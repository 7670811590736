import React, {useEffect, useState} from 'react'
import { AuthHeader } from '../../login/components/AuthHeader'
import clsx from 'clsx'
import Select from 'react-select'
import {Link, useHistory} from 'react-router-dom'
import {Button} from './Button'
export function Forms(callback, headerObj, fields, error, disclaimer){
    const [loading, setLoading] = useState(false)
    console.log("FIELDS +++++ ", fields, error)
    useEffect(()=>{
        console.log("field and error = ", fields, error)
    }, [error])
    return(
        <form
        className='form w-100'
        onSubmit={(e)=>callback(e)}
        noValidate>
            {headerObj && <AuthHeader object={headerObj}/>}
            {fields.map(item => {
                console.log("ITEM + ", item)
                return(
                    <div className='fv-row mb-5 mt-5'>
                        <div className='d-flex justify-content-between mt-n5'>
                            <div className='d-flex flex-stack mb-2'>
                                <label className='form-label fw-bolder text-dark fs-6 mb-0'>{item.title}</label>
                            </div>
                        </div>
                        {item.type!='dd' ? 
                        item.type!='text-area' ? <input
                        placeholder={item.placeholder || item.title}
                        className={clsx(
                            'form-control form-control-lg form-control-solid mb-2' 
                        )}
                        type={item.type}
                        disabled={item.disabled ? true : false}
                        name={item.name}
                        autoComplete='off'
                        />
                        :
                        <textarea className={clsx(
                            'form-control form-control-lg form-control-solid'
                        )} name={item.name}></textarea>
                        
                        :
                        <Select placeholder="USD" name={item.name} defaultValue='USD' className="curr-dd" options={item.list} />
                        }
                        {
                            item.notice && 
                            item.noticeJSX
                        }
                        {error?.state && error.field == item.name && <div className='fv-plugins-message-container'>
                            <div  className="tx-error">
                                <span role='alert'>{error.message}</span>
                            </div>
                        </div>}
          
                  </div>
                )
            })}
            {
                disclaimer &&
                <div className="fs-6 fw-bolder mb-5">
                    {disclaimer}
                </div>   
            }
            <div className='text-center'>
                {Button("Continue", null, "#5200E5")}
            </div>
        </form>
    )
}