import logo from './logo.svg';
import './App.css';
import 'tailwindcss/tailwind.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import {DataExplorer} from './battleExplorer/DataExplorer'
import GameList from './GameList.js'
import Feedback from './Feedback.js'
import {addKeypair, addRunInstance, addBlockchainInstance, addBip} from "./redux/blockchain/actions";
import Fighter from './Fighter.js'
import ItemView from './marketplace/components/ItemView.js'
import LiveMatches from './LiveMatches.js'
import {Leaderboard} from './Leaderboard'
import CreateItem from './CreateItem.js'
import CreateCollection from './CreateCollection.js'
import CreateCfCollection from './CreateCfCollection.js'
import AllCollections from './AllCollections.js'
import Queue from './Queue.js'
import {WeaveStatus} from './WeaveStatus'
import {MatchSummary} from './battleExplorer/MatchSummary';
import {useEffect, useState} from 'react';
import UserDetails from './userDetails';
import initialize from './RunInitialize';
import {connect} from "react-redux";
import {AuthService} from "@fyxgaming/lib/dist/auth-service";
import {Bip32, Constants, KeyPair, PrivKey} from 'bsv';
import axios from 'axios';
import AllFighters from './AllFighters';
import Tos from './Tos';
import Privacy from './Privacy';
import {AuthPage} from './login/AuthPage'
import WalletWrapper from './wallet/WalletWrapper';
import {Redirect} from 'react-router-dom';
import MarketplaceWrapper from './marketplace/MarketplaceWrapper.js';
import SharedLayout from './ui/components/layout';
import WalletHistory from './wallet/components/WalletHistory';
import {ComponentTester} from './ComponentTester';
import {Banners} from "./pages/admin/Banners";
// import Marketplace from './Marketplace';
// import { Leaderboard } from './Leaderboard';
function App(props) {
    let [config, setConfig] = useState({})
    let [location, setLocation] = useState({})
    let history = useHistory()
    const path = require('path')
    if (!localStorage.getItem('privKeyString')) window.intercom("unidentified", "unverified@unverified.com")
    // require('dotenv').config({path: path.resolve(__dirname, '../.env')})
    console.log("env ===== ", process.env.REACT_APP_HOST_URL)
    useEffect(async () => {
        // if(window.location.pathname!='/') window.location.pathname = '/'
        let resp = process.env.REACT_APP_NETWORK === 'mainnet' ? {
                network: 'mainnet',
            } :
            {
                network: 'testnet',
            }
        console.log("REACT APP NETWORK ======= ", process.env.REACT_APP_NETWORK)
        setConfig(resp)
        let orL = await axios.get(process.env.REACT_APP_HOST_URL + '/chains/fyx/lib/order-lock.chain.json')
        let iL = await axios.get(process.env.REACT_APP_HOST_URL + '/chains/fyx/models/fyx-item.chain.json')
        let cL = await axios.get(process.env.REACT_APP_HOST_URL + '/chains/fyx/models/fyx-collection.chain.json')
        let fL = await axios.get(process.env.REACT_APP_HOST_URL + '/chains/cryptofights/models/fighter.chain.json')
        let t2 = {
            OrderLock: orL.data,
            FyxItem: iL.data,
            FyxCollection: cL.data,
            Fighter: fL.data
        }
        localStorage.setItem('location', JSON.stringify(t2))
        Constants.Default = resp.network === 'mainnet' ? Constants.Mainnet : Constants.Testnet;
        setLocation(t2)
        let tempKp

        if (!props.keypair && localStorage.getItem('privKeyString') && localStorage.getItem('userHandle')) {
            // console.log("TMKC")
            console.log("REStORING STATESSSSSS")
            window.intercom(localStorage.getItem('userHandle'), localStorage.getItem('emailFyx'))
            let temp = PrivKey.fromString(localStorage.getItem('privKeyString'))
            props.addKeypair(KeyPair.fromPrivKey(temp))
            tempKp = KeyPair.fromPrivKey(temp)
            let as = (new AuthService(process.env.REACT_APP_HOST_URL, resp.network))
            await as.recover(localStorage.getItem('userHandle'), tempKp).then(async (response) => {
                props.addBip(response)
                let t = await initialize(response, tempKp, resp)
                await props.addBlockchainInstance(t.blockchain)
                await props.addRunInstance(t.run)


            })
                .catch(e => console.log(e))
        }


        // let t = await initialize()
        //props add run block and kp

    }, [])
    useEffect(async () => {

        setInterval(async function () {
            if (props.run) {
                let add = await props.run.purse.address
                // let utCheck = await axios(process.env.REACT_APP_HOST_URL+'/utxos/import/'+add)
                // console.log("EVERY NMINUTE CHECK ===== ", utCheck)
                localStorage.setItem('userWalletBalance', await props.run.purse.balance())
            }
        }, 60 * 1000)
    })

    const adminRoutes = () => {
        if (localStorage.getItem('privKeyString') &&
            localStorage.getItem('userHandle') === 'becca') {
            return (
                <Route path="/admin/banners"><Banners config={config} location={location}/></Route>
                //<Route path="/admin/banners"><Banners config={config} location={location}/></Route>
                //<Route path="/admin/banners"><Banners config={config} location={location}/></Route>
            );
        } else {
            return (
                <Redirect from='/wallet' to='/auth'/>
            );
        }
    }

    return (
        <div className="App">
            <Router>
                <SharedLayout>
                    <Switch>
                        <Route exact path="/tester"><ComponentTester config={config} location={location}/></Route>
                        <Route path="/summary/:id/:live"><MatchSummary config={config} location={location}/></Route>
                        <Route exact path="/explorer"><DataExplorer config={config} location={location}/></Route>
                        <Route exact path="/fighter/:playerid"><Fighter config={config} location={location}/></Route>
                        <Route exact path="/nft/:collection/:itemLocation"><ItemView config={config}
                                                                                     location={location}/></Route>
                        <Route exact path="/allFighters"><AllFighters config={config} location={location}/></Route>
                        <Route exact path="/tos"><Tos config={config} location={location}/></Route>
                        <Route exact path="/privacy"><Privacy config={config} location={location}/></Route>
                        <Route exact path="/weavestatus/:id"><WeaveStatus config={config} location={location}/></Route>
                        <Route exact path="/leaderboard"><Leaderboard config={config} location={location}/></Route>
                        <Route exact path="/profile"><UserDetails config={config} location={location}/></Route>
                        <Route path="/queue"> <Queue/></Route>
                        <Route path="/feedback"> <Feedback config={config} location={location}/></Route>
                        {/* <Route path="/allgames"> <AllGames config={config} location={location}/></Route> */}

                        {localStorage.getItem('privKeyString') ?
                            <Route path="/wallet"><WalletWrapper/></Route>
                            :
                            <Redirect from='/wallet' to='/auth'/>
                        }

                        <Route path="/cryptofights"> <GameList config={config} location={location}/></Route>
                        <Route path="/create/:id/:collection?"> <CreateItem config={config}
                                                                            location={location}/></Route>
                        <Route path="/collection"> <CreateCollection config={config} location={location}/></Route>
                        <Route path="/cfcollection"> <CreateCfCollection config={config} location={location}/></Route>
                        <Route path="/all/collection"> <AllCollections config={config} location={location}/></Route>
                        <Route path="/livematches/:origin"> <LiveMatches config={config} location={location}/></Route>

                        {localStorage.getItem('privKeyString') ?
                            <Route path="/wallet"><WalletWrapper/></Route>
                            :
                            <Redirect from='/wallet' to='/auth'/>
                        }


                        {/* new eps  */}
                        <Route path='/wallethistory'><WalletHistory config={config} location={location}/></Route>
                        <Route path='/auth'><AuthPage config={config}></AuthPage></Route>
                        {/* <Route path='/marketplace' component={() => { 
                            window.location.href = 'https://fyxgaming.substack.com/p/system-maintenance?s=w'; 
                            return null;
                        }}/> */}
                        <Route path='/marketplace'><MarketplaceWrapper config={config} props={props} page={"marketplace"}/></Route>
                        <Route exact path='/marketplace/:collLocation?'><MarketplaceWrapper config={config} 
                                                                                            props={props}
                                                                                            page={"marketplace"}/></Route>
                        {/* <Route path='/myitems'><MarketplaceWrapper config={config} props={props} page={"myitems"}/></Route> */}
                        <Route path="/myitems" component={() => {
                            window.location.href = 'https://fyxgaming.substack.com/p/system-maintenance?s=w'; 
                            return null;
                        }} />
                        {adminRoutes()}
                        <Route path="/"> <AuthPage config={config}/></Route>
                        <Route path="*"> <AuthPage config={config} signup={true}/></Route>
                    </Switch>
                </SharedLayout>

            </Router>
            {/* <TopNav/> */}
            {/* <DataExplorer/> */}
        </div>
    );
}

const mapStateToProps = state => {
    return ({
        keypair: state.Blockchain.keypair,
        run: state.Blockchain.run,
        blockchain: state.Blockchain.blockchain
    });
}
export default connect(mapStateToProps,
    {addKeypair, addRunInstance, addBlockchainInstance, addBip})(App);
