import React, {useEffect, useState} from 'react'
import Select, {components} from 'react-select'
function Dropdown(props){
    const { Option } = components;
    let [val, setVal] = useState('Cryptofights')
    useEffect(()=>{},[val])
    const IconOption = props => (
      <Option {...props}>
        <img
          src={props.data.image}
          style={{ width: 25, height: 25, marginRight: 5, borderRadius: 13 }}
          alt={props.data.label}
        />
        {props.data.label}
      </Option>
    );
    return(
        <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={val}
        label="Collections"
        placeholder="Select Collection..."
        onChange={(e)=>{
          setVal(e)
          console.log("EVENT in DD = ", e)
          props.callback(e)
        } }
        options={props.list}
        components={{ Option: IconOption }}
        className="w-200px"
        >
        </Select>
    )
}
export default(Dropdown)