import React, { Component } from 'react';
import { FaTimesCircle } from 'react-icons/fa';

const Privacy = () => {
     
    return(
        <div style={{textAlign: 'left', color: 'black',margin: '2% 2%', fontSize: '12px'}}>

            <h1>PRIVACY POLICY</h1>
                <p>This  Privacy  Policy  sets  forth  what  information Fyx  Gaming,  Inc.  (“Fyx”)  collects  about  you when you use the website (https://cryptofights.io/) (the “Website”) and any services, content,  materials,  features,  or  functionality provided  through  the  Website (collectively, “Services”), and how that information is used, stored, shared, and/or deleted by Fyx.</p>
                <p>Any  personal  information  you  may  provide  through  your  use  and/or  access  of  the  Website is  subject  to  the  terms  of  this  Privacy  Policy,  as  may  be  updated  by  Fyx  from time  to  time.  Please  read  this  Privacy  Policy  carefully  before  using  the  Website. This Privacy  Policy  does  not  apply  to  any  third-party  websites,  services,  providers,  or  applications, even if such are available or accessible through our Services.</p>
                <p>If  you  do  not  agree  with  this  Privacy  Policy,  you  should  not  use  the  Website.  All capitalized terms not defined herein shall have the meanings provided in the Terms of Service, found at https://fyxgaming.com/tos</p>
            <h2>1. INFORMATION WE COLLECT</h2>
                <p>1.1 We will only collect information that is relevant for the purposes of providing the Services, including, without limitation: (i) provide, test, promote, and improve the Services; (ii) gather usage statistics for the Services; (iii) administer your use of the Services and provide customized user experience; (iv) provide access to third party content or services; and (v) fight spam, fraud, and other abuse of the Services. Through your use of the Services, including, without limitation, the Website, Fyx may collect the following information:</p>
                <p><br/> &nbsp; &nbsp; &nbsp; • <b>User Provided Information </b>– Fyx may collect certain personally identifiable information in order for you to use the Website and/or Services, including, without limitation, (i) full name; (ii) mailing address; (iii) phone number; and/or (iv) email address. Additionally, when you send a communication via the Website, Fyx may also collect that communication, and you agree Fyx is free to use the content of any communications submitted by you for any purpose, including developing, manufacturing, and/or marketing goods or Services. We will not release your name or otherwise publicize the fact that you submitted materials or other information to us unless: (a) you grant us permission to do so; (b) we first send notice to you that the materials or other information you submit to a particular part of a Service will be published or otherwise used with your name on it; or (c) we are required to do so by law.</p>
                <p><br/> &nbsp; &nbsp; &nbsp; • <b>Log File Information </b>– Fyx may automatically collect information about your use of the Services, including, without limitation, information about the Internet browser, IP address, operating system, mobile carrier, and/or device information automatically assigned to your computer or device used by you to access the Website and/or Services. Other information Fyx may automatically collect includes, without limitation, information about the frequency and length of (i) Website usage, (ii) Website visits (i.e. date and time), (iii) accessing your Wallet, and (iv) other actions taken through your use of the Services.</p>
                <p><br/> &nbsp; &nbsp; &nbsp; • <b>Wallet Information </b>– We may receive information regarding use of your Wallet from our Third Party Wallet Provider. All information collected by our Third Party Wallet Provider will be done so in accordance with its Privacy Policy.</p>
                <p><br/> &nbsp; &nbsp; &nbsp; • <b>Transaction Information </b>– We may receive information regarding transactions made with out Third Party Conversion Provider. All information collected by our Third Party Conversion Provider will be done so in accordance with its Privacy Policy.</p>
                <p><br/> &nbsp; &nbsp; &nbsp; • <b>Cookies/other Technologies Information </b>– Fyx may place cookies, pixel tags, local storage, and other technologies (“Technologies”) on your device used to access the Services in order to automatically collect information through the Services. Cookies allow us to record certain pieces of information whenever you visit or interact with our Services and Websites, and to recognize you across devices. Cookies and/or other Technologies shall only be used as operationally necessary, to assess performance, to enhance functionality, or to deliver content such as third party advertisements via the Services. If you would like to opt-out of the Cookies and/or other Technologies we employ with our Website, you may do so by blocking, deleting, or disabling them as your browser or device permits. Please be advise that deactivation of Cookies and/or other Technologies may prevent from you fully accessing the Website or Services.
                    <p><br/> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; • <b>Cookies:</b> Cookies are small text files placed in your device to store user preferences. Cookies do not tell us your personal identification information unless you choose to provide such information by using the Website. Cookies assign a specific numerical identifier to your device and enable us to recognize you as the same user who has used the Website, and connect your use of the Website to other information about you. We may use both session cookies and persistent cookies to identify that you’ve logged in to the Services and to tell us how and when you interact with our Services. We may also use cookies to monitor aggregate usage and web traffic routing on our Services and to customize and improve our Services. You may elect to set your device so that you will not receive Cookies or to prompt you before accepting Cookies. You can also delete existing Cookies from your device history and turn off cookie acceptance altogether. However, some functions and features of the Website may not function properly if you have elected to disable cookies or similar tracking technologies. Some third-party services providers that we engage (including third-party advertisers) may also place their own cookies on your device. Note that this Privacy Policy covers only our use of Cookies and does not include use of Cookies by such third parties</p>
                    <p><br/> &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; • <b>Pixels / Web Beacons:</b> Pixels / Web Beacons are tiny graphics with a unique identifier that may be included on our Services for several purposes, including to deliver or communicate with Cookies, to track and measure the performance of our Services, to monitor how many visitors view our Services, and to monitor the effectiveness of our advertising. Unlike Cookies, which are stored on the user’s hard drive, Web Beacons are typically embedded invisibly on web pages (or in an e-mail).</p>
                </p>
                <p><br/> &nbsp; &nbsp; &nbsp; <b>• Social Media Information </b>– If choose to log in, access or otherwise connect to the Services through a social networking service (such as Facebook, Twitter, Instagram, etc.), we may collect your user ID and/or username associated with that social networking service, as well as any information you make public using that social networking service or which the social networking service allows us to access. We may also collect information you have authorized the social networking service to share with us on your behalf. This supplemental information allows us to verify information that you have provided through use of the Services and to enhance our ability to provide you with information about our business, products, and Services.</p>
            <h2>2. HOW WE USE THE INFORMATION</h2>
                <p>2.1 Fyx uses the information received from you in connection with the Services and features provided via the Website, including, without limitation, in order to:</p>
                <p>
                <br/> &nbsp; &nbsp; &nbsp; • Process and respond to customer service / user inquiries; Alert users to new services, content and/or features; Prevent fraudulent or other criminal activity;
                <br/> &nbsp; &nbsp; &nbsp; • Meet legal and regulatory requirements;
                <br/> &nbsp; &nbsp; &nbsp; • Provide access to certain functionalities and features of the Services;
                <br/> &nbsp; &nbsp; &nbsp; • Enforce the Terms of Use governing use of the Website;
                <br/> &nbsp; &nbsp; &nbsp; • Contact users for administrative purposes, including, without limitation, notifying you of changes to the Privacy Policy; and
                <br/> &nbsp; &nbsp; &nbsp; • Monitor and track total number of users of the Website for statistical purposes.</p>
                <p>2.2 We may also use personal information provided by you for reasons for which we have a legitimate interest, such as direct marketing, individual or market research, anti- fraud protection, or any other purpose disclosed to you at the time you provide personal information or with your consent.</p>
                <p>2.3 Fyx may also make anonymous and/or aggregate personal information for commercial, marketing, and/or business purposes, such as reporting and conducting research and development in order to enhance our existing Services and/or develop new products and Services. Anonymous or aggregated information does not identify you, and we may use or disclose such information in a number of ways, including research, internal analysis, analytics, and any other legally permissible purposes. We may share this information internally and/or with third parties for our or their purposes in an anonymous or aggregated form that is designed to prevent anyone from identifying you.</p>
            <h2>3. DISCLOSURE OF INFORMATION</h2>
                <p>3.1 Fyx may share your information with: (i) its staff, data persons (or entities) in charge of maintaining and recording the information; and/or (ii) to third parties involved in providing Services. Any such third party shall be prohibited from sharing or using your personal information except as necessary to perform the Services or to comply with applicable legal and regulatory requirements.</p>
                <p>3.2 Fyx may also share information with its accountants, attorneys, and other professional advisors who owe a duty of confidentiality to Fyx. In the event Fyx is required to disclose information pursuant to a court order, subpoena, or law enforcement agency request, we will disclose the requested information to the extent permissible by applicable law. To the extent permissible under applicable law, disclosure of personal identification information may also be made when necessary to establish, exercise, and defend legal claims, or protect the rights and property of Fyx, our agents, customers and others, including, without limitation, the enforcement of agreements, policies, terms and conditions of use, or the personal safety of any person.</p>
                <p>3.3 In the event that ownership of all or a part of Fyx or its business were to change via merger, acquisition, divestiture, dissolution, or sale of all or substantially all of Fyx’s assets, your information pertinent to that business' operations may be assigned or transferred to, or licensed for use by, the new owner, without any prior notice to or consent from you.</p>
                <p>3.4 If you choose to share information with social media services about your activities on the Website by, for example, connecting your social media account (i.e. Facebook account) to the Website, then we may share your personal information and information about your use of the Website with such social media websites. Use of social media service websites is governed by the privacy practices and policies of those websites, including, without limitation, your ability to opt out of certain settings. Please make sure to read the terms and privacy statements of such social media websites prior to connecting to the Website via your social media account.</p>
            <h2>4. SECURITY OF YOUR INFORMATION</h2>
                <p>4.1 We will require any third party to whom we provide your personal information to agree to comply with our Privacy Policy in force at that time or requirements substantially similar to such policy. We will make reasonable commercial efforts to ensure that they comply with such policy or requirements, however, where not expressly prohibited by law, we will have no liability to you if any third party fails to do so. We shall require any third party, including without limitation any government or enforcement entity, seeking access to data to obtain a Court Order, or equivalent proof that they are statutorily empowered to access your data and that their request is valid and within their power.</p>
                <p>4.2 We take reasonable administrative, physical and electronic measures designed to protect the information that we collect from or about you (including your personal information) from unauthorized access, use or disclosure. Notwithstanding, Fyx cannot assure or guarantee that any third party with which Fyx shares information will comply with its obligations regarding such information. Furthermore, even though appropriate measures are used to prevent unauthorized disclosures of information, we cannot assure you that any personal identification information collected or received by us will never be disclosed in a manner that is inconsistent with this Privacy Policy, and do not accept liability for any unintentional disclosure.</p>
                <p>4.3 By using the Website or providing personal information to us, you agree that we may communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the Website and/or Services. If we learn of a security system’s breach, we may attempt to notify you electronically by posting a notice on the Website or sending you an email at the email provided to us.</p>
            <h2>5. THIRD PARTY WEBSITES</h2>
                <p>Fyx, in its sole discretion, may include or offer third party links, products, advertisements, or services (collectively, “Third Party Websites”) via the Website. This Privacy Policy does not cover any collection or use of information that Third Party Websites may collect from you, or the practices used by third parties to collect such information, and Third Party Websites should have separate and independent privacy policies and terms of use for the purpose of collecting personal information about you. You should consult such Third Party Websites’ privacy policy, and Fyx has and assumes absolutely no responsibility or liability whatsoever for the activities of Third Party Websites. If you decide to use, click on, access, or provide any personal information to Third Party Websites, you acknowledge that you are doing so at your own risk.</p>
            <h2>6. THIRD PARTY PAYMENT PROCESSING</h2>
                <p>6.1 When you pay fees through the Website, we may process your payments through a Third party Website, including, without limitation, third-party payment processers. The Third Party Website may collect certain financial information from you in order to process a payment, including your name, email address, address and other billing information. In order to delete your payment or billing information, you will need to do so with the Third Party Website, as Fyx only has minimal secure access to those records as needed to provide the Services.</p>
            <h2>7. RETAINING YOUR PERSONAL INFORMATION</h2>
                <p>7.1 Fyx will retain your personal information received as described in this Privacy Policy while you are an active user of the Website and Services, and thereafter so long as needed for purposes that are not prohibited by applicable law. Before we use any of your personal information for any new purpose not originally authorized by you or set forth in this Privacy Policy, we will use best efforts to provide details regarding the new purpose and give you the opportunity to opt-out. Furthermore, where consent is required to process any personal information pursuant to applicable law or contract, we will use best efforts to comply with such law or contract.</p>
                <p>7.2 Upon termination of your use of the Services, we reserve the right to maintain your personal information as part of our standard back-up procedures in an aggregated format. When personal information is no longer required, we will utilize procedures to destroy, delete, erase or convert it to an anonymous form.</p>
            <h2>8. ABILITY TO OPT-OUT</h2>
                <p>8.1 You have the right to opt out of certain uses and disclosures of your personal information. Where you have consented to Fyx’s processing of your personal information, you may withdraw that consent at any time and opt-out to further processing by contacting us at [ ]. If you opt-out, we may still collect and use non-personal Information regarding your use of our Services for non- interest based advertising purposes, such as to determine the effectiveness of the advertisements.</p>
                <p>8.2 You may “opt-out” by following the unsubscribe instructions provided through the Website. We do need to send you certain communications regarding the Services and you will not be able to opt-out of those communications – e.g., communications regarding updates to our Terms of Use or this Privacy Policy.</p>
                <p>8.3 We may occasionally send you push notifications through your device with updates and other notices that may be of interest to you. You may opt-out from receiving these types of communications by changing the settings on your device.</p>
            <h2>9. MODIFYING, ACCESSING, CORRECTING, OR DESTROYING PERSONAL INFORMATION</h2>
                <p>9.1 You can modify personal information through your personal settings or by contacting us at [ ]. You may inquire as to whether Fyx is processing your personal information, request access to your personal information, and ask that we correct, amend or delete your Personal Information where inaccurate. If you want us to delete your personal information, please contact us at [ ] with your request. We’ll take steps to delete your information as soon we can, but some information may remain in archived/backup copies for our records or as otherwise required by law.</p>
                <p>9.2 Fyx will use best efforts to provide you with access to your personal information. However, there may be circumstances in which we are unable to provide access, including but not limited to: (i) where the information contains legal privilege, (ii) would compromise others’ privacy or legitimate rights, (iii) where the burden or expense of providing access would be disproportionate to the risks to your privacy, or (iv) where it is commercially proprietary. If we determine that access should be restricted in any particular instance, we will provide you with an explanation of why that determination has been made and a contact point for any further inquiries. To protect your privacy, we will take commercially reasonable steps to verify your identity before granting access to or making any changes to your personal information.</p>
            <h2>10. INTERNATIONAL TRANSFER OF INFORMATION</h2>
                <p>10.1 The Website and Services are hosted in the United States. Your personal information may be transferred to, and maintained on, computers located outside of your state, province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. If you are located outside the United States and choose to provide personal information to us, we may transfer your personal information to the United States and process it there. By using the Services, you authorize us to transfer, store, and use your information in the United States and any other country where we operate. By choosing to visit the Website, utilize the Services or otherwise provide information to us, you agree that any dispute over privacy or the terms contained in this Privacy Policy will be governed in accordance with the “Governing Law and Regulations” provided below, and the adjudication of any disputes arising in connection with your use the Services or the Website will be in accordance with terms provided herein and/or set forth in the Terms of Use.</p>
            <h2>11. CHILDREN AND OUR SERVICES</h2>
                <p>11.1 Our Services are not directed to children, and we do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under the age of 13, or are otherwise ineligible for participation in our Services as set forth in our Terms of Use, we will take steps to delete such information from our files as soon as possible.</p>
            <h2>12. CALIFORNIA PRIVACY RIGHTS</h2>
                <p>12.1 California law permits users who are California residents to request and obtain from us once a year, free of charge, a list of the third parties to whom we have disclosed their personal information (if any) for their direct marketing purposes in the prior calendar year, as well as the type of personal information disclosed to those parties. Such requests may be made to [ ]</p>
            <h2>13. CHANGES TO PRIVACY POLICY</h2>
                <p>13.1 By using the Services, you signify your agreement to this Privacy Policy. Fyx reserves the right to change this Privacy Policy at any time. If we make any material changes to this Policy, the revised Policy will be posted here and notified to our users at least 30 days prior to the changes taking effect, so that you are always aware of what information we collect, how we use it and under what circumstances we disclose it. Please check this page frequently to see any updates or changes to this Policy. The Privacy Policy in effect at the time such information is collected covers all information collected via our Services. Your continued use of the Website constitutes your agreement to this Privacy Policy and any future revisions, updates, or modifications. If at any point you do not agree to any portion of the Privacy Policy then in effect, you must immediately stop using the Services, including, without limitation, the Website.</p>
            <h2>14. GOVERNING LAW AND REGULATIONS</h2>
                <p>14.1 This Privacy Policy shall be construed and enforced in accordance with the laws of the State of Florida without regard to any conflict of law provisions. Notwithstanding the foregoing, this Privacy Policy, and the collection of information hereunder, shall comply with all relevant data protection provisions, including, without limitation, the Data Protection Act and General Data Protection Regulation</p>
            <h2>15. GENERAL PROVISIONS</h2>
                <p>15.1 This Privacy Policy sets forth the entire understanding between you and Fyx regarding your use of the Website. In the event that any one or more of the terms and conditions contained in this Privacy Policy is held to be invalid, illegal or unenforceable, the remaining provisions will not in any way be affected or impaired thereby. Fyx's failure to insist upon strict adherence to any term contained in this Privacy Policy shall not constitute a waiver of such term and shall not be considered a waiver or limit of any right thereafter to insist upon strict adherence to that term or any other term contained in this Privacy Policy. The Parties shall make a good faith effort to resolve any actual or threatened breach of this Privacy Policy, or any other dispute arising under or in connection with this Privacy Policy, by binding arbitration under the administration and rules of the American Arbitration Association. The arbitration shall be held in in the county in which you reside, or another mutually agreeable location. The prevailing party in any action or proceeding shall be entitled to reasonable costs and attorneys' fees.</p>
                <p>15.2 Notwithstanding the foregoing arbitration provisions, in no event shall Fyx be precluded or delayed from seeking and obtaining temporary, preliminary and/or permanent injunctive relief, without the posting of any bond or proving of actual damages, against infringement or other violation of its claimed intellectual property rights, or other relief that it deems reasonably necessary to defend or enforce its rights or this Privacy Policy, in any court(s) of competent jurisdiction, and through your use of the Website, you hereby consent to, and hereby waive any objection regarding, such jurisdiction and venue. This is without prejudice to your right to launch a claim with the data protection supervisory authority in the country in which you live or work where you think we have infringed data protection laws.</p>
                <p>15.3 Fyx may assign, transfer, subcontract or otherwise deal with all of its rights, including those set forth herein without notifying you or obtaining your consent. You may not assign or transfer your rights and/or obligations under this Privacy Policy.</p>
            <h2>16. CONTACT US</h2>
                <p>16.1 If you have any questions or concerns regarding this Privacy Policy or the terms contained herein, please contact Fyx at [privacy@Fyx.io]. In the event that you wish to make a complaint about how we process your personal data, please contact us at [privacy@Fyx.io] and we will use best efforts to deal with your request as soon as possible.</p>



        </div>
        
    )
}
export default (Privacy);