import {
    HIDE_MESSAGE, HIDE_MODAL_MSG, HIDE_NOTIFICATION,
    SHOW_MESSAGE, SHOW_MODAL_MSG, SHOW_NOTIFICATION, YES_MODAL_CLICK
} from "./constants";

const INIT_STATE = {
    messageVisible: {},
    notificationVisible: {},
    modalMsgVisible: {}
};

const Messages = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SHOW_MESSAGE:
            return {
                ...state,
                messageVisible: {
                    ...state.messageVisible,
                    [action.payload]: true
                }
            };
        case HIDE_MESSAGE:
            return {
                ...state,
                messageVisible: {
                    ...state.messageVisible,
                    [action.payload]: false
                }
            };
        case SHOW_NOTIFICATION:
            return {
                ...state,
                notificationVisible: {
                    ...state.notificationVisible,
                    [action.payload]: true
                }
            };
        case HIDE_NOTIFICATION:
            return {
                ...state,
                notificationVisible: {
                    ...state.notificationVisible,
                    [action.payload]: false
                }
            };
        case SHOW_MODAL_MSG:
            return {
                ...state,
                modalMsgVisible: {
                    ...state.modalMsgVisible,
                    [action.payload]: true
                }
            };
        case HIDE_MODAL_MSG:
            return {
                ...state,
                modalMsgVisible: {}
            };
        default:
            return state;
    }
};

export default Messages;