import React, { useState, useEffect } from 'react';

import { connect } from "react-redux";
import {  Bip32, Constants, KeyPair, PrivKey } from 'bsv'


const Feedback = (props) => {
    Constants.Default = process.env.REACT_APP_NETWORK === 'mainnet'  ? Constants.Mainnet : Constants.Testnet;
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')

    const feedback = () => {
        
        window.cannyLoad(email, name, localStorage.getItem('userHandle'))
        window.open("https://feedback.fyxgaming.com", "_blank")
    } 
    return(
        <div>
            {/* <img className="login-img" style={{width: '100vw', height: 'auto'}} src={'./assets/login-background.png'}></img> */}
            
            <form className="login-form" >
                <div className="login-heading">Feedback</div>
                <label className="login-label">
                    
                    <input className="text-field" type="text" name="name" placeholder="Your Email" onChange={(event)=>setEmail(event.target.value)}/>
                </label>
                <label className="login-label">
                    
                    <input className="text-field" type="text" name="name" placeholder="Your Full Name" onChange={(event)=>setName(event.target.value)}/>
                </label>
                <input className="button" type="button" value="Continue" onClick={()=>feedback()}/>
            </form>
        </div>
    )
}

const mapStateToProps = state =>{ 
    return ({
     keypair: state.Blockchain.keypair
  });}
export default connect(mapStateToProps)(Feedback);