import React, {useEffect, useState} from 'react'
import QRCode from 'qrcode.react';
import { CopyField } from '../../../helpers/components/CopyField'
import {AuthHeader} from '../../../login/components/AuthHeader'
import { Notice } from '../WalletBalance'
function BSVDeposit({props}){
    let headerObj = {
        head: "Deposit BSV Directly",
        showImage: false
    }
    console.log("in BSV deposit props = ", props)
    const [render, setRender] = useState(null)
    useEffect(async ()=>{
        let address = await props.run.purse.address
        let paymail = localStorage.getItem('userHandle')+'@fyxgaming.com'
        setRender({address: address, paymail: paymail})
    },[props])
    return(
    <React.Fragment>
        <div className="pop-content card card-rounded shadow-sm p-5 p-lg-5">
            <AuthHeader object={headerObj}/>
            {
                render &&
                <div className='ps-20 pe-20 d-flex flex-column align-items-center shrink-popup'>
                    {/* qr*/}
                    <QRCode value={render.address} fgColor={"#4D00D5"} imageSettings={{height: "1", width: "1"}} style={{alignSelf: 'center'}}/>
                    <p className="fw-bold fs-7 mt-5 text-center">Scan the QR code above to deposit BSV to your Fyx Gaming Account, or send BSV directly to your PayMail or Wallet address.</p>
                    {CopyField("Paymail Address", render.paymail)}
                    {Notice("#FEB705", "Don’t use PayPal or Venmo to send to this address.", "warning")}
                    {CopyField("BSV Address", render.address)}
                    {Notice("#FEB705", "Don’t use PayPal or Venmo to send to this address.", "warning")}
                </div>
            }
        </div>
    </React.Fragment>
    )
}
export default (BSVDeposit)