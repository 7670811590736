import React from 'react'
export function CheckBox(body, event){
    function calc(e)
    {
        if (e.target.checked) 
        {
            event(true)
        } else {
            event(false)
        }
    }
    return(
        <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={e=>calc(e)}/>
            <label className="form-check-label" for="flexCheckDefault">
                {body}
            </label>
        </div>
    )
}