import React, {useEffect, useState} from 'react'
import {AuthHeader} from '../../login/components/AuthHeader'
import {CopyField} from '../../helpers/components/CopyField'
import {Button} from '../../helpers/components/Button'
import {Notice} from '../components/WalletBalance'
import QRCode from 'qrcode.react';


import {connect} from 'react-redux'
import Close from '../../helpers/components/Close'
function TransactionDetails({props, qr}){
    let header = {
        imageUrl2: '../../assets/visa.png',
        imageUrl: '../../assets/mastercard.png',
        head: 'Transaction Details',
        showImage: false,
    }
    console.log("Props in l4 = , props", props)

    return(
        <React.Fragment>
            <div className="pop-content card card-rounded shadow-sm pt-5 ps-20 pe-20">
                <Close/>
                <AuthHeader object={header}/>
                {qr &&
                    <QRCode value={qr} fgColor={"#4D00D5"} imageSettings={{height: "1", width: "1"}} style={{alignSelf: 'center'}}/>
                    }
                    <br/>
                {props.map(i =>  <div className="text-center" style={{color: 'black'}}>{CopyField(i.title, i.value, "Your address has been copied to the clipboard")}</div>)}
                <br/>
                <br/>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = state => {
    return ({
        keypair: state.Blockchain.keypair,
        run: state.Blockchain.run,
        blockchain: state.Blockchain.blockchain,
        bip32: state.Blockchain.bip32
    });
}
export default connect(mapStateToProps)(TransactionDetails)