import React from 'react'
import clsx from 'clsx'
export function IconHeader({object}){
    return(
        <div className='text-center mb-2 mt-5'>
            {
                object.imageUrl &&
                <a href='#' className='mb-10'>
                    {object.imageUrl2 && <img alt='Logo' src={object.imageUrl2} className='h-55px' />}
                    <img alt='Logo' src={object.imageUrl} className='h-65px' />
                </a>
            }

            <h1 className={clsx(
                            'text-dark mt-5',{
                                'mb-0' : !object.sizeReduction
                            },
                            {
                                'mb-0 fs-6': object.sizeReduction
                            }
                        )}>{object.head}
            </h1>
        </div>
    )
}