import React, {useEffect, useState} from 'react'
import {IconHeader} from '../../../helpers/components/IconHeader'
import clsx from 'clsx'
import axios from 'axios'
import Select, {components} from 'react-select'
import { FaCircle } from 'react-icons/fa'
import {Button} from '../../../helpers/components/Button'
import { connect } from "react-redux";
import { SignedMessage } from "@fyxgaming/lib/dist/signed-message";
import {Notice} from '../WalletBalance'
import QRCode from 'qrcode.react';
import Loader from "react-loader-spinner";
import BSVDeposit from './BSVDeposit'
import {AuthHeader} from '../../../login/components/AuthHeader'
import {CopyField} from '../../../helpers/components/CopyField'
import { WalletService } from '../../Service/WalletService'
import TransactionDetails from '../TransactionDetails'
import Close from '../../../helpers/components/Close'
import ConfirmationComponent from '../ConfirmationComponent'
function Cryptodeposit(props){
    const { Option } = components;
    let header = {
        // imageUrl2: '../../assets/visa.png',
        // imageUrl: '../../assets/mastercard.png',
        head: 'Receive Via Exchanging crypto',
        showImage: false
    }
    //placeholder
    let error = null
    const [level, setLevel] = useState(1)
    const [depositCurrency, setDepositCurrency] = useState('BTC')
    const [depositAmount, setDepositAmount] = useState(null)
    const [fiatMaximums, setFiatMaximums] = useState({})
    const [errorMessage, setErrorMessage] = useState(null)
    const [weaveObject, setWeaveObject] = useState(null)
    const [memo, setMemo] = useState(null)
    const [loading, setLoading] = useState(false)
    const [weaveTx, setWeaveTx] = useState(null)
    const [wT, setWT] = useState(null)
    const [fiatMinimums, setFiatMinimums] = useState({})
    const [getPayment, setGetPayment] = useState(null)
    const [currenciesList, setCurrenciesList] = useState([])
    let list = [{value: 'USD', label: 'USD'}, {value: 'EUR', label: 'EUR'}]
    useEffect(async ()=>{
        let kp = props.keypair
        let fiat2  = await axios.get(process.env.REACT_APP_HOST_URL+`/exchange/getweavecurrencies`)
        // let curr = fiat2?.data && fiat2.data?.data.map(i => {return i.code.toLowerCase()=='bsv' ? ({value: i.code, label: i.code+' via QR Code'}): ({value: i.code, label: i.code})})
        let curr = []
        if(fiat2.data && fiat2.data.data){
            for(let c of fiat2.data.data){
                if(c.code.toLowerCase() == 'bsv') continue
                else if(c.code.toLowerCase() == 'usdt') curr.push({value: c.code, label: `${c.code} - ERC20`})
                else curr.push({value: c.code, label: c.code})
            }
        }
        setCurrenciesList(curr)

        let tMin = {}
        for(let c of fiat2?.data?.data){
            tMin[c.code] = parseFloat(5/c.usd_rate)
        }

        setFiatMinimums(tMin)
    },[])
    const IconOption = props => (
        <Option {...props}>
          {props.data.label}
        </Option>
      );
    let depositStepOne = async (e) => {
        e.preventDefault(e)
        // if(e.target.depositCurrency.value) setCurrencyDeposit(e.target.depositCurrency.value)
        try{
            console.log("Event in BSV deposit = ", depositCurrency, e.target.depositAmount.value, isNaN(e.target.depositAmount.value))
            if(depositCurrency.toLowerCase() == 'bsv'){
                setLevel(5)
            } 
            if(isNaN(e.target.depositAmount.value)) throw {message: "The entered amount should be a valid number."}
            if(e.target.depositAmount.value < fiatMinimums[depositCurrency]) throw {message: `Enter an amount greater than ${depositCurrency} ${fiatMinimums[depositCurrency]}`}
            setDepositAmount(e.target.depositAmount.value)
            let config = process.env.REACT_APP_NETWORK === 'mainnet' ? {
                network: 'mainnet',
            } : 
            {
                network: 'testnet',
            }
            if(isNaN(e.target.depositAmount.value)) throw "The entered amount should be a valid number."
            const ws = new WalletService(props, config)
            let response = await ws.weaveGetQuote(depositCurrency,'BSV', e.target.depositAmount.value)
            if(response.error) throw response.error
            console.log("RESPNSE FROM WEAVE ++++++ ", response)
            await setWeaveObject(response)
            setLevel(2)
        }
        catch(e){
            console.log("e.message = ",e.message)
            setErrorMessage(e.message)
        }
    }

    let depositStepTwo = async (e) => {
        e.preventDefault(e)
        setMemo(e.target.walletMemo.value)
        setLevel(3)
    }
    let depositStepThree = async (e) => {
        e.preventDefault(e)
        setLoading(true)
        let config = process.env.REACT_APP_NETWORK === 'mainnet' ? {
            network: 'mainnet',
          } : 
          {
            network: 'testnet',
          }
        try{

            const ws = new WalletService(props, config)
            let response = await ws.weaveCreateTransaction(null, weaveObject) 
            console.log("response from build tx = ", response)
            setWT(response)
            if(response.error) throw response.error
            let tx = response.deposit_tag ? [
            {
                // title: `Please scan the QR Code above or send ${response.amount +' '+response.from_asset} to this Address:`,
                title: `Please scan the QR Code above or send ${response.amount +' '+response.from_asset} to this Address:`,
                value: response.deposit_address
            },
            {
                title: `Please use this XLM memo while sending your funds:`,
                value: response.deposit_tag
            },
            {
                title: `You should receive ${response.to_amount+' '+response.to_asset} in 1-3 business days after the ${response.from_asset} transaction is settled.`,
                value: null
            },{
                title: `Having issues?`,
                value: null
            },
            {
                title: `Contact support with your transaction ID:`,
                value: response.id
            }]
            :
            [
                {
                    title: `Please scan the QR Code above or send ${response.amount +' '+response.from_asset} to this Address:`,
                    value: response.deposit_address
                },
                {
                    title: `You should receive ${response.to_amount+' '+response.to_asset} after the ${response.from_asset} transaction is settled.`,
                    value: null
                },
                {
                    title: `Contact support with your transaction ID:`,
                    value: response.id
                }]
            
            setWeaveTx(tx)

            setLevel(4)
        }
        catch(e){
            setErrorMessage(e.message)
        }

    }
    let goBack = () =>{
        setLevel(level-1)
    }

    const LevelOne = () => {
        return(
            <div className="p-10">
                <div className='fv-row mb-10'>
                <br/>
                    <div className="mb-10">Please note that there will be a network fee associated with your transaction. </div>
                    <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Deposit token: </label>
                        </div>
                    </div>
                    <Select 
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="depositCurrency"
                            placeholder={depositCurrency} 
                            value={depositCurrency} 
                            options={currenciesList} 
                            onChange={async (e)=> await setDepositCurrency(e.value)}/>
                </div>
                <div className='fv-row mb-2'>
                    <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Deposit Amount: </label>
                        </div>
                    </div>
                    <form
                    className='form w-100'
                    onSubmit={(e)=>depositStepOne(e)}
                    noValidate>
                    <input
                        placeholder="0.0"
                        className={clsx(
                            'form-control form-control-lg form-control-solid mb-10'
                        )}
                        type="text"
                        value={depositAmount || null}
                        name="depositAmount"
                        autoComplete='off'></input>
                    {errorMessage && Notice("red", errorMessage, "warning")}
                    <div className="fs-6 fw-bolder mb-5 mt-5 text-left d-flex justify-content-between align-items-center">Estimate BSV you’ll receive. {Button("Continue", null, "#5200E5")}</div>
                    {/* <div className="fs-6 fw-bolder m-5 text-center">Continue to get an estimate of how much BSV you’ll receive.</div>
                    {Button("Continue", null, "#5200E5", 3)} */}
                    </form>
                </div>
                
            </div>

        )
    }
    const LevelTwo = () => {
        return(
            weaveObject && 
            <React.Fragment>
                <div className="ps-10 pe-10">
                    {loading ? <div style={{color: 'white', marginLeft: '45%'}}>   
                        Please wait ...                      
                        <Loader
                                type="Puff"
                                color="#00BFFF"
                                height={30}
                                width={30}
                                timeout={30000} //3 secs
                        /></div>: null}
                    {weaveObject && <ConfirmationComponent 
                        sendingAmount={depositAmount} 
                        sendingCurrency={depositCurrency}
                        receivingAmount={weaveObject.amount}
                        receivingCurrency={'BSV'}
                        errorMessage={null}
                        goBack={goBack}
                        depositStepTwo={depositStepThree}
                    />}
                </div>
            </React.Fragment>
        )
    }
    const LevelThree = () => {
        return(
            <div className="p-10">
                <div className='fv-row mb-1'>
                    <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack'>
                            <label className='form-label fw-bolder text-dark fs-6 mb-0'> {depositCurrency} Memo (This is required for XLM): </label>
                        </div>
                    </div>
                    <form
                    className='form w-100'
                    onSubmit={(e)=>depositStepThree(e)}
                    noValidate>
                    {/* <input
                        placeholder="Wallet Address"
                        className={clsx(
                            'form-control form-control-lg form-control-solid '
                        )}
                        type="text"
                        name="walletAddress"
                        autoComplete='off'></input>
                    {Notice("#3699FF", `Your Wallet address should be valid`, "info")} */}
                    <br/>
                    <input
                        placeholder="Wallet memo"
                        className={clsx(
                            'form-control form-control-lg form-control-solid mb-10'
                        )}
                        type="text"
                        name="walletMemo"
                        autoComplete='off'></input>
                    <div className="fs-6 fw-bolder m-5">Want to change the currency or amount?</div>
                    {Button("Back to Previous step", goBack, null)}
                    <div className="fs-6 fw-bolder m-5">Continue to be taken to another page to finalize the transaction.</div>
                    {loading ? <div style={{color: 'white'}}>   
                        Please wait ...                      
                        <Loader
                                type="Puff"
                                color="#00BFFF"
                                height={30}
                                width={30}
                                timeout={30000} //3 secs
                        /></div>: null}
                    {Button("Continue", null, "#5200E5", 5)}

                    </form>
                </div>
                
            </div>

        )
    }
    const LevelFour = () => {
        let header = {
            imageUrl2: '../../assets/visa.png',
            imageUrl: '../../assets/mastercard.png',
            head: 'Steps to complete this transaction',
            showImage: false,
        }
        let qr = weaveTx[0].value
        return(
            <React.Fragment>
                <div className="pop-content card card-rounded shadow-sm pt-5 ps-20 pe-20">
                    <Close/>
                    <AuthHeader object={header}/>
                    <br/>
                    <div className="text-left fw-bold" style={{lineHeight: '2rem',fontSize: '14px',color: 'black'}}>1: Open your 3rd party Wallet (Metamask, Trust, Handcash, etc)</div>
                    <div className="text-left fw-bold" style={{lineHeight: '2rem',fontSize: '14px',color: 'black'}}>2: Go to Send</div>
                    <div className="text-left fw-bold" style={{lineHeight: '2rem',fontSize: '14px',color: 'black'}}>3: Enter recipient address by typing, copy-pasting, or scanning the QR code.</div>
                        {qr &&
                            <QRCode value={qr} fgColor={"#4D00D5"} imageSettings={{height: "1", width: "1"}} style={{alignSelf: 'center'}}/>
                        }
                        {CopyField('', wT.deposit_address, "Your address has been copied to the clipboard")}
                        <br/>
                    <div className="text-left fw-bold" style={{lineHeight: '2rem',fontSize: '14px',color: 'black'}}>4: Enter {wT.amount+' '+wT.from_asset} as Amount</div>
                    <div className="text-left fw-bold" style={{lineHeight: '2rem',fontSize: '14px',color: 'black'}}>5: Send</div>
                        {CopyField('Contact support with your transaction ID:', wT.id, "Your id has been copied to the clipboard")}
                    <br/>
                    <br/>
                </div>
            </React.Fragment>

        )
    }

    return(
        <React.Fragment>
            {level==5 && <BSVDeposit props={props}/>}
            {level!=4 && level!==5 && <div className="pop-content card card-rounded shadow-sm p-5 p-lg-5 shrink-popup">
                <Close/>
                <IconHeader object={header}/>
                {level==1 && <LevelOne/>}
                {level==2 && <LevelTwo/>}
                {level==3 && <LevelThree/>}
            </div>}
            {level == 4 && <LevelFour/>}

        </React.Fragment>
    )
}
const mapStateToProps = state =>{ 
    return ({
     keypair: state.Blockchain.keypair,
     run: state.Blockchain.run,
     blockchain: state.Blockchain.blockchain
  });}
export default connect(mapStateToProps)(Cryptodeposit)