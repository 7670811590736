import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams, useHistory } from "react-router-dom";
import { FaAngleLeft, FaAngleRight, AiOutlineFileSearch, FaHeart, FaGem } from "react-icons/fa";
import {MatchSummary} from './battleExplorer/MatchSummary';
import { AuthService } from "@fyxgaming/lib/dist/auth-service";

import { SignedMessage } from "@fyxgaming/lib/dist/signed-message";
import Iframe from 'react-iframe'
import Carousel from 'react-bootstrap-v5/lib/Carousel'
import { connect } from "react-redux";
import  {  Bip32, Constants, KeyPair, PrivKey } from 'bsv';
import { Input } from '@material-ui/core';
import ProgressBar from 'react-bootstrap-v5/lib/ProgressBar'
import CountUp from 'react-countup';
import copy from 'copy-to-clipboard';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
const UserDetails = (props) => {
    Constants.Default = process.env.REACT_APP_NETWORK === 'mainnet'  ? Constants.Mainnet : Constants.Testnet;
    let { playerid } = useParams();
    // let history = useHistory()
    const [cpyClip, setCpyClip] = useState('Copy Seed words')
    const [fighterIndex, setFighterIndex] = useState(0)
    const [fighterBotIndex, setFighterBotIndex] = useState(0)
    const [match, setMatch] = useState()
    const [items, setItems] = useState()
    const [aggre, setAggre] = useState()
    const [stk, setStk] = useState()
    const [element, setElement] = useState()
    const [usdTransferRate, setUsdTransferRate] = useState(null)
    let location = props.location
    const config = props.config
    let history = useHistory()
    if(props.config.network){
        Constants.Default = props.config.network.slice(0,4) === 'main' ? 
            Constants.Mainnet : 
            Constants.Testnet;
    }
    let kp=props.keypair

    useEffect(async ()=>{
        if(location.Fighter){
        if(!localStorage.getItem('privKeyString')){
            history.push('/')
        }


        if(!props.location) location = JSON.parse(localStorage.getItem('location'))
        //new endpoint
        const resp_items = await axios.post(`${process.env.REACT_APP_HOST_URL}/jigs/fighters/${localStorage.getItem('userHandle')}`,{
            limit: 2,
        })
        const fighterItems = await Promise.all(resp_items.data.map(async ({location}) => {
            const {data: item} = await axios(`${process.env.REACT_APP_HOST_URL}/jigs/${location}`);
            return item;
        }));
        const i = fighterItems
        setFighterBotIndex(i[0])
        let resp_battle = await axios.post(process.env.REACT_APP_HOST_URL+'/cryptofights/battles',{
            userId: localStorage.getItem('userHandle'),
            limit: 20
        })

        resp_battle = await Promise.all(resp_battle.data.map(async ({id}) => {
            const {data: item} = await axios(`${process.env.REACT_APP_HOST_URL}/cryptofights/battles/${id}`);
            return item;
        }));
        console.log("DEBUG BATTLES == ", resp_battle)
        //not required with new ep
        let battle = resp_battle
        console.log("battle jig = ", battle)
        setMatch(battle[0])
        setFighterIndex(0)
        if(battle.length!=0)CardStack(battle)
        // itemBox()
        setItems(renderItems(i.slice(0,2)))
        let test = await axios.post(process.env.REACT_APP_HOST_URL+'/cryptofights/leaderboard',{
        })
        test = test.data.filter(a => a?.id.toLowerCase() == localStorage.getItem('userHandle')?.toLowerCase())
        // console.log("aggr from new endpoint = ", test)
        setAggre(aggr(battle, localStorage.getItem('userHandle')))
        }
    },[location])
    const aggr = (v, keys) => {
        
        let count = 0
        let damage = 0
        let itemsWon = 0
        let level = 0
        let cash = 0
        // console.log("DEBIG V ==== ", v)
        for(let items of v){
            if(items.battlePlayers[0].fighter.metadata.name === items.victor.fighter.metadata.name) count+=1
            //damage per game

            damage += items.state.fighterStates[1].maxHP - items.state.fighterStates[1].hp
            //items won
            if(items.rewards) itemsWon+= items.rewards.length
            //level reached
            level = Math.max(level, items.battlePlayers[0].fighter.level)
            //cash won 
            cash+=items.rules.reward
        }
        const ppt = ((count==v.length? count : count/(v.length-count)*10)+(damage*5/v.length)+(cash*30/1000000)*v.length)/(30)
        return {
            userId: keys,
            damage: damage/v.length,
            winLoss: count==v.length? count : count/(v.length-count),
            itemsWon: itemsWon,
            level: level,
            cash: cash/1000000, 
            matchesPlayed: v.length,
            ppt: ppt*10
        }
    }

    const skills = (match) => {
        const stack = []
        
        match&&match.battlePlayers[fighterIndex].skills.slice(0,3).map((item)=>{
            stack.push(<div className="skillList"> <span style={{fontSize: 10}}> ·</span>{item.displayName}  </div>)
        })
        return stack
    }
    const ability = (match) => {
        const stack = []
        let point = 0
        match&&match.battlePlayers[fighterIndex].fighter.abilityScores.map((item)=>{
            point +=1
            let text = point == 1? "Strength" : point==2? "Dexterity" : "Intelligence"
            stack.push(<div className="skillList mob-skilllist">{text} : &nbsp; <br/> {<CountUp start={0} end={item}/>} </div>)
        })
        return stack
    }
    const CardStack = async (num) => {
        const stack = []
        const checkStack = []
        console.log("card stack = ", num)
        let transferRate = await axios.get(`https://api.whatsonchain.com/v1/bsv/main/exchangerate`)
        localStorage.setItem('usdTR', transferRate.data.rate)

        for(let item of num){
            //ask if victor can be null
            const url = '../summary/'+item.id+'/'+item.hasOwnProperty('live')
            console.log("testing item = ", item)
            let d = new Date(item.timestamp)
            var year = d.getFullYear();
            var month = (1 + d.getMonth()).toString();
            month = month.length > 1 ? month : '0' + month;
            var day = d.getDate().toString();
            day = day.length > 1 ? day : '0' + day;
            const rew = item.hasOwnProperty('rewards') ? item.rewards[0].item.displayName : '-'
            checkStack.push(
                <tr>
                    <td>
                        <div className='d-flex align-items-center justify-content-between '>
                            <div className='symbol symbol-45px me-1'>
                                <img className="mob-img" style={{width: '3vw', height: 'auto', borderRadius: '1.5vw'}} src={item.battlePlayers[0].fighter.metadata.imageUrl} alt='' />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark text-muted fst-italic fw-bold text-hover-primary fs-6'>
                                    {item.battlePlayers[0].fighter.metadata.name} VS {item.battlePlayers[1].fighter.metadata.name}
                                </a>
                            </div>
                            <div className='symbol symbol-45px ms-1 me-3'>
                                <img className="mob-img" style={{width: '3vw', height: 'auto', borderRadius: '1.5vw'}} src={item.battlePlayers[1].fighter.metadata.imageUrl} alt='' />
                            </div>
                        </div>
                    </td>
                    <td>
                        <a href='#' className='text-dark text-muted fw-bold  text-hover-primary d-block fs-6'>
                            {month + '.' + day + '.' + year+ ' '+ d.toTimeString().slice(0,9)}
                        </a>
                    </td>
                    <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                            <span className='text-muted me-2 fs-7 fw-bold'>${(item.rules.reward*localStorage.getItem('usdTR') /100000000).toFixed(2)}</span>
                            </div>
                            <div className='progress w-100' style={{height: '0.4rem'}}>
                                <div
                                    className='progress-bar'
                                    role='progressbar'
                                    style={{width: `${(item.rules.reward/7200).toFixed(2)}%`, backgroundColor: ((item.rules.reward/7200).toFixed(2) <33 ? "red" : (item.rules.reward/7200).toFixed(2) <66 ? "rgb(114, 167, 228)" : "rgb(86, 214, 96)")}}
                                ></div>
                            </div>
                        </div>
                    </td>
                    <td>
                    <a href='#' className='text-dark text-muted fw-bold  text-hover-primary d-block fs-6'>
                        {rew}
                    </a>
                    </td>
                    <td className="text-end">
                    <div className='d-flex justify-content-end flex-shrink-0'>
                        <div
                        onClick={()=> history.push(`/summary/${item.id}/false`)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                            <img className="mob-img" style={{width: '2vw', height: 'auto'}} src={'./assets/Log.png'}></img>
                        </div>
                        <div
                        
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                            <img className="mob-img" style={{width: '2vw', height: 'auto'}} src={'./assets/Share.png'}></img>
                        </div>
                        <div
                        
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                            <img className="mob-img" style={{width: '2vw', height: 'auto'}} src={'./assets/Watch.png'}></img>
                        </div>
                    </div>
                    </td>
                </tr>

            )

        }
        await setStk(stack)
        renderCard(checkStack)
        
    }
    const renderCard = async(stack) => {
        const elem = []
        for( let items of stack){
            elem.push(
                    items
            )
        }

        await setElement(elem)
    }
    const renderItems=(item)=>{
        let src = ""
        console.log("")
        let a = item

        const stack = []
        for(let i of a){

            stack.push(
                <Link to={`/fighter/${i.origin}`}>
                    <div className="itemBox customIb" style={{border: 'none'}}>
                        <img className="opemItemImageView fighterImage" src={i.metadata.imageUrl ? i.metadata.imageUrl : `${process.env.REACT_APP_PUBLIC_URL}/assets/fyxlogopopup.png`}></img>
                        <div>{i.metadata.name}</div>                        
                    </div>
                </Link>
            )
        }
        return stack
    }
    const [itemSection, setItemSection] = useState('myitems')
    return( 
        <React.Fragment>
            <div className="handleDetails">


                <div className="fighterCont">
                    <div className="fighterValues">
                        <div className="fighterHead">Player<br/>
                            <div className="fighterDetails">
                                <div className="fighterDetCont fighterImgCont">
                                    <img className="avtr avtrCustom" src={fighterBotIndex && fighterBotIndex.metadata.imageUrl}></img>
                                    <div className="fighter-name name-custom">{localStorage.getItem('userHandle')}</div>
                                    <input className="button seedButton" type="button" style={{width: 170, fontSize: 12, height: 35}} value={cpyClip} onClick={()=>{
                                        copy(localStorage.getItem('mnemonic'))
                                        setCpyClip("Seed words copied.")
                                        }}/>
                                </div>
                            </div>
                        </div>
                                    <div className="col-xl-6 border">
                                        <div className="card card-xl-stretch mb-5 ">
                                            <div className="card-header pt-5">
                                                <h3 className="card-title align-items-start flex-column">
                                                    <span className="card-label fw-bolder fs-3">Fighter Overview</span>
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="card-body py-0">
                                            <div className="table-responsive">
                                                <table className="table align-middle gs-0 gy-5">
                                                    <thead>
														<tr>
															<th className="p-0 w-50px"></th>
															<th className="p-0 min-w-200px"></th>
															<th className="p-0 min-w-100px"></th>
															<th className="p-0 min-w-40px"></th>
														</tr>
													</thead>
                                                    <tbody>
                                                        <tr>
															<th>
																<div className="symbol symbol-50px me-2">
																	<span className="symbol-label">
																		<img src="assets/media/svg/brand-logos/plurk.svg" className="h-50 align-self-center" alt="" />
																	</span>
																</div>
															</th>
															<td>
																<a href="#" className="text-dark fw-bolder text-hover-primary mb-1 fs-6">Top Authors</a>
																<span className="text-muted fw-bold d-block fs-7">Successful Fellas</span>
															</td>
															<td>
																<div className="d-flex flex-column w-100 me-2">
																	<div className="d-flex flex-stack mb-2">
																		<span className="text-muted me-2 fs-7 fw-bold">70%</span>
																	</div>
																	<div className="progress h-6px w-100">
																		<div className="progress-bar bg-primary" role="progressbar" style={{width: '70%'}} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
																	</div>
																</div>
															</td>
															<td className="text-end">
																<a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
																	<span className="svg-icon svg-icon-2">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
																			<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
																		</svg>
																	</span>
																</a>
															</td>
														</tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                        {/* </div> */}
                    </div>
                    <div className="battleHistory">
                        
                        <div className='card card-xl-stretch mb-5 mb-xl-8' style={{border: 'none'}}>
                            <div className='card-body py-3'>
                                <div className='table-responsive'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead className='gy-8' style={{paddingBottom: '10%', }}>
                                    <tr >
                                        <th className='min-w-150px' >Participants</th>
                                        <th className='min-w-140px' >Date</th>
                                        <th className='min-w-120px' >Cash Prize</th>
                                        <th className='min-w-120px' >Item Reward</th>
                                        <th className='min-w-100px' >Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody className='customtbody' style={{marginTop: '10%'}}>
                                        {element}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                {/* <div className="rightCol">
                    <div className="itemSection">
                        <div className="itemH">Fighters</div>
                        
                        <div className="itemViewBox ivbT">
                            {items && items} 
                        </div>
                        <div className="itemH" onClick={()=>history.push('/allfighters')}style={{fontWeight: 'bold', marginBottom: '2%', textAlign: 'right', fontSize: 12, paddingRight: '5%', cursor: 'pointer'}}> See All Fighters {'>'}</div>
                           
                    </div>
                    <div className="performanceSection">
                        <div className="itemH">Performance</div>
                        <div className="itemViewBox" style={{flexWrap: "wrap"}}>
                            <div className="itemBox customIb2" >
                                <div style={{fontWeight: "600"}}>WIN / LOSS RATIO</div>
                                <div>{aggre && <CountUp start={0} end={aggre.winLoss.toFixed(2)} decimals={2}/>}</div>
                            </div>
                            <div className="itemBox customIb2" >
                                <div style={{fontWeight: "600"}}>DAMAGE / GAME</div>
                                <div>{aggre && <CountUp start={0} end={aggre.damage.toFixed(2)} decimals={2}/>}</div>
                            </div>
                            <div className="itemBox customIb2" >
                                <div style={{fontWeight: "600"}}>MATCHES<br/>PLAYED</div>
                                <div>{aggre && <CountUp start={0} end={aggre.matchesPlayed}/>}</div>
                            </div>
                            <div className="itemBox customIb2" >
                                <div style={{fontWeight: "600"}}>ITEMS<br/>WON</div>
                                <div>{aggre && <CountUp start={0} end={aggre.itemsWon}/>}</div>
                            </div>
                            <div className="itemBox customIb2" >
                                <div style={{fontWeight: "600"}}>LEVEL</div>
                                <div>{aggre && <CountUp start={0} end={aggre.level}/>}</div>
                            </div>
                            <div className="itemBox customIb2" >
                                <div style={{fontWeight: "600"}}>CASH WON</div>
                                <div>{aggre && <CountUp start={0} end={aggre.cash}/>}</div>
                            </div>
                        </div>
                    </div>

                </div> */}
            </div>

        </React.Fragment>

    )

}
const mapStateToProps = state =>{ 
    return ({
     keypair: state.Blockchain.keypair
  });}

export default connect(mapStateToProps)(UserDetails);