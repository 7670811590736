import React, { useEffect, useState } from 'react'
import {useHistory} from 'react-router-dom'
import axios from 'axios'
import { FaInfo, FaInfoCircle } from 'react-icons/fa'
function ItemsCard(props){
    let history = useHistory()
    const [item, setItem] = useState(null)
    const [renderHover, setRenderHover] = useState(false)
    const [left, setLeft] = useState('0px')
    const [exrate, setExrate] = useState(0)
    const [bon, setBon] = useState(null)
    const [properties, setProperties] = useState(null)
    const [address, setAddress] = useState(null)
    let quality = [ "Poor",
    "Common",
    "Uncommon",
    "Rare",
    "Epic",
    "Legendary",
    "Special"]
    useEffect(async ()=>{
        setItem(props.item)
        props.props.run && setAddress(await props.props.run.purse.address)
        let {data: exRate} = await axios.get(process.env.REACT_APP_HOST_URL+`/exchange/getmarketexchange/bsv`)
        // console.log("Call back item in card = ", props)
        setExrate(exRate)
    }, [props, renderHover])
    const displayHover = () => {
        let bonuses = ["Strength",
        "Dexterity",
        "Intelligence",
        "Health",
        "Initiative",
        "Evasion",
        "CritChance",
        "BaseDamage",
        "Piercing",
        "Slashing",
        "Bludgeoning ",
        "Fire ",
        "Cold ",
        "Necrotic ",
        "Lightning ",
        "Poison ",
        "Mystic ",
        "Warfare ",
        "XP "]
        let bon = ""
        for(let val in item.item.bonuses){
            if (item.item.bonuses[val] == true){
                bon=bon+" • "+bonuses[val]
            }
        }
        let arr = ["Innocuous",
        "Brutal",
        "Dexterous",
        "Ambivalent",
        "Assault",
        "Precision",
        "Loading",
        "Swift",
        "Keen",
        "Light",
        "Heavy",
        "Versatile",
        "Strenuous"]
        let prop =""
        for(let val in item.item.properties){
            if(item.item.properties[val]==true) prop=prop+" • "+arr[val]
        }
        setProperties(prop)
        setBon(bon)
        if(window.innerWidth<600) return
        let hov = document.getElementsByClassName(item.location)[0]
        let right = hov.offsetLeft + 380
        if(right>window.innerWidth) setLeft('-190px')
        else setLeft('0px')
        setRenderHover(true)
    }
    return(
        item && <div 
                    className={`card card-rounded shadow-sm p-5 me-10 mb-10 nft-card ${item.location}`} 
                    onMouseOut={()=>setRenderHover(false)}
                    style={{height: '260px', width: '180px'}}>
                    {renderHover && item.item.description && 
                        <div className={`testHover ${item.location+'ho'}`} style={{left: left}}>
                            <div className="openItemProperties">
                                <div className="subHeadDesc" style={{}}>{item&&item.metadata.name}</div>
                            </div>
                            <textarea className="test" disabled placeholder={item.metadata.description || item.item.description}></textarea>
                            {!item.metadata.author && <div style={{marginBottom: '5%'}}>
                                <div className="itemBonuses">
                                    BONUSES: 
                                    {bon!=="" ? <div style={{color: 'black', marginLeft: '1%'}}>{bon}</div> :
                                    <div style={{color: 'black'}}> This item has no bonuses</div>}
                                </div>
                                <div className="itemBonuses">
                                    WIELD: 
                                    {item.item.equippableSlots.length ==1 ? <div style={{color: 'black', marginLeft: '1%'}}>Dual Wielding</div> :
                                    <div style={{color: 'black', marginLeft: '1%'}}> Single Handed</div>}
                                </div>
                                <div className="itemBonuses">
                                    QUALITY : 
                                    <div style={{color: 'black', marginLeft: '1%'}}>{quality[item.item.quality]}</div> 

                                </div>
                            </div>}
                            <br/>
                            <div className="itemDescMob" style={{}}>
                                <div className="openItemImageHeader" style={{margin: 'unset'}}>ITEM PROPERTIES</div>
                                <div className="openItemProperties" style={{margin: 'unset'}}>
                                    <div className="subHead" style={{fontSize: '10px'}}>Category</div>
                                    <div className="subHeadDesc" style={{color: 'grey', fontSize: '10px'}}>Weapons</div>
                                </div>
                                {/* <div className="openItemProperties">
                                    <div>Type</div>
                                    <div style={{color: 'grey'}}>{src.split('.')[0]}</div>
                                </div> */}
                                <div className="openItemProperties" style={{margin: 'unset'}}>
                                    <div className="subHead" style={{fontSize: '10px'}}>Level Required</div>
                                    <div className="subHeadDesc" style={{color: 'grey', fontSize: '10px'}}>{item.item.levelRequired}</div>
                                </div>
                                <div className="openItemProperties" style={{margin: 'unset'}}>
                                    <div className="subHead" style={{fontSize: '10px'}}>Properties</div>
                                    <div  className="subHeadDesc" style={{color: 'grey', fontSize: '10px'}}>{properties}</div>
                                </div>
                            </div>
                            <div className="triangle"></div>
                        </div>
                    }
                    
                    {props.page == 'myitems' && !item.owner.satoshis && 
                    <div className="melt-selector">
                        <input class="form-check-input melt-checkbox" type="checkbox" value={item.location} id={item.location} onClick={(e) => props.selector(e)}></input>
                    </div>}
                    <div className="fw-bolder d-flex flex-row justify-content-between" onClick={()=>history.push(`/nft/${props.collection.fyxId}/${item.location}`)}>
                        <span>{item.metadata.name && item.metadata.name.length >=20 ? item.metadata.name.slice(0,19)+'...': item.metadata.name}</span>
                        {/* {item.metadata.name} */}
                        {item.metadata.index && <span className="text-muted">#{item.metadata.index}</span>}
                        {<span 
                            className="text-muted"
                            onMouseOver={()=>displayHover()}
                            ><FaInfoCircle size={14}/></span>}
                    </div>
                    {item.item && <div className="fs-8" onClick={()=>history.push(`/nft/${props.collection.fyxId}/${item.location}`)}>
                        {quality[item.item.quality]}
                    </div>}
                    <div className=" mt-3" onClick={()=>history.push(`/nft/${props.collection.fyxId}/${item.location}`)}>
                        {item.metadata.imageUrl && <img style={{height: '120px', width: '100%'}} src={`https://dogefiles.twetch.app/${item.metadata.imageUrl.split('b://')[1].split('/')[0]}`}></img>}
                    </div>
                    <div className="d-flex flex-row justify-content-between mt-5">
                        {props.page == 'marketplace' && props.activeTab!=='inventory' &&  <div>
                            <div className="fs-9 fw-bolder w-50px">{((item.owner.satoshis*1.04/100000000)*exrate.price).toFixed(2)} USD</div>
                            <div className="text-muted fs-10 w-50px">{(item.owner.satoshis*1.04/100000000).toFixed(6)} BSV</div>
                        </div>}
                        
                        <div className=" card card-rounded p-1 ps-3 pe-3 fs-9 h-20px bg-grey" onClick={()=>history.push(`/nft/${props.collection.fyxId}/${item.location}`)} style={{ cursor: 'pointer'}}>View</div>
                        {item.owner.address && address !== item.owner.address && <div className=" card card-rounded p-1 ps-3 pe-3 fs-9 h-20px" onClick={()=>props.callback(item)}style={{color:'#5200E5', backgroundColor: '#CDC0E5' , cursor: 'pointer'}}>Buy</div>}
                        {props.page == 'myitems' && !item.owner.satoshis && <div className=" card card-rounded p-1 ps-3 pe-3 fs-9 h-20px" onClick={()=>props.melt(item)}style={{color:'#5200E5', backgroundColor: '#CDC0E5', cursor: 'pointer'}}>Melt</div>}
                    </div>
        </div>
    )
}
export default(ItemsCard)