import React, {useState, useEffect, useCallback} from 'react';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import copy from 'copy-to-clipboard';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import axios from 'axios';
import {useHistory} from "react-router-dom";
import {FaCopy, FaCircle} from "react-icons/fa";

// import Loading from 'react-circular-loading'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import '../../../marketplace/css/marketplace.css'
import clsx from 'clsx';
import PaginationRounded, {ComponentTester} from '../../../ComponentTester';
import {useDispatch} from "react-redux";
import {formatTime} from "../../../helpers/functions/formatTime";
import {ModalWatch} from "./ModalWatch";


export const Banners = (props) => {

    const [banners, setBanners] = useState([]);
    const [activeTab, setActiveTab] = useState('published');
    const [pageNumber, setPageNumber] = useState(1);
    const [showBannerUpload, setShowBannerUpload] = useState(false);
    const [bannerTitle, setBannerTitle] = useState('test');
    const [bannerImage, setBannerImage] = useState({});

    const updateBannerList = async () => {
        let {data: {banners}} = await axios.post(process.env.REACT_APP_HOST_URL + '/media/banners', {
            tab: activeTab,
            offset: (pageNumber - 1),
            limit: 20
        });

        await setBanners(banners);
    }

    useEffect(async () => {
        await updateBannerList();

    }, [pageNumber, activeTab]);

    // const createBanner = async () => {
    //     console.log('creating banner', process.env.REACT_APP_BANNER_API_URL)
    //     let {data: {banner}} = await axios.post(process.env.REACT_APP_HOST_URL + '/media/banner/create',{});
    //     console.log("Banner", banner);

    //     setWatchItem(banner);
    // }

    const handleBannerTitleChange = (event) => {
        setBannerTitle(event.target.value)
    }
    const handleBannerImageChange = (images) => {
        console.log('got images', images);
        setBannerImage({name: 'DefaultImage', value: images[images.length - 1]})
        // onItemChange({name: "image", value: images[images.length - 1]});
    }
    const getSignedUrl = async (fileName, fileType) => {
        const body = {
            fileName,
            fileType
        }
        console.log('body', body)
        console.log('url', process.env.REACT_APP_BANNER_API_URL)
        const signedUrl = await axios({
            method: 'post',
            url: process.env.REACT_APP_BANNER_API_URL + '/banner/presigned_url',
            data: JSON.stringify(body),
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}
        })

        return signedUrl
    }

    const saveBanner = async () => {
        console.log(bannerImage)
        const data = new FormData()
        data.append('file', bannerImage)
        //const {data:{signedUrl}} = await getSignedUrl(bannerImage.value.name, bannerImage.value.type)
        //console.log('got signed url', signedUrl)
        const uploaded = await axios.post('http://localhost:3001/banner/upload', data)
        console.log(uploaded)
    }
    

    const deleteBanner = async (item) => {
        await axios.post(process.env.REACT_APP_HOST_URL + '/media/banner/delete',
            {id: item.id}
        );
        await updateBannerList();
    }

    const publishBanner = async (item) => {
        await axios.post(process.env.REACT_APP_HOST_URL + '/media/banner/publish',
            {id: item.id}
        );
        await updateBannerList();
    }

    const cardStack = () => {

        const checkStack = []
        for (let i = 0; i < banners.length; i += 1) {
            //ask if victor can be null
            const item = banners[i];
            console.log("Item to debug = ==== ", item)

            checkStack.push(<TableRow key={item.id}>
                    <TableCell>{item.type}</TableCell>
                    <TableCell>{item.title}</TableCell>
                    <TableCell>{formatTime(item.updated_at, 'datetime')}</TableCell>

                    <TableCell align="right">
                        <Button className="text-muted bg-bk-2"
                                style={{cursor: 'no-drop !important'}}
                                onClick={() => {}}
                        >Watch</Button>
                    </TableCell>
                {
                    !item.deleted_at &&
                    <TableCell align="right">
                        <Button className="text-muted bg-bk-2"
                                style={{cursor: 'no-drop !important'}}
                                onClick={() => deleteBanner(item)}
                        >Delete</Button>
                    </TableCell>
                }
                {
                    item.deleted_at &&
                    <TableCell align="right">
                        <Button className="text-muted bg-bk-2"
                                style={{cursor: 'no-drop !important'}}
                                onClick={() => publishBanner(item)}
                        >Publish</Button>
                    </TableCell>
                }
                </TableRow>
            )
        }

        return checkStack;
    }


    return (
        <React.Fragment>
            {
                <div style={{width: '100%'}}>
                    <Typography variant="h1" className="bg-bk-2">Banners</Typography>
                    <Breadcrumbs aria-label="breadcrumb"
                                 sx={{fontSize: '13px', color: '#B5B5C3', paddingBottom: '0px'}}>
                        <Link underline="hover" color="inherit" href="/">
                            Fyx Home
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            href="/"
                        >Admin
                        </Link>
                        <Typography color="#181C32" className="bg-bk-2 fw-bold">Banners</Typography>
                    </Breadcrumbs>


                    <div className="mt-10 card card-rounded shadow-sm p-3 p-lg-3 d-flex flex-row bg-bk text-light">
                        <div className={clsx("card card-rounded shadow-sm p-3 text-dark fw-bold ", {
                            "active-tab": activeTab === 'published',
                            "bg-bk": activeTab !== 'published'
                        })}
                             style={{cursor: 'pointer'}}
                             onClick={() => setActiveTab('published')}>Published
                        </div>
                        <div className={clsx("card card-rounded shadow-sm p-3 fw-bold text-dark ms-10", {
                            "active-tab": activeTab === 'deleted',
                            "bg-bk": activeTab !== 'deleted'
                        })}
                             style={{cursor: 'pointer'}}
                             onClick={() => setActiveTab('deleted')}>Deleted
                        </div>
                    </div>
                    <br/>

                    <button className="createItemButton" onClick={() => setShowBannerUpload(true)}>Create Banner</button>
                    <TableContainer component={Card} className="bg-bk">
                        <Table sx={{minWidth: '100%'}} aria-label="simple table" className="bg-bk">
                            <TableHead className="bg-bk">
                                <TableRow className="bg-bk" style={{color: 'white'}}>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Updated</TableCell>
                                    <TableCell> </TableCell>
                                    <TableCell> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cardStack()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br/>
                    <PaginationRounded page={pageNumber} pageCount={5} onChange={setPageNumber}/>
                    <br/>
                </div>
            }
            {showBannerUpload ? 
                <ModalWatch
                    title={"Edit banner"}
                    handleBannerImageChange={handleBannerImageChange}
                    bannerImage={bannerImage}
                    bannerTitle={bannerTitle}
                    handleBannerTitleChange={handleBannerTitleChange}
                    onSave={saveBanner}
                    onCancel={() => {}}
                />: null
            }
        </React.Fragment>
    )
}