import React, { Component, useEffect, useState } from 'react';
import { FaTimesCircle, FaCopy } from 'react-icons/fa';
import QRCode from 'qrcode.react';
import { connect } from "react-redux";
import { Forge } from 'txforge'
import copy from 'copy-to-clipboard';
import  {Script, Address } from 'bsv';
import { SignedMessage } from "@fyxgaming/lib/dist/signed-message";
import axios from 'axios';
import Select from 'react-select'
import { PaymailClient } from '@moneybutton/paymail-client'
const crypto = require("crypto");
const VideoPopup = ({visible, cancelPopup, url}) => {
    // console.log("item = ", item)

    useEffect(async ()=> {


    }, [])

    const cancel = () => {


        cancelPopup()
    }


    return(
        visible ? <div className="bg videoBg">
            {/* <div className="customModal walletViewModal"> */}
            <div className="videoModalView">
                
                <div className="loginSemi loginSemiWallet videoPopupScreen" style={{height: 'auto'}}>
                    {/* <img className="loginSemiImg" src="../../../../assets/loginImg.png"></img> */}
                        <FaTimesCircle className="modalCancelWallet" color="#f93939" size={20} onClick={()=>cancel()}/>
                        <video className="videoCard" width="100%" height="100%" controls>
                            <source src={url} type='video/mp4'></source>
                        </video>
                        
                </div>


            </div>
        </div> : null
        
    )
}

export default (VideoPopup);