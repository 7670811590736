import React from 'react'
import {AuthHeader} from './AuthHeader'
export function EmailVerificationSent(){
    let headerObj = {
            head: "Email Verification Pending",
            body: "An email has been sent to you. Please follow the verification instructions there"
    }
    return(
        <React.Fragment>
            <AuthHeader object={headerObj}/>
        </React.Fragment>
    )
}