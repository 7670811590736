import { AuthService } from "@fyxgaming/lib/dist/auth-service";
import initialize from '../../RunInitialize';
import axios from 'axios';
import queryString from 'query-string';
import  {Constants} from 'bsv';
import {trackSignUp, trackLogIn, trackAuth} from '../../AmplitudeTrack.js'

export class LoginService{
    constructor(props){
        console.log("as = ", props, process.env.REACT_APP_HOST_URL, AuthService)
        this.as = new AuthService(process.env.REACT_APP_HOST_URL, props.network)
    }
    async login(username, password) {
        console.log("Auth service has been declared = ", this.as)
        if(!this.as) throw "Auth Service Not Active"
        try{
            const resp =this.as && await this.as.generateKeyPair(username, password)
            console.log("Auth service keypair = ", resp)
            let recover = await this.as.recover(username, resp)
            localStorage.setItem('userHandle', username.toLowerCase())
            localStorage.setItem('privKeyString', resp.privKey.toString())
            const localData = await this.as.getProfile(username, resp)
            localStorage.setItem('emailFyx', localData.email)
            localStorage.setItem('firstName', localData.firstName)
            localStorage.setItem('lastName', localData.lastName)
            let mne = await this.as.mnemonic(username, resp)
            localStorage.setItem('mnemonic', mne)
            this.initializeInstances(recover)
            let price = await axios.get(`https://api.whatsonchain.com/v1/bsv/main/exchangerate`)
            localStorage.setItem('usdTR', price.data.rate)
            trackLogIn()
            trackAuth()
            return {bip32: recover, kp: resp}
        }
        catch(e){
            //error handling
            return {error: e}
        }
        //state management for kp
    }
    async initializeInstances(recover){
        //redux
        // let bip32 = response
        // await props.addBip(bip32) //keep
        // let t = await initialize(bip32, resp, props.config)
        // let at = JSON.stringify(bip32)
        // await props.addBlockchainInstance(t.blockchain)
        // await props.addRunInstance(t.run)
        let orL = await axios.get(process.env.REACT_APP_HOST_URL+'/chains/fyx/lib/order-lock.chain.json')
        let iL = await axios.get(process.env.REACT_APP_HOST_URL+'/chains/fyx/models/fyx-item.chain.json')
        let cL = await axios.get(process.env.REACT_APP_HOST_URL+'/chains/fyx/models/fyx-collection.chain.json')
        let fL = await axios.get(process.env.REACT_APP_HOST_URL+'/chains/cryptofights/models/fighter.chain.json')
        let t2 = {
            OrderLock: orL.data,
            FyxItem: iL.data,
            FyxCollection: cL.data,
            Fighter: fL.data
        }
        localStorage.setItem('location', JSON.stringify(t2))
        console.log("All locations set = ", t2)
    }
    async forgotPassword(username, seedwords, password){
        if(password.length < 10) throw {message: "Your password is less than 10 characters", status: 401}
        try{
            let response = this.as && await this.as.rekey(seedwords, username.toLowerCase(), password)
            return response
        }
        catch(e){
            return {error: e}
        }
    }
    async signUp(username, password, email, firstname, lastname){
        try{
            let response = this.as && await this.as.register(username, password, email, firstname, lastname)
            trackSignUp()
            return response
        }
        catch(e){
            return {error: e}
        }
    }
}