import React from 'react'
import {Link} from 'react-router-dom'
export function Header(head){
    return(
        <div className="fw-bolder fs-1 bg-bk-2">{head}</div>
    )
}
export function Breadcrumbs(head){
    let routes = {
        mywallet: {
            title: "My Wallet",
            route: "/wallet"
        },
        marketplace: {
            title: "Marketplace",
            route: "/marketplace"
        },
        myitems: {
            title: " My Items",
            route: "/myitems"
        }
    }
    return(
        <div className="fw-bold bg-bk-2">
            <Link to='/profile'>
                <a className="text-muted">Fyx Home / </a>
            </Link>
            <Link to='/profile'>
                <a className="text-muted">Portal / </a>
            </Link>
            {
                head.map(i => {
                    return(
                        <Link to={routes[i].route}>
                            <a className="text-dark fw-bold">{routes[i].title} /</a>
                        </Link>
                    )
                })
            }

        </div>
    )
}