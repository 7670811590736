import React from 'react'
import {AuthHeader} from '../../login/components/AuthHeader'
import '../css/wallet.css'
function TransactionType({props}){

    return(
        <div className="card card-rounded w-50 tx-card align-center p-5 mb-5 fyx-oh  action-box-content" style={{minHeight: '140px', height: '30%', backgroundColor: 'black !important'}} onClick={()=>props.callback && props?.callback(props.event)}>
            <div className="d-flex flex-row justify-content-around ps-10 pe-10">
                {props?.icons.map(i=>{
                    return(
                        <img src={i.url} style={{height: 50, width: 'auto'}}></img>
                    )
                })}
            </div>
            {props?.headerObj && <AuthHeader object={props.headerObj}/>}
        </div>
    )
}
export default (TransactionType)