import React, { useState, useEffect } from 'react';
const SuccessFeedback = ({visible}) => {
    const [display, setDisplay] = useState(true)
    let interval = ""

    useEffect(()=>{
        
        
        // setDisplay(visible)
        if(visible == true) {interval = setInterval(()=> {
            setDisplay(false)
        },5000)
        return () => {
            // setDisplay(true)
            clearInterval(interval);
        }}

    }
    )
    return(
        visible && display? <div className="successFeedback">Success!!</div> : null
    )
}

export default (SuccessFeedback);