import React, { Component, useState, useEffect } from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import Loader from "react-loader-spinner";
const ProgressPopup = ({visible, stringArr, title, total}) => {
    
    const stringContent = () => {
        let ret = []
        for( let i in stringArr){
            if(i==stringArr.length-1){
                let error = false, errorStr = ''
                if(stringArr[i].toLowerCase().includes('error')){
                    console.log("Check the error checkpoint = ", stringArr[i])
                    error = true
                    if(stringArr[i].toLowerCase().includes('utxo')){
                        console.log("Check the error checkpoint 2= ", stringArr[i])
                        errorStr = "Inadequate Funds : " + "This means that you do not enough funding in your account. Please topup your balance from our wallet and try again."
                    }
                    else if(stringArr[i].toLowerCase().includes('notfounderror')){
                        errorStr = "User Not found : " + "Please type in the correct userHandle of the person you want to transfer this item to."
                    }
                    else{
                        errorStr = stringArr[i]
                    }
                }
                ret.unshift(
                    <div className="methodDesc activeMethod" >{error ? <span style={{color: 'red'}}>{errorStr}</span>: stringArr[i]}</div>
                )
            }
            else{
                ret.unshift(
                    <div className="methodDesc">{stringArr[i]}</div>
                )
            }
        }
        return(ret)
    }
    return(
        visible ? <div className="bg">
            <div className="progressModal d-flex flex-column align-items-center" style={{zIndex: 1000}}>
                {/* fyxIcon */}
                <img className="popupLogoFyx" src={process.env.REACT_APP_PUBLIC_URL+`/assets/fyxlogopopup.png`}></img>

                <div className="progressTitle mb-3">{title}</div>  
                <div className="progressStringContent">
                    <Loader
                        type="Puff"
                        color="rgb(105, 63, 204)"
                        height={30}
                        width={30}
                        timeout={100000}
                        className="mb-5" //3 secs
                    />
                    {stringContent()[0]}
                    <div className='progress mt-3' style={{height: '0.4rem', width: '300px'}}>
                        <div
                            className='progress-bar'
                            role='progressbar'
                            style={{width: `${(stringArr.length*100/total)}%`, backgroundColor: ((stringArr.length*100/total) <33 ? "red" : (stringArr.length*100/total) <66 ? "rgb(114, 167, 228)" : "rgb(86, 214, 96)")}}
                        ></div>
                    </div>
                    {stringArr.length === total ? "Finished" : "In Progress"}
                </div> 
                {/* <div className="promoText"><span className="activeMethod">Pro Tip:</span> Use the top left checkbox to select multiple items.</div> */}
                <div className="promoText"><span className="activeMethod">Note:</span> Please do not click refresh while transaction is in process.</div>

            </div>
        </div> : null
        
    )
}
export default (ProgressPopup);