import React, {useEffect} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import { ForgotPassword } from './components/ForgotPassword'
import Login from './components/Login'
import { SignUp } from './components/SignUp'
import { EmailVerificationSent } from './components/EmailVerificationSent'
import { EmailAuth } from './components/EmailAuth'
import { SeedWords } from './components/SeedWords'
export const AuthPage = (props) => {
    useEffect(()=>{
        console.log("Props = ", props.config)
    },[])
    return(
        <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
        //   backgroundColor: 'gray',
        }}>
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            <a href='/auth' className='mb-12'>
                <img alt='Logo' src={process.env.REACT_APP_PUBLIC_URL+'/assets/fyxlogopopup.png'} className='h-95px' />
            </a>
            <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
                <Switch>
                    <Route path='/login' component={Login} />
                    <Route path='/forgotpassword' component={ForgotPassword} />
                    <Route path='/signup' component={SignUp} />
                    <Route path='/seed' component={SeedWords} />
                    <Route path='/emailsent' component={EmailVerificationSent} />
                    <Route path="/emails/verify/:userId/:nonce" component={EmailAuth}></Route>
                    <Redirect from='/' exact={true} to='/login' />
                    <Redirect to='/login' />
                </Switch>
            </div>
            </div>
            <div className='d-flex flex-center flex-column-auto p-10'>
            <div className='d-flex align-items-center fw-bold fs-6'>
                <a href='/privacy' className='text-muted text-hover-primary px-2'>
                About
                </a>
    
                <a href='/tos' className='text-muted text-hover-primary px-2'>
                Terms
                </a>
    
                <a href='https://help.fyxgaming.com' target='_blank' className='text-muted text-hover-primary px-2'>
                Contact Us
                </a>
            </div>
            </div>
        </div>
  
    )
}