import React, { Component, useEffect, useState } from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import axios from 'axios';
const ImagePopup = ({visible, cancelPopup, item}) => {
    // console.log("item = ", item)
    const [fetchedItem, setFetchedItem] = useState(null)

    useEffect(async ()=>{
        let im =item &&  await axios.get(item)
        // console.log('img = ', im)
        let render = ''
        
        let rend
        if(im){
            // console.log("fetched Item = ", im)
            if(im.headers['content-type'].includes('image')){
                rend = <img src={item} class="popup-img"></img>
            }
            else if(im.headers['content-type'].includes('video')){
                console.log("render video")
                rend = <video src={item} class="popup-img" controls></video>
            }
            else {
                rend = <iframe src={item} class="popup-img"></iframe>
            }
        }
        setFetchedItem(rend)

    },[item])

    // const img = await axios.get(item)
    // console.log('img = ', img)

    return(
        visible ? <div className="bg">
            <div className="customModal itemViewModal">
                
                <div className="modalRow modalRow1">
                    Your file
                    <FaTimesCircle className="modalCancel" onClick={cancelPopup}/>
                </div>

                {fetchedItem}


            </div>
        </div> : null
        
    )
}
export default ImagePopup;