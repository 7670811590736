import React from 'react'
export function WalletHeader({props}){
    return(
        <div>
            <div className="d-flex flex-direction-row justify-content-start">
                <img src={props.icon} className="w-25px h-25px "></img>
                <div className="d-flex flex-direction-row justify-content-center h-25px align-items-center">
                    <p className="fw-bolder fs-1 ms-5">{props.heading}</p>
                </div>
            </div>
            {props.content && <p className="fw-bolder fs-5 text-muted mt-5">{props.content}</p>}
        </div>
    )
}