import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import { FaAngleLeft, FaAngleRight, AiOutlineFileSearch, FaMedal } from "react-icons/fa";

import CountUp from 'react-countup';
import QRCode from 'qrcode.react';
import Carousel from 'react-bootstrap-v5/lib/Carousel'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
export const WeaveStatus = (props) => {

    const [stk, setStk] = useState()

    const { id } = useParams()
    useEffect (async () => {
            let status = await axios.get(process.env.REACT_APP_HOST_URL+`/exchange/getweavestatus/${id}`)
            setStk(status.data.data)
            console.log("status of transaction = ", status.data.data)

      },[]);



    return(
        <React.Fragment>
            <div className="">

                <div className="weaveHeader text-dark">Your Weave transaction details:</div>
                {stk && 
                <div className='weaveTransactionStatus'>
                    <div>Transaction Created on: <span>{stk.created_at.split('T')[0]}</span></div>
                    <div>Transaction ID: <span>{stk.id}</span></div>
                    <div>From Currency: <span>{stk.from_asset}</span></div>
                    <div>To Currency: <span>{stk.to_asset}</span></div>
                    <div>Deposit Address: <span>{stk.deposit_address}</span></div>
                    <div><QRCode value={stk.deposit_address} fgColor={"#4D00D5"} imageSettings={{height: "1", width: "1"}}/></div>
                    <div>Recipient Address: <span>{stk.recipient_address}</span></div>
                    <div>Status: <span>{stk.status.toUpperCase()}</span></div>

                </div>}
            </div>


        </React.Fragment>


    )
}