import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {  useHistory } from "react-router-dom";
import {  FaChevronLeft } from "react-icons/fa";
import { AuthService } from "@fyxgaming/lib/dist/auth-service";

import { FyxOwner } from "@fyxgaming/lib/dist/fyx-owner";
import Dropdown from 'react-bootstrap-v5/lib/Dropdown'

import ProgressPopup from '../src/ProgressPopup.js';
import { connect } from "react-redux";
import  {Constants} from 'bsv';
import Run from 'run-sdk';
import Select from 'react-select'
import SuccessFeedback from './SuccessFeedback';
import Popup from '../src/Popup.js';
const AllFighters = (props) => {
    Constants.Default = props.config.network === 'mainnet' ? Constants.Mainnet : Constants.Testnet;
    let as = ""
    let history = useHistory()
    if(props.config.network){
        as = new AuthService(process.env.REACT_APP_HOST_URL, props.config.network)
    }
    const [itemSection, setItemSection] = useState('myitems')
    const [currencyHeading, setCurrencyHeading] = useState('USD')

    const [itemType, setItemType] = useState('all')
    const [item, setItem] = useState([])
    const [popupVisible, setPopupVisible] = useState(false)
    const [refresh, setRefresh] = useState(true)
    const [func, setFunc] = useState('')
    const [passingItem, setPassingItem] = useState()
    const [progressPopupFlag, setProgressPopupFlag] = useState(false)
    const [progressPopupString, setProgressPopupString] = useState([])
    const [itemRender, setItemRender] = useState()
    const [exchange, setExchange] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [itemViewOpen, setItemViewOpen] = useState(false)
    const [collStack, setCollStack] = useState([])
    const [currentItem, setCurrentItem] = useState([])
    const [selectedCollection, setSelectedCollection] = useState('cryptofights')
    const [selectedCollectionLocation, setSelectedCollectionLocation] = useState('')
    const [locations, setLocations] = useState([]);
    const [feedback, setFeedback] = useState(false)
    const [collOptions, setCollOptions] = useState([])

    useEffect(async ()=>{

        const location_url = process.env.REACT_APP_HOST_URL+'/cryptofights/locations' 
        const {data: location} = await axios.get(location_url)
        let kp=props.keypair

        //new endpoint
        const respAll = await axios.post(`${process.env.REACT_APP_HOST_URL}/jigs/fighters/${localStorage.getItem('userHandle')}`,{
            limit: 20,
            offset: (pageNumber-1)*20
        })
        console.log("Fighter locations = ", respAll)
        const items = await Promise.all(respAll.data.map(async ({location}) => {
            const {data: item} = await axios(`${process.env.REACT_APP_HOST_URL}/jigs/${location}`);
            return item;
        }));
        console.log("Fighter jigs = ", items)
        const test = items
        setItem(items)
        
    },[itemSection, refresh, selectedCollection, selectedCollectionLocation, pageNumber])



    const itemBox = () => {
        // const val = item.value.item
        let src = ""
        let a = item
        const stack = []



        for(let i of a){
            // locationsTemp.push(i.location)

            stack.push(
                <div className= "itemBox" >
                    <img className="itemImage" src={i.metadata.imageUrl} onClick={()=>history.push(`/fighter/${i.origin}`)}></img>
                    <div>{i.metadata.name && i.metadata.name.length > 15 ? i.metadata.name.slice(0, 15)+'...' : i.metadata.name}</div>
                    {/* <div style={{color: 'gold', fontWeight: 'bold'}}>{currencyHeading == 'BSV' ?(i.owner.satoshis*exchange).toFixed(8) :  (i.owner.satoshis*exchange).toFixed(2)} {currencyHeading}</div> */}
                    
                </div>
            )
        }

        return stack
    }
    return(
        <React.Fragment>

                <div className="itemView">

                    {/* <div className="collBanner"> {collStack}</div> */}
                    <div className="marketplaceHeader">
                        <div className="marketplaceName">All Fighters</div>
                    </div>


                    <div className="itemContainer marketplaceItemContainer">
                            {itemBox()}
                    </div>
                            <div style={{marginLeft: '0%', paddingBottom: '10%'}}>
                                <button className="paginationButton" onClick={()=>setPageNumber(pageNumber-1)}>Prev</button>
                                {pageNumber}
                                <button className="paginationButton" onClick={()=>setPageNumber(pageNumber+1)}>Next</button>
                            </div>


                </div>
            

        </React.Fragment>

    )
}
const mapStateToProps = state =>{ 
    return ({
     keypair: state.Blockchain.keypair,
     run: state.Blockchain.run,
     blockchain: state.Blockchain.blockchain,
     bip32: state.Blockchain.bip32
  });}

export default connect(mapStateToProps)(AllFighters);
