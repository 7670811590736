import { AuthService } from "@fyxgaming/lib/dist/auth-service";
import initialize from '../../RunInitialize';
import { SignedMessage } from "@fyxgaming/lib/dist/signed-message";
import axios from 'axios';
import { Forge } from 'txforge'
import { PaymailClient } from '@moneybutton/paymail-client'
import  { KeyPair, Constants, PrivKey, Script, Address, PubKey } from 'bsv';
import queryString from 'query-string';
import {fundTransfer,fundDeposit,fundSimplexDeposit,fundWithdrawal} from '../../AmplitudeTrack.js'
const crypto = require("crypto");

export class WalletService{
    constructor(props, config){
        console.log("wallet service as = ", props, process.env.REACT_APP_HOST_URL, config, AuthService)
        this.as = new AuthService(process.env.REACT_APP_HOST_URL, config.network)
        this.props = props
    }
    async withdraw(depositAddress, amount){
        //amount in BSV always
        console.log("Inside ws withdraw")
        if(!this.props.run) throw "Wallet Service Not Active"
        try{
            const satoshis = amount * 100000000
            let kp = await this.props.run.purse.keyPair
            console.log("kp ++++++ ", kp)
            
            let to;
            const paymail = new PaymailClient();
            if(depositAddress.includes('@')) {
                const handle = localStorage.getItem('userHandle').toLowerCase();
                const senderHandle = `${handle}@${process.env.REACT_APP_PAYMAIL_HOST}`;
                const script = await paymail.getOutputFor(depositAddress, {
                    senderHandle,
                    amount: satoshis, // Amount in satoshis
                    purpose: 'FYX Withdrawl',
                    pubkey: kp.pubKey.toString()
                }, kp.privKey.toString());
                to = Address.fromTxOutScript(Script.fromHex(script)).toString();
            } else {
                to = depositAddress;
            }

            const userId = localStorage.getItem('userHandle');
            const resp = await axios(process.env.REACT_APP_HOST_URL+`/cashout/${userId}/${to}/${satoshis}`);
            let {rawtx} = resp.data;
            const parents = await this.props.run.blockchain.loadParents(rawtx);
            rawtx = await this.props.run.owner.sign(rawtx, parents);
            console.log("signed txn ===== ", rawtx)
            // if(depositAddress.includes('@')) {
            //     await paymail.sendRawTx(depositAddress, rawtx);
            // }
            let txdc = await this.props.blockchain.broadcast(rawtx);
            console.log("money sent ===== ", txdc)
            fundTransfer(satoshis*localStorage.getItem('usdTR')/100000000, satoshis, depositAddress, 'transfer')
            return txdc
        }
        catch(e){
            //error handling
            return {error: e}
        }
    }
    async simplexGetQuote(topupCurrency, topupAmount){
        console.log("in simplex get quote")
        try{
            let kp = this.props.keypair
            let resp = await axios.post(process.env.REACT_APP_HOST_URL+`/exchange/getquote`, new SignedMessage(
                {
                    "from": localStorage.getItem('userHandle'),
                    "subject": "GetQuote",
                    "payload": JSON.stringify({
                        end_user_id: localStorage.getItem('userHandle'),
                        digital_currency: "BSV",
                        fiat_currency: topupCurrency,
                        requested_currency: topupCurrency,
                        requested_amount: parseInt(topupAmount),
                        wallet_id: "fyx",
                        payment_methods: ["credit_card", "simplex_account"] 
                    })                }
                , localStorage.getItem('userHandle'), kp))
            return resp.data
        }
        catch(e){
            return {error: e}
        }
    }
    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      }
    async simplexTransaction(simplexQuote){
        console.log("in simplex get tx")
        try{
            let kp = this.props.keypair
            let username = localStorage.getItem('userHandle')
            let email = localStorage.getItem('emailFyx')
            let address = await this.props.run.purse.address
            let simplexPaymentId = this.uuidv4()
            let payload = {
                "account_details": {
                    "app_provider_id": "fyx",
                    "app_version_id": "1.3.1",
                    "app_end_user_id": username,
                    "app_install_date": "2021-08-03T15:23:12Z",
                    "email": email,
                    "verified_details":["email"],
                    "signup_login": {
                        
                        "accept_language": "de,en-US;q=0.7,en;q=0.3",
                        "http_accept_language": "de,en-US;q=0.7,en;q=0.3",
                        "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:67.0) Gecko/20100101 Firefox/67.0",
                        "timestamp": "2021-08-03T15:23:12Z"
                    }
                },
                "transaction_details": {
                    "payment_details": {
                        "quote_id": simplexQuote.quote_id,
                        "payment_id": simplexPaymentId,
                        "order_id": "609b9976-5617-4513-9560-dcfe68b2330b",
                        "destination_wallet": {
                            "currency": "BSV",
                            "address": address,
                            "tag": ""
                        },
                        "original_http_ref_url": "https://portal.fyxgaming.com/"
                    }
                }
            }
            console.log("PAYLOAD +======== ", payload)
            const resp = kp && username && email && simplexQuote && localStorage.getItem('privKeyString') && await axios.post(process.env.REACT_APP_HOST_URL+`/exchange/paymentrequest`, new SignedMessage(
                {
                    "from": localStorage.getItem('userHandle'),
                    "subject": "PaymentRequest",
                    "payload": JSON.stringify(payload)
                }
                , localStorage.getItem('userHandle'), kp))
            console.log("get payment = ", resp)
            fundSimplexDeposit(address, simplexQuote.fiat_money.currency,simplexQuote.fiat_money.total_amount, 'Simplex', simplexQuote )

            return {...resp.data, ...{simplexPaymentId: simplexPaymentId}}
        }
        catch(e){
            return {error: e}
        }
    }
    async weaveGetQuote(from_asset, to_asset, currencyValue){
        console.log("in simplex get quote")
        let msg = {
            from_asset: from_asset.toUpperCase(),
            to_asset: to_asset.toUpperCase(),
            amount: currencyValue
        }
        console.log("MSG IN WEAVE + ", msg)
        try{
            let kp = this.props.keypair
            let url = `${process.env.REACT_APP_HOST_URL}/exchange/getweavequote`
            // console.log("TESTING PAYLOAD WEAVE= ", msg)
            let {data} = await axios.post(url, new SignedMessage({
    
                "from":localStorage.getItem('userHandle'),
                "subject": "GetWeaveQuote",
                "payload": JSON.stringify(msg),
    
            }, localStorage.getItem('userHandle'), kp))
            return data.data
        }
        catch(e){
            return {error: e}
        }
    }
    async weaveCreateTransaction(addressRefund, weaveQuote, withdrawBool, memo){
        let address = withdrawBool ?  addressRefund : await this.props.run.purse.address
        console.log("in weave create tx")
        let msg = {
            recipient_address: address,
            email_address: localStorage.getItem('emailFyx'),
            first_name: localStorage.getItem('firstName'),
            last_name: localStorage.getItem('lastName'),
            country_code: 'US',
            quote_id: weaveQuote.id

        }
        console.log("MSG IN WEAVE + ", msg)
        if(memo) msg.recipient_tag = memo
        try{
            let kp = this.props.keypair
            let url = `${process.env.REACT_APP_HOST_URL}/exchange/getweavetransaction`
            console.log("MSG IN WEAVE 2 step+ ", msg)
            let {data} = await axios.post(url, new SignedMessage({
    
                "from":localStorage.getItem('userHandle'),
                "subject": "GetWeaveQuote",
                "payload": JSON.stringify(msg),
    
            }, localStorage.getItem('userHandle'), kp))
            let temp = withdrawBool ? "Withdrawal-Weave" : "Deposit-Weave"
            console.log("REACHED HEERE = ", data.data)
            const resp2 = await axios.post(process.env.REACT_APP_HOST_URL+`/exchange`, new SignedMessage(
                {
                    "from":localStorage.getItem('userHandle'),
                    "subject": "NewExchange",
                    "payload": await JSON.stringify({"transaction_id":data.data.id,"transaction_type":temp, "service": 'weave'}),
                }
                , localStorage.getItem('userHandle'), kp))
                console.log("REACHED HEERE 2= ", resp2)
            withdrawBool ?  fundWithdrawal(address,"", weaveQuote.to_asset, weaveQuote.from_asset, 'Weave', data.data) : fundDeposit(address, weaveQuote.to_asset, weaveQuote.from_asset, 'Weave', data.data)
            return data.data
        }
        catch(e){
            return {error: e}
        }
    }

}