import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
export function AuthHeader({object}){
    return(
        <div className='text-center mb-0'>
            {object.showImage && <a href='#' className='mb-12'>
                <img alt='Logo' src={process.env.REACT_APP_PUBLIC_URL+'/assets/fyxlogopopup.png'} className='h-95px' />
            </a>}
            <h1 className={clsx(
                            'mt-5 auth-head-white',{
                                'mb-3 text-dark' : !object.sizeReduction
                            },
                            {
                                'mb-3 fs-6': object.sizeReduction
                            }
                        )}>{object.head}</h1>

            <div className={clsx(
                            'text-gray-400 fw-bold',{
                                'fs-4' : !object.sizeReduction
                            },
                            {
                                'fs-8': object.sizeReduction
                            }
                        )}>
            {object.body}{' '}
            {object.url && <Link className='tx-fyx fw-bolder' to={object.url.url}>
                {object.url.body}
            </Link>}
            </div>
        </div>
    )
}