import React, {useEffect, useState} from 'react'
import {IconHeader} from '../../../helpers/components/IconHeader'
import clsx from 'clsx'
import axios from 'axios'
import Select from 'react-select'
import { FaCircle } from 'react-icons/fa'
import {Button} from '../../../helpers/components/Button'
import { connect } from "react-redux";
import { SignedMessage } from "@fyxgaming/lib/dist/signed-message";
import {Notice} from '../WalletBalance'
import Loader from "react-loader-spinner";
import ConfirmationComponent from '../ConfirmationComponent'
import { WalletService } from '../../Service/WalletService'
import TransactionDetails from '../TransactionDetails'
import Close from '../../../helpers/components/Close'
function CryptoWithdraw(props){
    let header = {
        head: 'Send(Swap) BSV for crypto exchange'
    }
    //placeholder
    let error = null
    const [level, setLevel] = useState(1)
    const [depositCurrency, setDepositCurrency] = useState('BTC')
    const [depositAmount, setDepositAmount] = useState(null)
    const [balance, setBalance] = useState(0)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [weaveObject, setWeaveObject] = useState(null)
    const [weaveTx, setWeaveTx] = useState(null)
    const [fiatMinimums, setFiatMinimums] = useState({})
    const [getPayment, setGetPayment] = useState(null)
    const [currenciesList, setCurrenciesList] = useState([])
    let list = [{value: 'USD', label: 'USD'}, {value: 'EUR', label: 'EUR'}]
    useEffect(async ()=>{
        let kp = props.keypair
        let fiat2  = await axios.get(process.env.REACT_APP_HOST_URL+`/exchange/getweavecurrencies`)
        // let curr = fiat2?.data && fiat2.data?.data.map(i => {return ({value: i.code, label: i.code})}).filter(i=> i.value.toLowerCase()!='bsv')
        let curr = []
        if(fiat2.data && fiat2.data.data){
            for(let c of fiat2.data.data){
                if(c.code.toLowerCase() == 'bsv') continue
                else if(c.code.toLowerCase() == 'usdt') curr.push({value: c.code, label: `${c.code} - ERC20`})
                else curr.push({value: c.code, label: c.code})
            }
        }
        setCurrenciesList(curr)
        setBalance((await props.run.purse.balance())/100000000)
        let tMin = {}
        for(let c of fiat2?.data?.data){
            tMin[c.code] = parseFloat(5/c.usd_rate)
        }
        setFiatMinimums(tMin)
    },[])
    let depositStepOne = async (e) => {
        e.preventDefault(e)
        // if(e.target.depositCurrency.value) setCurrencyDeposit(e.target.depositCurrency.value)
        console.log("Event in BSV deposit = ", depositCurrency, e.target.withdrawAmount.value)
        try{
            if(isNaN(e.target.withdrawAmount.value)) throw {message: "The entered amount should be a valid number."}
            if(e.target.withdrawAmount.value < (5/localStorage.getItem('usdTR'))) throw {message: `The entered amount should be greater than or equal to ${5/localStorage.getItem('usdTR')} BSV`}
            setDepositAmount(e.target.withdrawAmount.value)
            let config = process.env.REACT_APP_NETWORK === 'mainnet' ? {
                network: 'mainnet',
            } : 
            {
                network: 'testnet',
            }
            const ws = new WalletService(props, config)
            let response = await ws.weaveGetQuote('BSV',depositCurrency, e.target.withdrawAmount.value)
            console.log("RESPNSE FROM WEAVE ++++++ ", response)
            await setWeaveObject(response)
            setLevel(2)
        }
        catch(e){
            console.log("e.message = ",e.message)
            setErrorMessage(e.message)
        }
    }

    let depositStepTwo = async (e) => {
        e.preventDefault(e)
        setLevel(3)
    }
    let depositStepThree = async (e) => {
        e.preventDefault(e)
        setLoading(true)
        let config = process.env.REACT_APP_NETWORK === 'mainnet' ? {
            network: 'mainnet',
          } : 
          {
            network: 'testnet',
          }
        const ws = new WalletService(props, config)
        let memo = null
        if(depositCurrency == 'XLM') memo = e.target.walletMemo.value
        let response = await ws.weaveCreateTransaction(e.target.walletAddress.value, weaveObject, true, memo)
        console.log("response from build tx = ", response)
        let tx = [{
            title: "Transaction ID",
            value: response.id
        },{
            title: "Sending Amount",
            value: response.amount +' '+response.from_asset
        },{
            title: "Receiving Amount",
            value: response.to_amount+' '+response.to_asset
        },
        {
            title: `Receiving ${response.to_asset} Address`,
            value: response.recipient_address
        }]
        setWeaveTx(tx)
        // alert('This next step is disabled in testnet.')
        let res = await ws.withdraw(response.deposit_address, response.amount)
        // console.log("res from ws = ", res)
        window.location.reload()
    }
    let goBack = () =>{
        setLevel(level-1)
    }
    const LevelOne = () => {
        return(
            <div className="p-10">
                <div className='fv-row mb-10 flex-column d-flex '>
                    <table style={{width: '70%', alignSelf: 'center', marginBottom: '10%', marginTop: '2%'}}>
                        <tr className="bg-light">
                            <td>Current Balance</td>
                            <td>{balance}</td>
                            <td>BSV</td>
                        </tr>
                        <tr>
                            <td>USD Balance</td>
                            <td>{(balance*localStorage.getItem('usdTR')).toFixed(2)}</td>
                            <td>USD</td>
                        </tr>
                        <tr className="bg-light">
                            <td>Exchange Rate(appx.)</td>
                            <td>{(localStorage.getItem('usdTR')*1.0).toFixed(2)}</td>
                            <td>USD</td>
                        </tr>
                    </table>
                    <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Receiving token (Cash out your BSV to this token): </label>
                        </div>
                    </div>
                    <Select 
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="depositCurrency"
                            placeholder={depositCurrency} 
                            value={depositCurrency} 
                            options={currenciesList} 
                            onChange={async (e)=> await setDepositCurrency(e.value)}/>
                </div>
                <div className='fv-row mb-2'>
                    <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Sending/Cash-Out Amount (in BSV): </label>
                        </div>
                    </div>
                    <form
                    className='form w-100'
                    onSubmit={(e)=>depositStepOne(e)}
                    // style={{display: 'flex', flexDirection: 'row'}}
                    noValidate>
                    <div style={{display: 'flex',
                                    flexDirection: 'row', 
                                    // justifyContent: 'center', 
                                    height: '46px'}}>
                        <div style={{display: 'flex',
                                    flexDirection: 'column', 
                                    justifyContent: 'center', 
                                    height: '46px', 
                                    fontWeight: 700, 
                                    padding: '2px', 
                                    borderTopLeftRadius: '4px',
                                    borderBottomLeftRadius: '4px',
                                    backgroundColor: '#f5f8fa'
                                    }}>BSV
                                    </div>
                        <input
                            placeholder="0.0"
                            className={clsx(
                                'form-control form-control-lg form-control-solid mb-10'
                            )}
                            type="text"
                            value={depositAmount || null}
                            name="withdrawAmount"
                            autoComplete='off'></input>
                    </div>
                    <br/>
                    {errorMessage && Notice("red", errorMessage, "warning")}
                    <div className="fs-6 fw-bolder text-left d-flex justify-content-between align-items-center">Estimate BSV you’ll receive. {Button("Continue", null, "#5200E5")}</div>
                    </form>
                </div>
                
            </div>

        )
    }
    const LevelTwo = () => {
        return(
            
                weaveObject && 
                <React.Fragment>
                <div className="ps-10 pe-10 pt-10">
                    {weaveObject && <ConfirmationComponent 
                        sendingAmount={depositAmount} 
                        sendingCurrency={'BSV'}
                        receivingAmount={weaveObject.amount}
                        receivingCurrency={weaveObject.to_asset}
                        errorMessage={null}
                        goBack={goBack}
                        depositStepTwo={depositStepTwo}
                    />}
            </div>
            
        </React.Fragment>

        )
    }
    const LevelThree = () => {
        return(
            <div className="p-10">
                <div className='fv-row mb-1'>
                    <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Your valid {depositCurrency} Wallet address : </label>
                        </div>
                    </div>
                    <form
                    className='form w-100'
                    onSubmit={(e)=>depositStepThree(e)}
                    noValidate>
                    <input
                        placeholder="Wallet Address"
                        className={clsx(
                            'form-control form-control-lg form-control-solid '
                        )}
                        type="text"
                        name="walletAddress"
                        autoComplete='off'></input>
                    <br/>
                    <br/>
                    {depositCurrency == 'XLM' && <div>
                        <div className='d-flex justify-content-between mt-n5'>
                            <div className='d-flex flex-stack mb-2'>
                                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Your {depositCurrency} Memo: </label>
                            </div>
                        </div>
                        <input
                            placeholder="Wallet memo"
                            className={clsx(
                                'form-control form-control-lg form-control-solid mb-10'
                            )}
                            type="text"
                            name="walletMemo"
                            autoComplete='off'></input>
                    </div>}
                    {/* <div className="fs-6 fw-bolder m-5">Want to change the currency or amount?</div> */}
                    
                    {/* <div className="fs-6 fw-bolder m-5">Continue to be taken to another page to finalize the transaction.</div> */}
                    {loading ? <div style={{color: 'black', display:'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px' }}>   
                        Please wait ...                      
                        <Loader
                                type="Puff"
                                color="#00BFFF"
                                height={30}
                                width={30}
                                timeout={300000} //3 secs
                        /></div>: null}
                        <div className="d-flex justify-content-between">
                            {Button("Cancel", goBack, null)}
                            {Button("Continue", null, "#5200E5")}
                        </div>

                    </form>
                </div>
                
            </div>

        )
    }
    return(
        <React.Fragment>
            {level!=4 && <div className="pop-content card card-rounded shadow-sm p-5 p-lg-5 shrink-popup">
                <Close/>
                <IconHeader object={header}/>
                {level==1 && <LevelOne/>}
                {level==2 && <LevelTwo/>}
                {level==3 && <LevelThree/>}
            </div>}
            {level == 4 && <TransactionDetails props={weaveTx}/>}
        </React.Fragment>
    )
}
const mapStateToProps = state =>{ 
    return ({
     keypair: state.Blockchain.keypair,
     run: state.Blockchain.run,
     blockchain: state.Blockchain.blockchain
  });}
export default connect(mapStateToProps)(CryptoWithdraw)