import {
    ADD_BIP,
    ADD_BLOCKCHAIN,
    ADD_KEYPAIR,
    ADD_RUN
} from "./constants";


export const addKeypair = (payload) => {
    return({type: ADD_KEYPAIR, payload})
}
export const addRunInstance = (payload) => {
    return({type: ADD_RUN, payload})
}
export const addBlockchainInstance = (payload) => {
    return({type: ADD_BLOCKCHAIN, payload})
}
export const addBip = (payload) => {
    return({type: ADD_BIP, payload})
}