import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios'
import { connect } from "react-redux";
import { FaPlusCircle, FaTimesCircle , FaChevronUp, FaChevronDown} from "react-icons/fa";
import { Forge } from 'txforge'
import  { KeyPair, Constants, PrivKey, Script, Address } from 'bsv';
import {useHistory, Link } from "react-router-dom";
import FileViewer from 'react-file-viewer';
import Popup from '../src/Popup.js';
import ProgressPopup from '../src/ProgressPopup.js';
import { FyxOwner } from "@fyxgaming/lib/dist/fyx-owner";
import {useParams} from "react-router-dom";
import { RestStateCache } from "@fyxgaming/lib/dist/rest-state-cache";
import SuccessFeedback from './SuccessFeedback';
import Run from 'run-sdk';
import { FyxPurse } from "@fyxgaming/lib/dist/fyx-purse";
const CreateItem = (props) => {
    Constants.Default = process.env.REACT_APP_NETWORK === 'mainnet'  ? Constants.Mainnet : Constants.Testnet;
    let history = useHistory()
    let { id, collection } = useParams();
    const inputFile = useRef(null) 
    const inputFile2 = useRef(null) 
    const [tags, setTags] = useState([])
    const [progressPopupFlag, setProgressPopupFlag] = useState(false)
    const [progressPopupString, setProgressPopupString] = useState([])
    const [popupVisible, setPopupVisible] = useState(false)
    const [backing, setBacking] = useState(0.00)
    const [currency, setCurrency] = useState(0.00)
    const [tempRawTx, setTempRawTx] = useState("")
    const [supply, setSupply] = useState(1)
    const [description, setDecription] = useState(0)
    const [previewName, setPreviewName] = useState()
    const [previewUrl, setPreviewUrl] = useState()
    const [feedback, setFeedback] = useState(false)
    const [tagsDiv, setTagsDiv] = useState([])
    const [previewImage, setPreviewImage] = useState()
    const [additionalFiles, setAdditionalFiles] = useState([])
    const [additionalFilesLabel, setAdditionalFilesLabel] = useState({})
    // let source = ''
    useEffect(async ()=>{
        //new purse kp = kp.fromWif(props.run.prs.privkey)
        //await blockchain.broadcast(forge.tx.toHex())
        // withdrawal()
        console.log("collection check = ", collection)

    },[props.run])

    const onChangeFile = (event) => {
        if(event.target.files[0]){
            event.stopPropagation();
            event.preventDefault();
            var file = event.target.files[0];
            // URL.createObjectURL(event.target.files[0])
            setPreviewImage(event.target.files[0])
            setPreviewUrl(URL.createObjectURL(event.target.files[0]))
            /// if you want to upload latter
            
        }
    }
    function getBase64(file) {
        const bs64 = ''
        var reader = new FileReader();
        reader.readAsDataURL(file);

        return new Promise((resolve, reject) => {
            reader.onload = function () {
                resolve(reader.result)
                  
                };
            reader.onerror = function (error) {
                reject(error)
            };
        })
     }
    const onChangeFile2 = (event) => {
        if(event.target.files[0]){
            event.stopPropagation();
            event.preventDefault();
            var file = event.target.files[0];
            // URL.createObjectURL(event.target.files[0])
            setAdditionalFiles([...additionalFiles,event.target.files[0]])
            /// if you want to upload latter
        }
    }
    let stack = []
    const additionalFilesListing = () => {
        
        const popFile=(pos)=>{
            let c = additionalFiles.length
            setAdditionalFiles([...additionalFiles.splice(0, pos), ...additionalFiles.splice(pos+1, c)])
        }
        let str = []
        if(additionalFiles) {
            for(let i in additionalFiles){

                stack.push(
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        Label: 
                        <input required placeholder="Label for file" className="fileLabel" style={{width: 100, marginRight: '5%', marginLeft: '5%', height: 20, border: '1px solid grey', fontSize: 12, borderRadius: 4}}></input>
                        <div className="additionalFilesList" onClick={()=>popFile(i)}>
                            {additionalFiles[i].name.slice(0,10)}...  x
                            
                        </div>
                    </div>
                )
                
                
        }
        return stack
    }
    }
    const handleTags = (event) =>{
        const popFile2=(pos)=>{

        }
        if(event.key === 'Enter'){
            let targ = event.target.value
            setTags([...tags, event.target.value])
            setTagsDiv([...tagsDiv, 
                <div className="additionalFilesList" onClick={()=>popFile2(targ)}>
                    {event.target.value} x
                
                </div>
            ])
            event.target.value=""
          }
        
    }
    const openPopup = () =>{
        if(!previewName) {
            alert("Please enter a name for the collection and try again")
        }
        else{
            if(previewName.length>0){

                setPopupVisible(true)
            }
            else{
                alert("Please enter a valid name for the collection and try again")

            }
        }

        
    }
    const closePopup = () =>{
        setPopupVisible(false)
        
    }
    const uploadImagesToBTC = async (preview, attch) => {
        try {

            let forge;
            forge = new Forge({});
            let url = 'https://dogefiles.twetch.app/upload?network=testnet'
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Attachments uploading to Blockchain"])

            for(let i in attch){
                let items = attch[i]
                const form = new FormData();
                form.append('file', items)
                const {data} = await axios.post(
                    url,
                    form,
                    {headers: {'content-type': 'multipart/form-data'}}
                );
                forge.addOutput({to: data.payment_address, satoshis: data.payment_sats_needed })

            }
            
            const form = new FormData();
            form.append('file', preview)
            const {data} = await axios.post(
                url,
                form,
                {headers: {'content-type': 'multipart/form-data'}}
            );
            forge.addOutput({to: data.payment_address, satoshis: data.payment_sats_needed })
            forge.build();
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Payment Process intitated."])

            let rawtx = await props.run.purse.pay(forge.tx.toHex(), []);
            await props.blockchain.broadcast(rawtx)
            const img = await axios.post(
                'https://dogefiles.twetch.app/pay?network=testnet',
                Buffer.from(rawtx, 'hex'), 
                {headers: {'content-type': 'application/octet-stream'}}
            );
            //image upload
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Payment succesful"])

            let ob = []
            let a = document.getElementsByClassName("fileLabel")
            for(let i in img.data){
                if(i==img.data.length-1){              
                    ob.push({label: preview.name, url: "b://"+img.data[i]})               
                }
                else ob.push({label: a[i].value, url: "b://"+img.data[i]})
            }
            setTempRawTx(rawtx)
            return ob
        }
        catch(err){
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with creation${err}`])
            await new Promise((res, rej)=> {
                setTimeout(()=>{
                    res()
                }, 7000)
            })
            setProgressPopupFlag(false)
        }

    }
    

    const createItemTransaction = async () => {
        try{
            if(!previewName && previewName.length<=0) throw "Name is a required feild"
            let a = document.getElementById('curr')
            let sel =  a.options[a.selectedIndex].innerHTML
            let transferRate = await axios.get(`https://api.whatsonchain.com/v1/bsv/main/exchangerate`)
            let ex = sel.toLowerCase() === 'bsv' ? 100000000 : 100000000/transferRate.data.rate
            if(backing*ex > await props.run.purse.balance()) throw " Insufficient funds"
            // let attach = []
            // let kp = props.run.purse.keyPair
    let apiUrl = process.env.REACT_APP_HOST_URL
    // let blockchain = props.blockchain
    closePopup()
    setProgressPopupFlag(true)
    setProgressPopupString(["System ready for upload."])
    //possible change here
    let fyxId = collection == 'zenescope' ? "cryptofights" : 'nft' 
    console.log("FYX ID IS = ", fyxId)
    let owner = new FyxOwner(apiUrl, props.bip32, fyxId, localStorage.getItem('userHandle'), props.keypair);
    props.run.owner = owner
    const run = props.run
    setProgressPopupString(progressPopupString=>[...progressPopupString, "Preview Image uploading to Blockchain"])
    let ob = await uploadImagesToBTC(previewImage, additionalFiles)
    //tags
    //zenescope
    let itemJson = collection == 'zenescope' ? {
        "displayName":"Mystere",
        "type":29,
        "description":"Born with the ability to communicate with and raise the dead, Mary Medina’s life has been anything but normal. Cursed with a power she doesn’t fully understand, her world is constantly bombarded by evil. Not one to back down from a fight, Mary takes the battle to them as Mystere, Oracle of the Dead, using her power to tear down those who seek to destroy her. Death has no power over Mary Media - she is the one who wields power of the Dead!",
        "levelRequired":1,
        "abilityRequirements":[1,1,1],
        "properties":[false,false,false,false,false,false,false,false,false,false,false,false,false],
        "bonuses":[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        "damageReduction":[0,0,0,0,0,0,0,0,0,0],
        "quality":1,
        "equippableSlots":[4],
        "className":"zenescope:mystere",
        "collections":["zenescope","gen1"],
        "tags":['illusion','gen1','cryptofights','zenescope', 'mystere', 'skin']} : {}
    
    const metadata = {
        name: previewName,
        description: description,
        imageUrl: ob[ob.length-1].url,
        
        // imageUrl: "B://5197dcab799f03f7a838045f8d8315afbc5cd648130564db674df56110f61190",
        attachments: ob.slice(0,ob.length-1),
        author: localStorage.getItem('userHandle'),
        publisher: 'Fyx'
}

owner = await run.owner.nextOwner()
// let data = await axios.get(process.env.REACT_APP_HOST_URL+`/jigs/latest/${id}`)
// console.log("CUREENT LOCATION + ", data.data)
const itemInstance = await run.load(id)

setProgressPopupString(progressPopupString=>[...progressPopupString, "Blockchain transaction created"])
setProgressPopupString(progressPopupString=>[...progressPopupString, `Satoshish ===== , ${parseInt(backing * ex)}`])
//option to mint directly from the orderlock
//let temp =new Orderlock(owner, askingPrice)
// itemInstance.mint(supply, temp, metadata, itemJson, 0)
itemInstance.mint(supply, owner, metadata, itemJson, 0)
await itemInstance.sync()
        console.log("item should be synced")
        setProgressPopupString(progressPopupString=>[...progressPopupString, "Transaction broadcasted"])
        setProgressPopupString(progressPopupString=>[...progressPopupString, "Item creation successful"])
        
        await new Promise((res, rej)=> {
            setTimeout(()=>{
                res()
            }, 3000)
        })

        setFeedback(true)
        setProgressPopupFlag(false)
        // history.push('/items')
        }
        catch(err){
            if(progressPopupFlag == false) {
                closePopup()
                setProgressPopupFlag(true)
            }
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with creation${err}`])
            await new Promise((res, rej)=> {
                setTimeout(()=>{
                    res()
                }, 7000)
            })
            setProgressPopupFlag(false)
        }

    }
    const onOpenModal = () => {
        openPopup()
    }

    return(
        <div>
            <ProgressPopup visible={progressPopupFlag} stringArr={progressPopupString} title="Creating Item(s)"></ProgressPopup>
            <Popup visible={popupVisible} cancelPopup={closePopup} onCreate = {createItemTransaction}/>
            <SuccessFeedback visible={feedback}></SuccessFeedback>
            <div className="my__carousel_main createCollScreen">
                {/* <div className="score">CREATE A NEW NFT</div> */}
                <div className="nftContainer">
                    <div className="createNft">
                        CREATE A NEW NFT
                        <div className="nftForm">
                            <div className="nftHeaders">
                                NFT Name
                            </div>
                            <input required className="depositAddressInput nftInput" type="text" placeholder="Name for your Item." onChange={(e)=>setPreviewName(e.target.value)}></input>
                        </div>
                        <div className="nftForm">
                            <div className="nftHeaders">
                                Preview Image
                            </div>
                            {/* <input className="depositAddressInput nftInput nftDesc" type="file" placeholder="Short description for your Item."></input> */}
                            <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={event => onChangeFile(event)}/>
                            <div className=" depositAddressInput previewPicker" onClick={()=>inputFile.current.click()}>{!previewImage ? 
                                <button className="nft-upload" >
                                    <span>Drop your file here</span>
                                    <div class="spin">
                                        <div class="plus top-corners"></div>
                                        <div class="plus bottom-corners"></div>
                                    </div>
                                </button>
                            : <div>
                                <img className="imgPrev" src={previewUrl}></img>
                                <div className="crossImage"><FaTimesCircle color={'rgb(218, 55, 55)'} onClick={()=>setPreviewImage()}/></div>
                                </div>}
                            </div>
                        </div>
                        <div className="nftForm">
                            <div className="nftHeaders">
                                Additional Files
                            </div>
                            {/* <input className="depositAddressInput nftInput nftDesc" type="file" placeholder="Short description for your Item."></input> */}
                            <input type='file' id='file' ref={inputFile2} style={{display: 'none'}} onChange={event => onChangeFile2(event)}/>
                            <div className=" depositAddressInput filePicker" >
                                <div>
                                <div className="browse"><div className="browseButton" onClick={()=>inputFile2.current.click()}>Browse</div></div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="nftForm">
                            <div className="nftHeaders">
                                
                            </div>
                            <div className="additionalFilesCont"onClick={()=> console.log("additional files = ", additionalFiles)}>{additionalFilesListing()}</div>
                        </div>
                        <div className="nftForm">
                            <div className="nftHeaders">
                                Description
                            </div>
                            <input className="depositAddressInput nftInput nftDesc" type="text" placeholder="Short description for your Item." onChange={(e)=>setDecription(e.target.value)}></input>
                        </div>


                        <div className="nftForm">
                            <div className="nftHeaders">
                                Tags
                            </div>
                            <input className="depositAddressInput nftInput" type="text" placeholder="Type a tag and press Enter." onKeyPress={(event)=>handleTags(event)}></input>                    
                        </div>
                        <div className="nftForm">
                            <div className="nftHeaders">
                                
                            </div>
                            <div className="additionalFilesCont"onClick={()=> console.log("get elem from id = ", document.getElementById('curr').value)}>{tagsDiv}</div>
                        </div>
                        
                        <div className="nftForm">
                            <div className="nftHeaders">
                                Cash Value
                            </div>
                            {/* <input className="depositAddressInput nftInput nftDesc" type="file" placeholder="Short description for your Item."></input> */}

                            <div className=" depositAddressInput filePicker" >
                                <div className="backingInput">
                                    <select name="curr" className="curr" id="curr" className="currencySelect">
                                        <option value="usdc">BSV</option>
                                        <option value="usdc">USD</option>
                                    </select>
                                    <input className="inputCurrency" type="number" value={backing} onChange={(e)=>{
                                        setBacking(e.target.value)
                                        }}></input>
                                    <div className="incrementer">
                                        <div className="incrementerIcon" onClick={()=>setBacking(backing+1)}><FaChevronUp className="testd"/></div>
                                        <div className="incrementerIcon" onClick={()=>setBacking(backing-1)}><FaChevronDown className="testd"/></div>

                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="nftForm">
                            <div className="nftHeaders">
                                Total Supply
                            </div>
                            {/* <input className="depositAddressInput nftInput nftDesc" type="file" placeholder="Short description for your Item."></input> */}

                            <div className=" depositAddressInput filePicker" >
                                <div className="backingInput">
                                    <input className="inputCurrency" type="number" value={supply} min="1" max="1000" onChange={(e)=>{
                                        setSupply(e.target.value)
                                        }}></input>
                                    <div className="incrementer">
                                        <div className="incrementerIcon" onClick={()=>supply < 1000 && setSupply(supply+1)}><FaChevronUp className="testd"/></div>
                                        <div className="incrementerIcon" onClick={()=>supply > 1 && setSupply(supply-1)}><FaChevronDown className="testd"/></div>

                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    <div className="nftPreview">
                        
                        <div className="filePreview" style={previewUrl ? {display: 'block'}: {display: 'none'}}>
                            <div className="selectedFile">
                                <div className="filePreviewer">
                                {/* {previewImage&&<FileViewer
                                    fileType={'pdf'}
                                    filePath={previewImage}
                                    // errorComponent={CustomErrorComponent}
                                    onError={()=>console.log("error preview")}/>} */}
                                    <img style={{width: '70%', height: 'auto'}}src={previewUrl}></img>
                                </div>
                                <div className="allFiles">

                                </div>
                                {/* <div className="nftProp">
                                    <div>Tags: </div>
                                    <div>Tags: </div>
                                </div>
                                <div className="nftProp">
                                    <div>Total Supply: </div>
                                    <div>Tags: </div>
                                </div> */}
                            </div>
                            <div className="selectedDescription">
                                <div>by <span style={{color: 'gold'}}>{localStorage.getItem('userHandle')}</span></div>
                                <div><span style={{color: 'gold'}}>Display Name:</span> {previewName}</div>
                                <div><span style={{color: 'gold'}}>Backing:</span> {backing}</div><br/>
                                <div><span style={{color: 'gold'}}>Description:</span> <br/>{description}</div>
                            </div>

                        </div>
                        <button className="createItemButton mobCreateButton" onClick={()=>openPopup()}>Create Item for {backing} BSV</button>
                        {/* <button className="createItemButton" onClick={()=>testFile()}>Check labels</button> */}

                    </div>


                </div>

            </div>

        </div>
    )
}

const mapStateToProps = state =>{ 
    return ({
     keypair: state.Blockchain.keypair,
     run: state.Blockchain.run,
     blockchain: state.Blockchain.blockchain,
     bip32: state.Blockchain.bip32
  });}
export default connect(mapStateToProps)(CreateItem);