import {
    HIDE_MESSAGE, HIDE_MODAL_MSG, HIDE_NOTIFICATION,
    SHOW_MESSAGE, SHOW_MODAL_MSG, SHOW_NOTIFICATION, YES_MODAL_CLICK
} from "./constants";


export const showMessage = (key) => {
    return({type: SHOW_MESSAGE, payload: key})
}
export const hideMessage = (key) => {
    return({type: HIDE_MESSAGE, payload: key})
}
export const showNotification = (key) => {
    return({type: SHOW_NOTIFICATION, payload: key})
}
export const hideNotification = (key) => {
    return({type: HIDE_NOTIFICATION, payload: key})
}
export const showModalMsg = (key) => {
    return({type: SHOW_MODAL_MSG, payload: key})
}
export const hideModalMsg = () => {
    return({type: HIDE_MODAL_MSG, payload: {}})
}