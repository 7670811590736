import React, {useEffect, useState} from "react";
import "./scss/modal.scss";
import Loader from "react-loader-spinner";
import {FileUploader} from "../../../helpers/components/FileUploader";
import { FilePond, File, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import {formatBytes} from "../../../helpers/functions/formatBytes";
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)



export const ModalWatch = ({handleBannerImageChange, bannerTitle, handleBannerTitleChange, bannerImage, onSave, onCancel}) => {
    const [files, setFiles] = useState([])

    const [banner, setBanner] = useState({});
    
    const getButtons = () => {
        return (
            <>
                <div className='btn btn-lg btn-yes' onClick={() => {
                    onSave(banner);
                }}>Save
                </div>
                <div className='btn btn-lg btn-no' onClick={() => onCancel()}>Cancel</div>
            </>
        );
    };

    return (
        <div className="modal-msg" id="bg" onClick={(e) => (e.target.id === 'bg' && onCancel())}>
            <div className="body">
                <div className="close" onClick={() => onCancel()}>
                    <span aria-hidden="true">×</span>
                </div>

                <div className="content-title mb-3">{bannerTitle}</div>


                <div className="mb-3">
                    <label htmlFor="title">Banner Title (Alt)</label>
                    <input type="text"
                           className="form-control"
                           id="title"
                           name="title"
                           onChange={handleBannerTitleChange}
                           defaultValue={bannerTitle}
                    />
                </div>

                <FilePond
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple={true}
                    maxFiles={3}
                    server={{url: 'http://206.81.0.249:3001/api/banner/upload'}}
                    name="files" 
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />

                <div className="content-buttons">
                    {getButtons()}
                </div>

            </div>
        </div>
    );

};
