import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import clsx from 'clsx'
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Icon from '@mui/material/Icon';
import {Alert} from '@mui/material';
import {useHistory} from 'react-router';
import {
    HomeIcon,
    BattleIcon,
    MarketplaceIcon,
    ProfileIcon,
    NFTIcon,
    WalletIcon,
    TelegramIcon,
    TwitterIcon,
    DiscordIcon,
    HelpIcon,
    FeedbackIcon,
    BannersIcon,
    SettingsIcon,
    LogoutIcon
} from './icons/HomeIcons'
import {FaBars, FaHamburger} from 'react-icons/fa';


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(6)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MiniDrawer({children}) {
    const theme = useTheme();
    const history = useHistory();
    const [open, setOpen] = React.useState(true);
    React.useEffect(() => {
        if (window.innerWidth < 600) setOpen(false)
    }, [])
    const logout = () => {
        console.log("clearing ls")
        localStorage.removeItem("loggedIn")
        localStorage.clear()
        history.push('/auth')
    }
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const adminLinks = () => {
        if (localStorage.getItem('privKeyString') &&
            localStorage.getItem('userHandle') === 'becca') {
            return (
                <>
                    {open && <ListSubheader sx={{
                        background: 'none',
                        color: '#fff',
                        opacity: '.21',
                        textTransform: 'uppercase',
                        textAlign: 'left',
                        lineHeight: '0',
                        marginTop: '20px'
                    }}>Admin</ListSubheader>}
                    <ListItemButton component="a" href="/admin/banners">
                        <ListItemIcon><BannersIcon/></ListItemIcon>
                        <ListItemText>Banners</ListItemText>
                    </ListItemButton>
                </>
            )
        }
    }

    return (
        <Box sx={{display: 'flex', height: '100%', width: '100%', padding: '3%', overflow: 'scroll'}}>

            <CssBaseline/>
            <Drawer variant="permanent" open={open} className={clsx("menu-drawer", {"d-flex": open})}>
                <DrawerHeader sx={{backgroundColor: '#10011D'}}>
                    {open &&
                        <svg width="145" height="44" viewBox="0 0 145 44" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M82.4607 9.49292H85.0303L85.5121 14.2884L87.7127 9.49292H90.3672L90.8395 14.2981L93.0407 9.49292H95.5818L92.0018 16.7742H89.0922L88.544 12.2235L86.4472 16.7742H83.5286L82.4607 9.49292Z"
                                fill="white"/>
                            <path d="M95.2653 16.7742L96.6348 9.49292H99.1195L97.7591 16.7742H95.2653Z" fill="white"/>
                            <path
                                d="M101.936 16.7742H99.4984L100.868 9.49292H103.409L106.319 13.7207L107.112 9.49292H109.54L108.17 16.7742H105.78L102.748 12.4192L101.936 16.7742Z"
                                fill="white"/>
                            <path
                                d="M112.349 16.7742H109.912L111.282 9.49292H113.822L116.732 13.7207L117.525 9.49292H119.953L118.583 16.7742H116.194L113.161 12.4192L112.349 16.7742Z"
                                fill="white"/>
                            <path d="M120.325 16.7742L121.695 9.49292H124.179L122.819 16.7742H120.325Z" fill="white"/>
                            <path
                                d="M126.995 16.7742H124.558L125.928 9.49292H128.469L131.379 13.7207L132.172 9.49292H134.6L133.23 16.7742H130.84L127.808 12.4192L126.995 16.7742Z"
                                fill="white"/>
                            <path
                                d="M141.65 14.0436H139.666L139.922 12.703H144.569L143.804 16.7742H142.056C142.056 16.5004 142.019 16.1085 141.952 15.8442C141.698 16.1085 140.923 16.8914 139.26 16.8914H139.109C136.446 16.8914 135.331 15.365 135.331 13.4954C135.331 11.5872 136.417 9.36573 140.044 9.36573H140.356C144.05 9.36573 144.673 10.9023 144.597 12.0963H141.981C141.924 11.9003 141.754 11.3033 140.195 11.3033C138.297 11.3033 137.919 12.5758 137.919 13.388C137.919 14.181 138.353 14.9734 139.714 14.9734C141.111 14.9734 141.575 14.3083 141.65 14.0436Z"
                                fill="white"/>
                            <path
                                d="M82.4607 19.1233H85.0303L85.5121 23.9188L87.7127 19.1233H90.3672L90.8395 23.9285L93.0407 19.1233H95.5818L92.0018 26.4045H89.0922L88.544 21.854L86.4472 26.4045H83.5286L82.4607 19.1233Z"
                                fill="white"/>
                            <path
                                d="M100.489 20.9535C98.5524 20.9535 98.2314 22.2843 98.2314 23.0379C98.2314 23.7033 98.5619 24.5452 100.083 24.5452C102.18 24.5452 102.341 23.0285 102.341 22.4314C102.341 21.756 102.01 20.9535 100.489 20.9535ZM104.929 22.3627C104.929 24.1047 104.06 26.5319 100.281 26.5319H99.8654C96.3797 26.5319 95.6338 24.5943 95.6338 23.0965C95.6338 21.4232 96.5025 18.9961 100.281 18.9961H100.697C104.192 18.9961 104.929 20.8751 104.929 22.3627Z"
                                fill="white"/>
                            <path
                                d="M108.106 26.4045H105.669L107.039 19.1233H109.58L112.489 23.3511L113.283 19.1233H115.71L114.341 26.4045H111.951L108.919 22.0495L108.106 26.4045Z"
                                fill="white"/>
                            <path
                                d="M118.009 20.9827H116.838L117.188 19.1233H119.738L119.455 20.6306C119.332 21.2862 118.944 22.0495 118.699 22.4116H117.216C117.49 22.0297 117.858 21.3741 118.009 20.9827Z"
                                fill="white"/>
                            <path
                                d="M120.552 21.11L120.92 19.1233H128.911L128.543 21.11H125.785L124.793 26.4045H122.318L123.31 21.11H120.552Z"
                                fill="white"/>
                            <path
                                d="M82.4607 28.7533H85.0303L85.5121 33.5486L87.7127 28.7533H90.3672L90.8395 33.5585L93.0407 28.7533H95.5818L92.0018 36.0345H89.0922L88.544 31.4839L86.4472 36.0345H83.5286L82.4607 28.7533Z"
                                fill="white"/>
                            <path
                                d="M98.5714 33.1572H100.687L100.073 30.6616L98.5714 33.1572ZM97.5604 34.8403L96.833 36.0345H94.226L98.7226 28.7533H101.972L103.918 36.0345H101.396L101.103 34.8403H97.5604Z"
                                fill="white"/>
                            <path d="M104.79 36.0345L106.16 28.7533H108.645L107.284 36.0345H104.79Z" fill="white"/>
                            <path
                                d="M109.666 30.7398L110.035 28.7533H118.026L117.657 30.7398H114.899L113.908 36.0345H111.432L112.424 30.7398H109.666Z"
                                fill="white"/>
                            <path
                                d="M49.4365 31.657L47.386 27.3505L44.1054 31.657H36.9404L43.894 23.1623L39.5368 13.855L27.6763 39.4389H21.8608L25.2022 31.657H19.0615L21.0555 18.7268H14.3992L12.4078 31.657H5.86422L7.85548 18.7268H5.20868L6.01757 13.5201H8.9216L9.14217 12.0483C9.84102 7.55848 13.0022 4.6909 18.3326 4.6909C19.2427 4.6909 20.0287 4.77557 20.5045 4.85016L19.6373 10.4309C19.2824 10.3484 18.7886 10.2753 18.1489 10.2753C16.4945 10.2753 15.2816 11.1429 15.0241 12.8406L14.9139 13.5201H22.8856L26.905 13.5203L27.6119 26.1168L33.0412 13.5203H39.3801L39.3798 13.5201H46.6569L48.4891 18.7101L52.08 13.5201H59.5405L52.1285 22.5588L56.5604 31.657H49.4365ZM69.0887 8.9591H24.366L25.5975 1.03131L21.1724 0.340782C20.6676 0.262222 19.6274 0.12986 18.3326 0.12986C11.9075 0.12986 7.10731 3.4529 5.31557 8.9591H2.22624L0 23.2877H2.65401L0.662776 36.2181H18.3877L15.0462 44H30.4829L34.0908 36.2181H63.7508L57.4098 23.2012L69.0887 8.9591Z"
                                fill="white"/>
                        </svg>

                    }
                    <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
                        {/* {!open ? <ChevronRightIcon style={{color: 'white', fontSize: 34}}/> : <ChevronLeftIcon style={{color: 'white', fontSize: 34}}/>} */}

                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" viewBox="0 0 25 22" fill="none"
                             transform={open ? null : 'rotate(180)'}>
                            <path
                                d="M21.9259 0.36731H23.9749C24.3704 0.36731 24.731 0.566106 24.9011 0.877227C25.0712 1.18835 25.0189 1.55652 24.7679 1.82396L16.0779 11.0649L24.7679 20.3067C25.0189 20.5732 25.0701 20.9414 24.9011 21.2534C24.7321 21.5654 24.3704 21.7625 23.9749 21.7625H21.9259C21.6185 21.7625 21.3276 21.6421 21.1329 21.4353L11.9123 11.6292C11.6039 11.3002 11.6039 10.8286 11.9123 10.4997L21.1329 0.693586C21.3276 0.487657 21.6185 0.36731 21.9259 0.36731Z"
                                fill="#7A59FF" fillOpacity="0.5"/>
                            <path
                                d="M10.9122 0.36731H12.9612C13.3567 0.36731 13.7173 0.566106 13.8874 0.877227C14.0574 1.18835 14.0052 1.55652 13.7542 1.82396L5.06422 11.0649L13.7542 20.3067C14.0052 20.5732 14.0564 20.9414 13.8874 21.2534C13.7183 21.5654 13.3567 21.7625 12.9612 21.7625H10.9122C10.6048 21.7625 10.3138 21.6421 10.1192 21.4353L0.898521 11.6292C0.590141 11.3002 0.590141 10.8286 0.898521 10.4997L10.1192 0.693586C10.3138 0.487657 10.6048 0.36731 10.9122 0.36731Z"
                                fill="#7A59FF"/>
                        </svg>
                    </IconButton>
                </DrawerHeader>
                <Container sx={{
                    maxWidth: 'auto',
                    margin: '0px !important',
                    padding: '0px !important',
                    //text color
                    color: '#ffffff',
                    height: '100%',
                    background: 'linear-gradient(180.01deg, #18002D 18.52%, #18012E 99.99%)',
                    // background: '#050505'

                }}>
                    <List>
                        {open && <ListSubheader sx={{
                            background: 'none',
                            color: '#fff',
                            opacity: '.21',
                            textTransform: 'uppercase',
                            textAlign: 'left',
                            lineHeight: '0',
                            marginTop: '20px'
                        }}>Portal</ListSubheader>}
                        <ListItemButton component="a" href="/cryptofights">
                            <ListItemIcon><HomeIcon/></ListItemIcon>
                            <ListItemText>Fyx Home</ListItemText>
                        </ListItemButton>
                        <ListItemButton component="a" href="https://www.google.com/url?q=https://fyxgaming.substack.com/p/system-maintenance?s%3Dw&sa=D&source=hangouts&ust=1649357778043000&usg=AOvVaw30imBaDZ4zK6LyJJakxCkG">
                            <ListItemIcon><MarketplaceIcon/></ListItemIcon>
                            <ListItemText>NFT Marketplace</ListItemText>
                        </ListItemButton>
                        <ListItemButton component="a" href="/explorer">
                            <ListItemIcon><BattleIcon/></ListItemIcon>
                            <ListItemText>Battle Explorer</ListItemText>
                        </ListItemButton>


                        {open && <ListSubheader sx={{
                            background: 'none',
                            color: '#fff',
                            opacity: '.21',
                            textTransform: 'uppercase',
                            textAlign: 'left',
                            lineHeight: '0',
                            marginTop: '20px'
                        }}>My FYX</ListSubheader>}
                        <ListItemButton component="a" href="/leaderboard">
                            <ListItemIcon><ProfileIcon/></ListItemIcon>
                            <ListItemText>Leaderboard</ListItemText>
                        </ListItemButton>
                        {/* <ListItemButton component="a" href="/myitems">
            <ListItemIcon><NFTIcon /></ListItemIcon>
            <ListItemText>My NFTs</ListItemText>
          </ListItemButton> */}
                        <ListItemButton component="a" href="/wallet">
                            <ListItemIcon><WalletIcon/></ListItemIcon>
                            <ListItemText>My Wallet</ListItemText>
                        </ListItemButton>


                        {open && <ListSubheader sx={{
                            background: 'none',
                            color: '#fff',
                            opacity: '.21',
                            textTransform: 'uppercase',
                            textAlign: 'left',
                            lineHeight: '0',
                            marginTop: '20px'
                        }}>Community</ListSubheader>}
                        <ListItemButton component="a" href="https://fyx.gg/telegram" target="_blank">
                            <ListItemIcon><TelegramIcon/></ListItemIcon>
                            <ListItemText>Telegram</ListItemText>
                        </ListItemButton>
                        <ListItemButton component="a" href="https://twitter.com/CryptoFights" target="_blank">
                            <ListItemIcon><TwitterIcon/></ListItemIcon>
                            <ListItemText>Twitter</ListItemText>
                        </ListItemButton>
                        <ListItemButton component="a" href="http://fyx.gg/discord" target="_blank">
                            <ListItemIcon><DiscordIcon/></ListItemIcon>
                            <ListItemText>Discord</ListItemText>
                        </ListItemButton>


                        {open && <ListSubheader sx={{
                            background: 'none',
                            color: '#fff',
                            opacity: '.21',
                            textTransform: 'uppercase',
                            textAlign: 'left',
                            lineHeight: '0',
                            marginTop: '20px'
                        }}>Help</ListSubheader>}
                        <ListItemButton component="a" href="https://help.fyxgaming.com/">
                            <ListItemIcon><HelpIcon/></ListItemIcon>
                            <ListItemText>Help Center</ListItemText>
                        </ListItemButton>
                        <ListItemButton component="a" href="https://feedback.fyxgaming.com/">
                            <ListItemIcon><FeedbackIcon/></ListItemIcon>
                            <ListItemText>Give Feedback</ListItemText>
                        </ListItemButton>

                        {adminLinks()}

                        {open && <ListSubheader sx={{
                            background: 'none',
                            color: '#fff',
                            opacity: '.21',
                            textTransform: 'uppercase',
                            textAlign: 'left',
                            lineHeight: '0',
                            marginTop: '20px'
                        }}>More</ListSubheader>}
                        <ListItemButton component="a" href="/settings">
                            <ListItemIcon><SettingsIcon/></ListItemIcon>
                            <ListItemText>Settings</ListItemText>
                        </ListItemButton>
                        <ListItemButton component="a" onClick={() => logout()}>
                            <ListItemIcon><LogoutIcon/></ListItemIcon>
                            <ListItemText>Log Out</ListItemText>
                        </ListItemButton>
                    </List>
                    <span className="mt-5 text-muted">Version: {process.env.REACT_APP_VERSION}</span>
                </Container>
            </Drawer>

            <Box component="main" sx={{flexGrow: 1, textAlign: 'left', width: '100%'}}>
                <div className="mb-5 mobile-menu" onClick={() => setOpen(!open)}><FaBars color="#10011D" size={30}/>
                </div>
                {children}
            </Box>
        </Box>
    );
}