import React from 'react'
import {useDispatch} from "react-redux";
import {FaCopy} from 'react-icons/fa'
import copy from 'copy-to-clipboard';
import './helper.css'
import {Message} from "../../messages/Message";
import {showMessage} from "../../redux/messages/actions";

export function CopyField(text, copyField, msgContent) {

    const dispatch = useDispatch();
    const key = text.replace(/\s+/g, '_').toLowerCase();

    return (
        <div className="d-flex flex-column mt-5 w-100">
            <p className="fw-bold fs-5 fw-bolder">{text}</p>
            {copyField ?
                <div
                    className="card card-rounded bg-copy p-2 ps-5 pe-5 d-flex flex-row align-items-center justify-content-between fyx-oh  small-font-copy">

                    <div style={{wordBreak: 'break-word'}} onClick={() => {
                        copy(copyField);
                        dispatch(showMessage(key));
                    }}>{copyField}</div>

                    <Message content={msgContent} msgKey={key}>
                        <FaCopy color="#b5b5c3" onClick={() => {
                            copy(copyField);
                            dispatch(showMessage(key));
                        }}/>
                    </Message>
                </div> : <br/>}
        </div>
    )
}