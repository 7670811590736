import React, {useEffect, useState} from 'react'
import axios from 'axios'
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FaEye,FaTimesCircle,FaSync, FaPlusCircle, FaFolderPlus, FaFolderMinus, FaCopy, FaLongArrowAltRight, FaCheckCircle } from "react-icons/fa";
import Card from '@mui/material/Card';
import {connect} from 'react-redux'
import { SignedMessage } from "@fyxgaming/lib/dist/signed-message";
import { Link, useParams, useHistory } from "react-router-dom";
const crypto = require("crypto");
function WalletHistory(props){
    let history = useHistory()
    let [transactionRender, setTransactionRender] = useState([])
    const changellyApiStatus = async (id) => {
        let api_url = 'https://api.changelly.com'
        let api_key = 'a9792049081e4522879affc38fdd8945'
        let api_secret = '88b73cec9e929d81397446738354673f214970b2f9f477d8488d98255975f131'

        const message = {
            "jsonrpc": "2.0",
            "id": "test",
            "method": "getTransactions",
            "params": {
                "id": id,
                "extraId": null,
            }
         }

        const sign = crypto
        .createHmac('sha512', api_secret)
        .update(JSON.stringify(message))
        .digest('hex');
        
        const headers = {
            'api-key': api_key,
            'sign': sign, 'Content-type': 
            'application/json'
        }
        let response = await axios.post(api_url, message, {headers: headers})
        // console.log("response from changelly ALL PI= ", response)


        return response
    }
    useEffect(async () => {
        let kp = props.keypair
        console.log("All transaction = ", props)
        const resp = kp && localStorage.getItem('privKeyString') && await axios.post(process.env.REACT_APP_HOST_URL+`/exchange/${localStorage.getItem('userHandle')}`, new SignedMessage(
            {
                "from": localStorage.getItem('userHandle'),
                "to": [],
                "reply": "",
                "subject": "GetExchanges",
                "context": [],
                "payload": "",
                // offset: 100
                // "ts": Date.now(),
                // "sig": "30440220188e573e1093e7edd821af6378453653d8f5071061d70f464bb8a4d88bb5ee7402206861a863dd0df1e9577e20041fd5d6c590ce430dbf1007ce8ac78d970b29ff92"
            }
            , localStorage.getItem('userHandle'), kp))
        console.log("All transaction = ", resp)
        let ret = []
        
        let allTransactions = resp.data
        if(allTransactions && localStorage.getItem('userHandle')){

            for(let index in allTransactions){
                let items = allTransactions[index]
                let colCircle = ''
                switch(index%5){
                    case 0: {
                        colCircle=`text-primary`
                        break
                    }
                    case 1:{
                        colCircle=`text-danger`
                        break
                    }
                    case 2:{
                        colCircle=`text-success`
                        break
                    }
                    case 4:{
                        colCircle=`text-warming`
                        break
                    }
                    default:{
                        colCircle='text-warning'
                        break
                    }
                }
                // console.log("Transaction individual = ", items)
                if((items.transaction_type == 'Deposit-Weave' || items.transaction_type == 'Withdrawal-Weave' || items.transaction_type == 'fiat-topup' )) {
                    // console.log("testing item = ", items)
                    if(items.transaction_type != 'fiat-topup'  ){
                        let status = await changellyApiStatus(items._id)
                        // console.log("ALL TRANSACTIONS insitial = ", items)
                        // console.log("ALL TRANSACTIONS STATUS = ", status.data)
                        let d = new Date(items.created)
                        var year = d.getFullYear();
                        var month = (1 + d.getMonth()).toString();
                        month = month.length > 1 ? month : '0' + month;
                        var day = d.getDate().toString();
                        day = day.length > 1 ? day : '0' + day;

                        status.data && ret.push(
                            <TableRow>
                                <TableCell>{month + '.' + day + '.' + year + ' ' + d.toTimeString().slice(0, 9)}</TableCell>
                                <TableCell align="center" style={{color: '#00A3FF'}}>
                                    {localStorage.getItem('userHandle')?.toUpperCase()+' '} 
                                        <span className="depositString">{items.transaction_type == 'Withdraw' ? " withdrew from" : " deposited into" }</span> 
                                    {' '} FYX GAMING
                                </TableCell>
                                <TableCell align="center">{items.transaction_type == 'Withdraw' ? "-" : "+" } {status.data.result[0].amountExpectedFrom} {status.data.result[0].currencyFrom.toUpperCase()}</TableCell>
                                <TableCell align="center">
                                    {items.id}
                                </TableCell>
                                <TableCell align="right" onClick={()=> history.push(`/weavestatus/${items.id}`)} style={{cursor: 'pointer'}}>{status.data.result[0].status}</TableCell>
                                <TableCell align="right" onClick={()=> history.push(`/weavestatus/${items.id}`)} style={{cursor: 'pointer'}}><FaEye/></TableCell>
                            </TableRow>
                        
                        )
                    }
                    else{
                        let d = new Date(items.created)
                        var year = d.getFullYear();
                        var month = (1 + d.getMonth()).toString();
                        month = month.length > 1 ? month : '0' + month;
                        var day = d.getDate().toString();
                        day = day.length > 1 ? day : '0' + day;
                        ret.push(
                            // <div className='timeline-item'>
                            // <div class="timeline-label fw-bolder text-gray-800 fs-6">16:50</div>
                            //     <div class="timeline-badge">
                            //         <i class="fa fa-genderless fs-1" className={colCircle}></i>
                            //     </div>
                            //     <div class="timeline-content fw-mormal text-muted ps-3">{localStorage.getItem('userHandle')?.toUpperCase()} deposited into FYX GAMING</div>
                            //     <div class="timeline-content fw-mormal text-muted ps-3">{items.transactionType == 'Withdraw' ? "-" : "+" } </div>
                            //     <a href={`https://payment-status.simplex.com/#/payment/${items._id}`} target="_blank" className="timeline-content fw-mormal text-muted ps-3">{items._id}</a>
                            //     <a href={`https://payment-status.simplex.com/#/payment/${items._id}`} target="_blank" className="timeline-content fw-mormal text-muted ps-3">Check here</a>
                            //     <div className="tableElement6" onClick={()=> window.open(`https://payment-status.simplex.com/#/payment/${items._id}`, '_blank')}><FaEye/></div>

                            // </div>
                            <TableRow>
                                <TableCell>{month + '.' + day + '.' + year + ' ' + d.toTimeString().slice(0, 9)}</TableCell>
                                <TableCell align="center" style={{color: '#00A3FF'}}>{localStorage.getItem('userHandle')?.toUpperCase()} deposited into FYX GAMING</TableCell>
                                <TableCell align="center">{items.transaction_type == 'Withdraw' ? "-" : "+" } Amount here </TableCell>
                                <TableCell align="center">
                                    {items.id}
                                </TableCell>
                                <TableCell align="right" onClick={()=>window.open(`https://payment-status.simplex.com/#/payment/${items.id}`, '_blank')} style={{cursor: 'pointer'}}>Check Here</TableCell>
                                <TableCell align="right" onClick={()=>window.open(`https://payment-status.simplex.com/#/payment/${items.id}`, '_blank')} style={{cursor: 'pointer'}}><FaEye/></TableCell>
                            </TableRow>
                        )
                    }
                }
            }


        } 
        setTransactionRender(ret)

    },[])
    return(
        <React.Fragment>
            <Typography variant="h1">Wallet History</Typography>
            <Breadcrumbs aria-label="breadcrumb" sx={{fontSize: '13px', color:'#B5B5C3', paddingBottom: '0px'}}>
                <Link underline="hover" color="black" href="/" style={{color: 'black'}}>
                    Fyx Home
                </Link>
                <Link
                    underline="hover"
                    color="black"
                    href="/"
                    style={{color: 'black'}}
                >Portal
                </Link>
                <Typography color="#181C32">Wallet History</Typography>
            </Breadcrumbs>
            <br/>
            <TableContainer component={Card}>
                        <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell align="center">Description</TableCell>
                                    <TableCell align="center">Amount</TableCell>
                                    <TableCell align="center">ID</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                    <TableCell align="right">Details</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactionRender}
                            </TableBody>
                        </Table>
                    </TableContainer>
        </React.Fragment>
    )
}
const mapStateToProps = state => {
    return ({
        keypair: state.Blockchain.keypair,
        run: state.Blockchain.run,
        blockchain: state.Blockchain.blockchain,
        bip32: state.Blockchain.bip32
    });
}
export default connect(mapStateToProps)(WalletHistory)