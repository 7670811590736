import React, { useState } from 'react'
import {Notice} from './WalletBalance'
import {Button} from '../../helpers/components/Button'
import { CheckBox } from '../../helpers/components/CheckBox'
export default function ConfirmationComponent({
    sendingAmount,
    sendingCurrency,
    receivingAmount,
    receivingCurrency,
    errorMessage,
    goBack,
    depositStepTwo
    }){
    const [checked, setChecked] = useState(false)
    const setFlag = (event) => {
        setChecked(event)
    }
    return(
        <React.Fragment><div className="ps-5 pe-5">
        <table style={{width: '100%', alignSelf: 'center', marginBottom: '10%', marginTop: '2%'}}>
                <tr className="bg-light">
                    <td>You'll Send</td>
                    <td>{sendingAmount}</td>
                    <td>{sendingCurrency}</td>
                </tr>
                <tr>
                    <td>You'll receive<span style={{color: 'red'}}>*</span></td>
                    <td>{receivingAmount}</td>
                    <td>{receivingCurrency}</td>
                </tr>
                <tr className="bg-light">
                    <td>Exchange Rate(appx.)</td>
                    <td>{(localStorage.getItem('usdTR')*1.0).toFixed(2)}</td>
                    <td>USD</td>
                </tr>
            </table>
        <div className='fv-row mb-10'>
            {errorMessage && Notice("red", errorMessage, "warning")}
            {/* {Notice("#3699FF", `1 BSV is approximately ${localStorage.getItem('usdTR')}`, "info")} */}
            {CheckBox("I understand that the network fee is not yet calculated with the receiving amount.", setFlag)}
            {sendingCurrency.toLowerCase()=='usdt' || receivingCurrency.toLowerCase()=='usdt' && CheckBox("I understand that USDT token in for the ERC20 network.", setFlag)}
            <div className="d-flex justify-content-between mt-5">
                {Button("Cancel", goBack, null)}
                {checked && Button("Continue", depositStepTwo, "#5200E5")}
                {!checked && Button("Continue", null, null)}
            </div>
            
        </div>
        
    </div>
    
</React.Fragment>

)
}