
export const formatTime = (ts, type) => {
    var date =  type == 'datetime' ?  new Date(ts) : new Date(ts * 1000);
    if(type == 'datetime') return date.toLocaleString()
    // Hours part from the timestamp
    var hours = "0"+date.getHours();
    // Minutes part from the timestamp
    var minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    var seconds = "0" + date.getSeconds();

    // Will display time in 10:30:23 format
    var formattedTime = hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    return <span> {formattedTime} </span>
    // return "BUDBAK"
}