import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaAngleLeft, FaBolt, FaLink, FaTimesCircle } from "react-icons/fa";
import ProgressBar from 'react-bootstrap-v5/lib/ProgressBar'
import {useHistory } from "react-router-dom";
import {FaCopy} from 'react-icons/fa'
import Typography from '@mui/material/Typography';
import copy from 'copy-to-clipboard';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";
import { Button } from '../helpers/components/Button';
import { CopyField } from '../helpers/components/CopyField';
export const MatchSummary = (props) => {
    let history = useHistory()
    let { id, live } = useParams();
    const [match, setMatch] = useState();
    const [boxes, setBoxes] = useState();
    const [update, setUpdate] = useState();
    const [rewards, setRewards] = useState();
    const [url, setUrl] = useState(null);
    //wallet url /locations +battleKey
    let location = props.location
    const config = props.config
    useEffect( async()=>{
        let jigg_res1 = []
        jigg_res1 = await axios.get(process.env.REACT_APP_HOST_URL+"/cryptofights/battles/"+id) // +/turncount --> from socket
        console.log("Battle jig = ", jigg_res1.data)
        let jigg_res = { data: [{value: {battle: {}}}]}
        jigg_res.data[0].value.battle = jigg_res1.data
        if(jigg_res.data[0]&&jigg_res.data[0].value.battle.hasOwnProperty('rewards')){
            const rew = []
            for(let text of jigg_res.data[0].value.battle.rewards) rew.push(<div><span className="colored-name3">{text.item.displayName}</span><br/></div>)
            await setRewards(rew)
        }
        await setMatch(jigg_res.data[0])
        setUrl(`https://fyx-unikrn.s3.amazonaws.com/${jigg_res.data[0].value.battle.origin}.mp4`)
        if(jigg_res.data[0]!=undefined) timelineCard(jigg_res.data[0], jigg_res.data[0].value.battle.battlePlayers[0].fighter.metadata.name, jigg_res.data[0].value.battle.battlePlayers[1].fighter.metadata.name)

    },[update])

    useEffect(async ()=>{
        if(config.sockets != undefined){
            let ws = new WebSocket(config.sockets);
            ws.onopen = function(){
                ws.send(JSON.stringify({"action": "subscribe","channelId":"BattleUpdated"}))
            } 
            ws.onmessage = async (e) => {
                let a = JSON.parse(e.data)
                a=a.data
                a= JSON.parse("{"+a.payload.slice(1,-1)+"}")
                if(a.origin == id){
                    
                    setUpdate(!update)
                }
            }
        }
    },[url])
    const getDetails = (battle) => {
        console.log("Match in deta = , ", battle)
        let jsx = []
        jsx.push(
            <div className="d-flex flex-row justify-content-between fw-bolder fs-8 mb-3">
                <div className="">Battle Id</div>
                <div className="text-muted">{battle.origin.slice(0,20)}... &nbsp;
                        <FaCopy color="#b5b5c3" onClick={() => {
                            copy(battle.origin);
                        }}/>
                </div>
            </div>
        )
        jsx.push(
            <div className="d-flex flex-row justify-content-between fw-bolder fs-8 mb-3">
                <div className="">Started At</div>
                <div className="text-muted">{formatTime(battle.stateHistory[0].timestamp || battle.timestamp, "datetime")}</div>
            </div>
        )
        jsx.push(
            <div className="d-flex flex-row justify-content-between fw-bolder fs-8 mb-3">
                <div className="">Ended At</div>
                <div className="text-muted">{formatTime(battle.timestamp, "datetime")}</div>
            </div>
        )
        jsx.push(
            <div className="d-flex flex-row justify-content-between fw-bolder fs-8 mb-3">
                <div className="">Duration</div>
                <div className="text-muted">{parseInt((battle.timestamp - battle.stateHistory[0].timestamp)/1000)} seconds</div>
            </div>
        )
        jsx.push(
            <div className="d-flex flex-row justify-content-between fw-bolder fs-8 mb-3">
                <div className="">Arena</div>
                <div className="text-muted">{battle.rules.rulesId}</div>
            </div>
        )
        jsx.push(
            <div className="d-flex flex-row justify-content-between fw-bolder fs-8 mb-3">
                <div className="">Participants</div>
                <div className="text-muted">{battle.battlePlayers[0].fighter.metadata.name}, {battle.battlePlayers[1].fighter.metadata.name}</div>
            </div>
        )
        jsx.push(
            <div className="d-flex flex-row justify-content-between fw-bolder fs-8 mb-3">
                <div className="">Cash Reward</div>
                <div className="text-muted">${(battle.rules.reward * localStorage.getItem('usdTR') / 100000000).toFixed(2)}</div>
            </div>
        )
        jsx.push(
            battle.rewards && <div className="d-flex flex-row justify-content-between fw-bolder fs-8 mb-3">
                <div className="">Item Rewards</div>
                <div className="text-muted">{battle.rewards[0] ? battle.rewards.map(e => e.metadata.name): '-'}</div>
            </div>
        )
        jsx.push(
            <div className="d-flex flex-row justify-content-between fw-bolder fs-8 mb-3">
                <div className="">Winner</div>
                <div className="text-muted">{battle.victor ? battle.victor.fighter.metadata.name :  'N/A'}</div>
            </div>
        )
        return(jsx)
    }
    const formatTime = (ts, type) => {
        var date =  type == 'datetime' ?  new Date(ts) : new Date(ts * 1000);
        if(type == 'datetime') return date.toLocaleString()
        // Hours part from the timestamp
        var hours = "0"+date.getHours();
        // Minutes part from the timestamp
        var minutes = "0" + date.getMinutes();
        // Seconds part from the timestamp
        var seconds = "0" + date.getSeconds();

        // Will display time in 10:30:23 format
        var formattedTime = hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
        return <span> {formattedTime} </span>
        // return "BUDBAK"
    }
    const timelineCard = async (one, n1, n2) => {
        console.log("tc battle= ", one)
        const parseData = (ts, val) => {
            let str = ''
            let stack = []
            let left = 0 

            for(let item in val){
                let i = val[item]
                if(i==' ') {
                    str+=' '
                    continue}
                if(i=='<') {
                    left=1
                }
                if(i=='>') {
                    left=0
                    str+=' '
                }
                if(left==0 &&  i!='<' && i!='>'){
                    str=str+i
                }
                
            }
            //check for spaces error
            str= str.trim()
            // str = str.split('\n').reverse().join('\n ')
            let a = str.split(' ')
            let tempstr = ''
            let arr =[]
            let test = n2.toLowerCase().includes(a[0].toLowerCase()) ? 'dot-red' : 'dot-blue'
            for(let item of a){
                console.log("A + ", a)
                if(item == '') continue
                if(item == item.toUpperCase() && isNaN(item) && n1.toLowerCase().includes(item.toLowerCase())){
                    item=<span className="colored-name1" style={{color: "#C0FF8E", position: 'relative'}}>{item+' '}</span>
                }
                else if(item == item.toUpperCase() && isNaN(item) && n2.toLowerCase().includes(item.toLowerCase())){
                    item=<span className="colored-name2" style={{color: "#FFA6FA"}}>{item+' '}</span>
                }

                else {
                    if(item.includes('\n')) {
                        item=   <span className="colored-name3">  
                                    {item+' '} <br/>
                                    <span className="summary-ts">{formatTime(ts)}</span>
                                    <div className={test}></div>
                                    <div className="summary-line summary-line-custom" ></div>
                                </span>
                        // item += <span>break</span>
                    }
                    else item=<span className="colored-name3" >{item+' '}</span>
                }
                tempstr+=item
                arr.push(
                        item
                    )
                
            }
    
            arr.unshift(<span><span className="summary-ts">{formatTime(ts)}</span><div className={test}></div></span>)
            return arr
            
        }
        let actions = one && one.value.battle.stateHistory.map(item => [item.actionLogs, item.timestamp, item.location])
        let temparr = []
        console.log("Match summary items = ", actions)

        for(let all of actions){
            if(all[0].length==2){
                temparr.push([all[0][0].actionLogMessage, all[1],all[0][0].playerIndex, all[2]])
                temparr.push([all[0][1].actionLogMessage, all[1], all[0][1].playerIndex, all[2]])
            }
            else {
                let str = all[0][0].actionLogMessage
                if(all[0][0].hasOwnProperty('results') && all[0][0].results[0].hasOwnProperty('resultMessage') ){
                    str = str  +all[0][0].results[0].resultMessage
                } 
                // temparr.push([str, all[1], all[0][0].playerIndex])
                temparr.push([str, all[1],all[0][0].playerIndex, all[2]])
            }
        }
        console.log("temp arr summary items = ", temparr)

        let stack = []
        let started = []
        started.unshift(                
            <div className="timeline-box">
                <div className="action-box ">
                    <div className="action-box-headline"><span><span className="summary-ts">{formatTime(temparr[0][1])}</span><div className="dot-red"></div></span>Match has started.</div>
                </div>
                <div className="summary-line"></div>
            </div>
        )

        if(temparr){
            for(let index in temparr){
                let item = temparr[index]
                console.log("ITEM NO ", index, " === ", item)
                let ts = new Date(item[1])
                const lc = item[3].split('_')[0]
                stack.push(
                    <div className="timeline-box">
                        <div className="action-box">
                            <div className="action-box-headline">                                
                                {parseData(item[1], item[0], n1, n2)} 
                            </div>
                        </div>
                        <div className="summary-line"></div>
                    </div>
                )
            }
            let st = []
            if(one && one.value.battle.hasOwnProperty('victor')){
                let str = one.value.battle.victor.fighter.metadata.name.toUpperCase()+ " has won the Battle " 
                st.push(
                    <div className="timeline-box" style={{marginTop: '6%'}}>
                        <div className="action-box">
                            <div className="action-box-headline">{formatTime(one.value.battle.timestamp)}{parseData('yess1',str, n1, n2)}</div>
                        </div>    
                        <div className="summary-line"></div>
                    </div>
                )
            }
            await setBoxes([...started, ...stack])
        }
    }
    return(
        <React.Fragment>
            <Typography variant="h1" className="bg-bk-2">Data Explorer</Typography>
            <Breadcrumbs aria-label="breadcrumb" sx={{fontSize: '13px', color:'#B5B5C3', paddingBottom: '20px'}}>
                <Link underline="hover" color="inherit" href="/" className="text-muted">
                    Fyx Home
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    href="/"
                    className="text-muted"
                    style={{color: 'black'}}
                >Portal
                </Link>
                <Typography className="fw-bold">Data Explorer</Typography>
            </Breadcrumbs>
            {url && <div className="timelineVideo repurposed bg-bk-2">{match && match.value.battle.battlePlayers[0].fighter.metadata.name} vs {match && match.value.battle.battlePlayers[1].fighter.metadata.name}
                    {match&&match.value.battle.rules.playerCount == 2 ? 
                    <div>
                        <div className="battleVideoCard" style={{height: 'auto', width: '98%'}}>
                                <video className="videoCard" width="100%" height="100%" controls>
                                    <source src={url} type='video/mp4'></source>
                                </video>
                                
                        </div>
                    </div>
                    :
                    <img style={{width: '100%',marginTop: '5%', height: 'auto'}}src="../../assets/videoPlaceholder.png"></img>
                    }

                </div>}
            <div className="summary-cont">

                <div className="timeline ">
                    <div className="timelineCont p-5 bg-bk shadow-sm">
                        {match && <div className="fw-bolder fs-3 d-flex justify-content-between mb-10" style={{zIndex: 2}}><span>Combat Log </span><FaLink color={"#007bff"} onClick={()=>{
                            console.log("check match ====== ", match)
                            window.open(`https://whatsonchain.com/tx/${match.value.battle.location.split('_')[0]}`, '_blank')}}
                            /></div>}
                        <div style={{zIndex: 2}}>
                            
                            <div style={{zIndex: 2}}>{boxes}</div>
                        </div>
                    </div>
                </div>
                {url && <div className="timelineVideo">
                    {match&&match.value.battle.rules.playerCount == 2 ? 
                    <div>
                        <div className="battleVideoCard" style={{height: 'auto', width: '98%'}}>
                                <video className="videoCard" width="100%" height="100%" controls>
                                    <source src={url} type='video/mp4'></source>
                                </video>
                                
                        </div>
                        
                    </div>
                    :
                    <img style={{width: '100%', height: 'auto', marginTop: '5%'}} src="../../assets/videoPlaceholder.png"></img>
                    }
                    <div className="w-100 card-rounded p-10" style={{margin: '5% 5% 0% 0%', backgroundColor: 'white', height: '45.5%', overflow: 'hidden'}}>
                        
                        <div className="d-flex flex-row justify-content-between">
                            {Button("Battle Details", null, null)}
                            {Button("Contact Suppport", null, "#F64E60")}
                        </div>
                        {match.value.battle && getDetails(match.value.battle)}
                    </div>
                </div>}

            </div>
        </React.Fragment>
    )
}