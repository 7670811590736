import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./css/msg.css";
import {hideMessage} from "../redux/messages/actions";

export const Message = (props) => {

    const dispatch = useDispatch();
    const messageVisible = useSelector(state => state.Messages.messageVisible);

    const [active, setActive] = useState(false);

    useEffect(() => {

        if(!props.msgKey) {
            console.error('Messages: msgKey property should be added to Component!');
        }

        messageVisible[props.msgKey] ? showMsg() : hideMsg();

    }, [messageVisible[props.msgKey]]);


    let timeout = {};
    const showMsg = () => {

        timeout[props.msgKey] = setTimeout(() => {
            setActive(true);
        }, props.showDelay || 0);

        timeout[props.msgKey] = setTimeout(() => {
            dispatch(hideMessage(props.msgKey));
        }, props.hideDelay || 3000);
    };

    const hideMsg = () => {
        clearInterval(timeout[props.msgKey]);
        setActive(false);
    };

    return (
        <div className="msg-wrapper">
            {props.children}
            {active && (
                <div className={`msg-popup ${props.direction || "top"}`}>
                    {props.content}
                </div>
            )}
        </div>
    );
};
