import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {
    FaAngleDoubleDown,
    FaCheckCircle,
    FaCreditCard,
    FaExchangeAlt,
    FaExclamationTriangle,
    FaInfoCircle,
    FaPaperPlane,
    FaWallet
} from 'react-icons/fa'
import {Button} from '../../helpers/components/Button'
import {CopyField} from '../../helpers/components/CopyField'
import {WalletHeader} from './WalletHeader'
import axios from 'axios'
import {useDispatch, useSelector} from "react-redux";
import {SignedMessage} from "@fyxgaming/lib/dist/signed-message";

export function WalletBalance({props}) {

    let content = `Fyx Gaming runs on Bitcoin SV. USD amounts will fluctuate based on BSV market value.`
    return (
        <div>
            <WalletHeader props={{icon: '../../assets/Balance.svg', heading: "My Wallet Balance", content: content}}/>
            <div className="mt-5">
                <div className="fw-bolder fs-1">${props.balance}</div>
            </div>
            <p className="fw-bolder fs-7"> {props.convertedBalance} BSV</p>
            <div className="d-flex justify-content-start mt-10 mob-buttons-balance">
                <div className="cs-mg" onClick={() => props.callback1()}>
                    {/* <div className="mob-button-hide">{Button("Buy", props.callback1, '#3699FF')}</div> */}
                    <div className="mob-balance-icons">
                        <div className="action-icon-but"><FaCreditCard size={20} style={{color: "white"}}/></div>
                        Buy
                    </div>

                </div>
                <div className="cs-mg" onClick={() => props.callback2()}>
                    {/* <div className="mob-button-hide">{Button("Send", props.callback2, '#3699FF')}</div> */}
                    <div className="mob-balance-icons">
                        <div className="action-icon-but"><FaPaperPlane size={20} style={{color: "white"}}/></div>
                        Send
                    </div>
                </div>
                <div className="cs-mg" onClick={() => props.callback3()}>
                    {/* <div className="mob-button-hide">{Button("Receive", props.callback3,"#3699FF")}</div> */}
                    <div className="mob-balance-icons">
                        <div className="action-icon-but"><FaAngleDoubleDown size={20} style={{color: "white"}}/></div>
                        Receive
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Notice(color, text, icon) {
    let jsx
    switch (icon) {
        case "warning":
            jsx = <FaExclamationTriangle color={color}/>
            break
        case "info":
            jsx = <FaInfoCircle color={color}/>
            break
        case "success":
            jsx = <FaCheckCircle color={color}/>
            break
        default:
            jsx = null
    }
    return (
        <div className="d-flex flex-row mt-2">
            {jsx}
            <div className="fs-8 ms-1" style={{color: color}}>
                {text}
            </div>
        </div>
    )
}

export function WalletDetails({props}) {
    return (
        <div>
            <WalletHeader props={{icon: '../../assets/Details.svg', heading: "My Wallet Details"}}/>
            {CopyField("Paymail Address", props.paymail, <>Your address has been<br/> copied to the clipboard!</>)}
            {Notice("#FEB705", "Don’t use PayPal or Venmo to send to this address.", "warning")}
            {CopyField("BSV Wallet Address", props.address, <>Your address has been<br/> copied to the clipboard!</>)}
            {Notice("#FEB705", "Don’t send BTC, BCH or other tokens to this address.", "warning")}
        </div>
    )
}

export function TransactionHistory() {
    let history = useHistory()
    const dispatch = useDispatch();
    const kp = useSelector(state => state.Blockchain.keypair);
    let content = `In-game actions, Marketplace transactions or direct BSV transfers are not shown here.`
    const [tx, setTx] = useState(null)
    useEffect(async () => {
        // let kp = props.keypair
        const resp = kp && localStorage.getItem('privKeyString') && await axios.post(process.env.REACT_APP_HOST_URL + `/exchange/${localStorage.getItem('userHandle')}`, new SignedMessage(
            {
                "from": localStorage.getItem('userHandle'),
                "to": [],
                "reply": "",
                "subject": "GetExchanges",
                "context": [],
                "payload": "",
                // "ts": Date.now(),
                // "sig": "30440220188e573e1093e7edd821af6378453653d8f5071061d70f464bb8a4d88bb5ee7402206861a863dd0df1e9577e20041fd5d6c590ce430dbf1007ce8ac78d970b29ff92"
            }
            , localStorage.getItem('userHandle'), kp))
        console.log("RESP WALLET DETS = ", resp)

        if (resp.data && resp.data.length > 0) {
            setTx(resp.data[0].id);
        }
    }, [kp])

    return (
        <div>
            <WalletHeader
                props={{icon: '../../assets/History.svg', heading: "My Transaction History", content: content}}/>
            {CopyField("Transaction History", tx, 'Copied to clipboard!')}
            {Notice("#50CD89", "Success: success", "success")}
            <div className="d-flex flex-row justify-content-start mt-10 fs-7">
                {Button("Transaction Details", null, "#3699FF")}
                &nbsp; &nbsp;
                {Button("Full Transaction History", () => history.push('/wallethistory'), null)}
            </div>
        </div>
    )
}

export function GetHelp() {
    let content = `Need Asistance? Read our Help Center Articles or get in touch with the Fyx Gaming support team.`
    return (
        <div>
            <WalletHeader props={{icon: '../../assets/Support.svg', heading: "Get Help", content: content}}/>
            <br/>
            <a href='https://help.fyxgaming.com/en/articles/5300002-how-to-deposit-funds-to-your-wallet'
               target="_blank">{Notice("#3699FF", "How to Deposit funds into your account", "info")}</a>
            <a href="https://help.fyxgaming.com/en/articles/5299999-how-to-withdraw-your-earnings"
               target="_blank">{Notice("#3699FF", "How to Withdraw your earnings to another wallet", "info")}</a>
            <a href="https://help.fyxgaming.com/en/articles/5538484-fyx-token-and-wallet-faq"
               target="_blank">{Notice("#3699FF", "Fyx Gaming Wallet - Commonly Asked Questions", "info")}</a>
            <div className="d-flex flex-row justify-content-between mt-10">
                {Button("Contact Support", null, "#F64E60")}
                {Button("Fyx Gaming Help Center", () => window.open('https://help.fyxgaming.com/en/'), null)}
            </div>
        </div>
    )
}