import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import {FaAndroid, FaPlay, FaWindows} from 'react-icons/fa';
import { Link, useParams, useHistory } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { trackDownload, trackPage } from "./AmplitudeTrack";
const GameList = (props) => {
    let history = useHistory()
    useEffect(()=>{
        trackPage('Download')
    })
    return(
        <React.Fragment>
            <Typography variant="h1">Download</Typography>
            <Breadcrumbs aria-label="breadcrumb" sx={{fontSize: '13px', color:'#B5B5C3', paddingBottom: '0px'}}>
                <Link underline="hover" color="black" href="/" style={{color: 'black'}}>
                    Fyx Home
                </Link>
                <Link
                    underline="hover"
                    color="black"
                    href="/"
                    style={{color: 'black'}}
                >Portal
                </Link>
                <Typography color="#181C32">Cryptofights</Typography>
            </Breadcrumbs>
            <br/>
            <div>
                <div className="gameDets">
                    <div className="gameInfo">
                        <div className="gameInfoHead">
                            <div className="infoInactive infoActive">Game Info</div>
                            <div className="infoInactive" onClick={()=>history.push('/livematches/all')}>Highlights</div>
                            <div className="infoInactive" onClick={()=>history.push('/explorer')}>Data Explorer</div>
                            <div className="infoInactive" onClick={()=>history.push('/livematches/all')}>Live Matches</div>
                            <div className="infoInactive" onClick={()=>history.push('/leaderboard')}>Tournaments</div>
                        </div>
                        <div className="gameInfoBox1" style={{ background: `right 0% / 100% 100% no-repeat url(../../assets/crypCover.png)`}}>
                            <div className="cryptofightsAssetImage">
                                <img style={{width: '250px'}}src='../../assets/cryF.png'></img>
                            </div>
                            {/* <Link to={!localStorage.getItem('privKeyString')?'/signup':'./https://www.cryptofights.io/download'}> */}
                                <div className="dwnldButton" style={localStorage.getItem('privKeyString')!=null ? {display: 'none'}: null} onClick={()=>!localStorage.getItem('privKeyString')?history.push('/signup'+window.location.search):window.open('https://www.cryptofights.io/')}>{localStorage.getItem('privKeyString')!=null ? null
                                : 
                                <span><FaPlay color={"#00c3ff"}/>  Sign Up to play</span>}</div>
                            {/* </Link> */}
                            <div className="downloadLoggedin" style={localStorage.getItem('privKeyString')!=null ? null : {display: 'none'}}>
                                <a className="dwnldButton dButton1 "  href="https://fyx.gg/latestexe"  onClick={()=>trackDownload('exe', 'windowsplayer')}><FaWindows color={"#00c3ff"}/>   Download .exe</a>
                                <a className="dwnldButton dButton2 "  href="https://play.google.com/store/apps/details?id=com.fyxgaming.cryptofights" target="_blank"
                                    onClick={()=>trackDownload('apk', 'android')}
                                >DOWNLOAD FROM GOOGLE PLAY   </a>
                            </div>
                        </div>
                    </div>
                    <div className="gameInfo2">
                        <div className="gameInfoHead">
                            <div className="infoInactive infoActive">Details</div>
                            <div className="infoInactive">Requirements</div>
                        </div>
                        <div className="gameInfoBox2">
                            <div className="gib2Val">
                                <div className="gib2Val1">Genre</div>
                                <div className="gib2Val2">Fighting, PvP, Fantasy</div>
                            </div>
                            <div className="gib2Val">
                                <div className="gib2Val1">Languages</div>
                                <div className="gib2Val2">English</div>
                            </div>
                            <div className="gib2Val">
                                <div className="gib2Val1">Publisher</div>
                                <div className="gib2Val2">Fyx Gaming</div>
                            </div>
                            <div className="gib2Val">
                                <div className="gib2Val1">Release Date</div>
                                <div className="gib2Val2">2021</div>
                            </div>
                            <div className="gib2Val">
                                <div className="gib2Val1">Features</div>
                                <div className="gib2Val2">Cash Tournaments, Multi-player</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="glcsImage">
                    <div className="csTemp tu"><div style={{marginTop: '23vh', color: '#20155C', fontWeight: "900"}}>Coming Soon</div></div>
                    <div className="csTemp">

                        <img style={{width: '100%', height: '100%'}} src="../../assets/glcs.png"></img>
                    </div>
                </div>
            </div>
        </React.Fragment>
        
    )
}

  const mapStateToProps = state =>{ 
    return ({
     keypair: state.Blockchain.keypair,
     run: state.Blockchain.run,
     bip32: state.Blockchain.bip32,
     blockchain: state.Blockchain.blockchain
  });}
export default connect(mapStateToProps)(GameList)
