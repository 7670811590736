import React from 'react'
import { FaTimes, FaTimesCircle } from 'react-icons/fa'
import {AuthHeader} from '../../login/components/AuthHeader'
import TransactionType from './TransactionType'

function WithdrawWrapper({props}){
    let headerObj = {
        head: "Send BSV",
        showImage: false
    }

    let headerObjCrypto = {
        head: "Send BSV for crypto exchange",
        body: "USDT, ETH, 10+ more",
        showImage: false,
        sizeReduction: true,
    }
    let headerObjBSV= {
        head: "Send BSV to another wallet",
        // body: "Send BSV to another wallet",
        showImage: false,
        sizeReduction: true,
    }
    let icons1=[{url: '../../assets/Coinbase.png'}, {url: '../../assets/Handcash.png'}, {url: '../../assets/Metamask.png'}]
    let icons2=[{url: '../../assets/bsv-full.png'}]
    return(
        <React.Fragment>
            <div className="pop-content card card-rounded shadow-sm p-5 p-lg-5" style={{height: 'unset'}}>
                <div className="d-flex flex-row justify-content-end" style={{cursor: 'pointer'}} onClick={()=>props.close(false)}><FaTimes/></div>
                <AuthHeader object={headerObj}/>
                <div className="d-flex flex-column align-items-center pb-10 mt-10" style={{height: '70%'}}>
                    <TransactionType props={{icons: icons2, headerObj: headerObjBSV, callback: props?.callback, event: "BSVWithdrawal"}}/>
                    <TransactionType props={{icons: icons1, headerObj: headerObjCrypto, callback: props?.callback, event: "CryptoWithdrawal"}}/>
                </div>
            </div>
        </React.Fragment>
    )
}
export default (WithdrawWrapper)