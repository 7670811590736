import React, { useState, useEffect } from 'react';

import { connect } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from 'axios';
import Loader from "react-loader-spinner";
import VideoPopup from './VideoPopup';
const LiveMatches = (props) => {
    let history = useHistory()
    let { origin } = useParams();
    const [boxes, setBoxes] = useState(null)
    const [name, setName] = useState('')
    const [popupVisible, setPopupVisible] = useState(false)
    const [subject, setSubject] = useState('Deposit')
    const [pageNumber, setPageNumber] = useState(1)
    const [jigs, setJigs] = useState(null)
    const location_url = process.env.REACT_APP_HOST_URL+'/cryptofights/locations'
    const jig_url = process.env.REACT_APP_HOST_URL+'/jigs'
    let location = props.location
    useEffect (async () => {

       //check here
       if(!props.location) location = JSON.parse(localStorage.getItem('location'))
        if(origin !== 'all'){
            setSubject(`https://fyx-unikrn.s3.amazonaws.com/${origin}.mp4`)
            setPopupVisible(true)
        }
        const jigg_res = await axios.post(process.env.REACT_APP_HOST_URL+'/cryptofights/battles',{
            playerCount: 2,
            offset: (pageNumber-1),
            limit: 30
        })
        jigg_res.data = await Promise.all(jigg_res.data.map(async ({id}) => {
            const {data: item} = await axios(`${process.env.REACT_APP_HOST_URL}/cryptofights/battles/${id}`);
            return item;
        }));
       let val=jigg_res.data
       const box =  itemBox(jigg_res.data)
       setBoxes(box)
     },[pageNumber, subject]);
    const handleVideoMounted = element => {
        if (element !== null) {
          element.currentTime = 20;
        }
      };
    const itemBox =   (jigs) => {
        // const val = item.value.item

        const stack = []


        let locationsTemp = []
        if(jigs) for(let id = 0; id<jigs.length; id+=2){
            // locationsTemp.push(i.location)
            let i = jigs[id]
            let url = (`https://fyx-unikrn.s3.amazonaws.com/${i.origin}.jpg`)
                stack.push(
                    <div className="videoBox" onClick={()=> {
                        setSubject(`https://fyx-unikrn.s3.amazonaws.com/${i.origin}.mp4`)
                        setPopupVisible(true)
                    }} >
                        {/* <video id="vid" key={i.origin} className=" videoCard"   ref={handleVideoMounted}>
                            <source src={`https://fyx-unikrn.s3.amazonaws.com/${i.origin}.mp4`} type='video/mp4'></source>
                        </video> */}
                        <img id="vid" className=" videoCard" onError={(e)=>{e.target.onerror = null; e.target.src="../../assets/cryF.png";}} src={url}></img>
                        <div style={{textTransform: 'capitalize'}}>{i.battlePlayers[0].fighter.metadata.name} VS {i.battlePlayers[1].fighter.metadata.name}</div>
                        <div style={{color: 'gold', fontWeight: 'bold'}}>By: {i.userId}</div>
                        
                    </div>
                )
            }

        

        return stack
    }
    const closePopup = () =>{
        setPopupVisible(false)
        
    }
    return(
        // <div className="ComingSoonParent" >
        //     <TopNav login={true}/>
        //     <div className="comingSoon" >
        //      <img  className="comingSoonImg" src="../../assets/liveMatches.png"/>
        //     </div>


        //     <div style={{marginLeft: '0%',paddingTop: '10%', paddingBottom: '10%', width: '100vw'}}>
        //                         <button className="paginationButton" onClick={()=>setPageNumber(pageNumber-1)}>Prev</button>
        //                         {pageNumber}
        //                         <button className="paginationButton" onClick={()=>setPageNumber(pageNumber+1)}>Next</button>
        //     </div>

        // </div>
            <React.Fragment>
                <VideoPopup visible={popupVisible} cancelPopup={closePopup} url={subject} />
                <div className="giCont">
                    <div className="listCont">
                        <div className="highlightHeading" style={{paddingTop: '5%'}}>
                            <div className="marketplaceName">Game Highlights</div>
                            {/* <img className="listImg" src="../../assets/cryptofights.png"></img> */}
                        </div>
                    </div>
                    <div className="gameDets">
                        <div className="gameInfo">
                            <div className="gameInfoHead">
                                <div className="infoInactive" onClick={()=>history.push('/cryptofights')}>Game Info</div>
                                <div className="infoInactive infoActive" onClick={()=>history.push('/livematches/all')}>Highlights</div>
                                <div className="infoInactive" onClick={()=>history.push('/explorer')}>Data Explorer</div>
                                <div className="infoInactive" onClick={()=>history.push('/livematches/all')}>Live Matches</div>
                                <div className="infoInactive" onClick={()=>history.push('/leaderboard')}>Tournaments</div>
                            </div>

                        </div>
                    </div>
                    <div className="itemContainer marketplaceItemContainer highlightContainer">
                            {boxes ? boxes :
                                        
                                    <Loader
                                            type="Puff"
                                            color="#00BFFF"
                                            height={30}
                                            width={30}
                                            timeout={30000}
                                            className="loaderSpinner" //3 secs
                                    />

                            }
                    </div>
                    <div className="paginationButtons" >
                           <button className="paginationButton" onClick={()=>setPageNumber(pageNumber-1)}>Prev</button>
                           {pageNumber}
                           <button className="paginationButton" onClick={()=>setPageNumber(pageNumber+1)}>Next</button>
                    </div>
                </div>
            </React.Fragment>
    
    )
}

const mapStateToProps = state =>{ 
    return ({
     keypair: state.Blockchain.keypair
  });}
export default connect(mapStateToProps)(LiveMatches);