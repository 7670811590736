import React, { useState, useEffect, useRef } from 'react';

import { connect } from "react-redux";
import { FaPlusCircle, FaTimesCircle , FaChevronUp, FaChevronDown} from "react-icons/fa";
import { Forge } from 'txforge'
import  { KeyPair, Constants, PrivKey, Script, Address } from 'bsv';
import Popup from '../src/Popup.js';
import ProgressPopup from '../src/ProgressPopup.js';
import { FyxPurse } from "@fyxgaming/lib/dist/fyx-purse";
import axios from 'axios'
import {useHistory, Link } from "react-router-dom";
import { FyxOwner } from "@fyxgaming/lib/dist/fyx-owner";
import SuccessFeedback from './SuccessFeedback';
// import { RestBlockchain } from "@fyxgaming/lib/dist/rest-blockchain";
import { RestStateCache } from "@fyxgaming/lib/dist/rest-state-cache";
import Run from 'run-sdk';
const CreateCfCollection = (props) => {
    Constants.Default = process.env.REACT_APP_NETWORK === 'mainnet'  ? Constants.Mainnet : Constants.Testnet;
    let history = useHistory()
    const inputFile = useRef(null) 
    const [tags, setTags] = useState([])
    const [progressPopupFlag, setProgressPopupFlag] = useState(false)
    const [progressPopupString, setProgressPopupString] = useState([])
    
    const [popupVisible, setPopupVisible] = useState(false)
    const [backing, setBacking] = useState(0.00)
    const [description, setDecription] = useState(0)
    const [previewName, setPreviewName] = useState()
    const [feedback, setFeedback] = useState()
    const [previewUrl, setPreviewUrl] = useState()
    const [tagsDiv, setTagsDiv] = useState([])
    const [previewImage, setPreviewImage] = useState()
    let location = props.location
    useEffect(async ()=>{
        if(!props.location) location = JSON.parse(localStorage.getItem('location'))
    },[props.run])
    const onChangeFile = (event) => {
        if(event.target.files[0]){
            event.stopPropagation();
            event.preventDefault();
            var file = event.target.files[0];
            // URL.createObjectURL(event.target.files[0])
            setPreviewImage(event.target.files[0])
            setPreviewUrl(URL.createObjectURL(event.target.files[0]))
            /// if you want to upload latter
            
        }
    }
    function getBase64(file) {
        const bs64 = ''
        var reader = new FileReader();
        reader.readAsDataURL(file);

        return new Promise((resolve, reject) => {
            reader.onload = function () {
                resolve(reader.result)
                  
                };
            reader.onerror = function (error) {
                reject(error)
            };
        })
     }
    const handleTags = (event) =>{
        const popFile2=(pos)=>{

        }
        if(event.key === 'Enter'){
            let targ = event.target.value
            setTags([...tags, event.target.value])
            setTagsDiv([...tagsDiv, 
                <div className="additionalFilesList" onClick={()=>popFile2(targ)}>
                    {event.target.value} x
                
                </div>
            ])
            event.target.value=""
          }
        
    }
    const openPopup = () =>{
        if(!previewName) {
            alert("Please enter a name for the collection and try again")
        }
        else{
            if(previewName.length>0){

                setPopupVisible(true)
            }
            else{
                alert("Please enter a valid name for the collection and try again")

            }
        }

        
    }
    const closePopup = () =>{
        setPopupVisible(false)
        
    }
    const createItemTransaction = async () => {
        try{

                    closePopup()
                    setProgressPopupFlag(true)
                    setProgressPopupString(["System ready for upload."])
                    // let base = await getBase64(previewImage)
                    let apiUrl = process.env.REACT_APP_HOST_URL
                    const { run } = props;
                    // let fyxId = 'nft'
                    run.owner = new FyxOwner(apiUrl, props.bip32, 'cryptofights', localStorage.getItem('userHandle'), props.keypair);
                    //build formData 
                    let url = 'https://dogefiles.twetch.app/upload?network=testnet'
                    const form = new FormData();
                    form.append('file', previewImage)
                    setProgressPopupString(progressPopupString=>[...progressPopupString, "Preview Image uploading to Blockchain"])
                    const {data} = await axios.post(
                        url,
                        form,
                        {headers: {'content-type': 'multipart/form-data'}}
                    );
                    const forge = new Forge({outputs: [{to: data.payment_address, satoshis: data.payment_sats_needed }]});
                    //estimate
                    forge.build();
                    setProgressPopupString(progressPopupString=>[...progressPopupString, "Payment Process Initiated"])
                    const rawtx = await run.purse.pay(forge.tx.toHex(), []);
                    setProgressPopupString(progressPopupString=>[...progressPopupString, "Your wallet has been charged for the preview."])
                    const img = await axios.post(
                        'https://dogefiles.twetch.app/pay?network=testnet',
                        Buffer.from(rawtx, 'hex'), 
                        {headers: {'content-type': 'application/octet-stream'}}
                    );
                    const metadata = {
                        name: previewName,
                        description: description,
                        imageUrl: `b://${img.data[0]}`,
                        author: localStorage.getItem('userHandle'),
                        publisher: 'Fyx'
                    }
                    const FyxCollection = await run.load(location.FyxCollection.location)
                    console.log("FYX COLL === ", FyxCollection.name)
                    setProgressPopupString(progressPopupString=>[...progressPopupString, "Metadata loaded in collection"])
                    let owner = await run.owner.nextOwner()
                    console.log("NEXT OWNER === ", owner)
                    // setProgressPopupString(progressPopupString=>[...progressPopupString, "CP1"])
                    let coll = new FyxCollection(owner, metadata);
                    // setProgressPopupString(progressPopupString=>[...progressPopupString, "CP2"])
                    await coll.sync() 
                    setProgressPopupString(progressPopupString=>[...progressPopupString, "Collection Broadcast Successful"])
                    await new Promise((res, rej)=> {
                        setTimeout(()=>{
                            res()
                        }, 3000)
                    })
                    setProgressPopupFlag(false)
                    
                    setFeedback(true)
                    history.push('/all/collection')
                
        }
        catch(err){
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with creation${err}`])
            await new Promise((res, rej)=> {
                setTimeout(()=>{
                    res()
                }, 7000)

            })
            setProgressPopupFlag(false)
        }

    }
    const onOpenModal = () => {
        openPopup()
    }

    return(
        <div>
            <ProgressPopup visible={progressPopupFlag} stringArr={progressPopupString} title="Creating Collection(s)"></ProgressPopup>
            <Popup visible={popupVisible} cancelPopup={closePopup} onCreate = {createItemTransaction}/>
            <SuccessFeedback visible={feedback}></SuccessFeedback>
            <div className="my__carousel_main createCollScreen">
                {/* <div className="score">CREATE A NEW NFT</div> */}
                <div className="nftContainer">
                    <div className="createNft">
                        CREATE A NEW COLLECTION
                        <div className="nftForm">
                            <div className="nftHeaders">
                                Collection Name
                            </div>
                            <input required className="depositAddressInput nftInput" type="text" placeholder="Name for your Item." onChange={(e)=>setPreviewName(e.target.value)}></input>
                        </div>
                        <div className="nftForm">
                            <div className="nftHeaders">
                                Preview Image
                            </div>
                            {/* <input className="depositAddressInput nftInput nftDesc" type="file" placeholder="Short description for your Item."></input> */}
                            <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={event => onChangeFile(event)}/>
                            <div className=" depositAddressInput previewPicker" onClick={()=>inputFile.current.click()}>{!previewImage ? 
                                <button className="nft-upload" >
                                    <span>Drop your file here</span>
                                    <div class="spin">
                                        <div class="plus top-corners"></div>
                                        <div class="plus bottom-corners"></div>
                                    </div>
                                </button>
                                    : 
                                <div>
                                    <img className="imgPrev" src={previewUrl}></img>
                                    <div className="crossImage">   
                                        <FaTimesCircle color={'rgb(218, 55, 55)'} onClick={()=>setPreviewImage()}/>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="nftForm">
                            <div className="nftHeaders">
                                Description
                            </div>
                            <input className="depositAddressInput nftInput nftDesc" type="text" placeholder="Short description for your Item." onChange={(e)=>setDecription(e.target.value)}></input>
                        </div>
                        <div className="nftForm" style={{marginLeft: '20%'}}>
                            <button className="createItemButton" onClick={()=>openPopup()}>Create Collection</button>
                        </div>                     
                    </div>
                    <div className="nftPreview">
                        
                        <div className="filePreview" style={previewUrl ? {display: 'block'}: {display: 'none'}}>
                            Preview 
                            <div className="selectedFile">
                                <div className="filePreviewer">
                                    <img style={{width: '70%', height: 'auto'}}src={previewUrl}></img>
                                </div>
                                <div className="allFiles">

                                </div>
                            </div>
                            <div className="selectedDescription">
                                <div>by <span style={{color: 'gold'}}>{localStorage.getItem('userHandle')}</span></div>
                                <div><span style={{color: 'gold'}}>Display Name:</span> {previewName}</div><br/>
                                <div><span style={{color: 'gold'}}>Description:</span> <br/>{description}</div>
                            </div>

                        </div>

                </div>
                </div>
                


            </div>


        </div>
    )
}

const mapStateToProps = state =>{ 
    return ({
     keypair: state.Blockchain.keypair,
     run: state.Blockchain.run,
     blockchain: state.Blockchain.blockchain,
     bip32: state.Blockchain.bip32
  });}
export default connect(mapStateToProps)(CreateCfCollection);