import React, {useState, useEffect} from 'react'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import {LoginService} from '../service/LoginService'
import '../css/Login.css'
import { AuthHeader } from './AuthHeader'
import { Forms } from '../../helpers/components/Forms'
export const ForgotPassword = () => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({state: false})
    let history = useHistory()
    let obj = {head: "Reset Your Password.", body:"You'll need your seed words"}
    let fields = [
      {title: 'Username', name:'username', type:'text'},
      {title: 'Seed Words', name:'seedwords',type:'text-area'},
      {title: 'New Password', name:'password', type:'password'}
    ]
    useEffect(()=>{

    },[error])
    const forgotPasswordSubmit = async (e) => {
      e.preventDefault()
      let config = process.env.REACT_APP_NETWORK === 'mainnet' ? {
          network: 'mainnet',
        } : 
        {
          network: 'testnet',
        }
      const ls = new LoginService(config)
      try{
        console.log("Seed words text area = ", e.target.seedwords.value)
        await ls.forgotPassword(e.target.username.value, e.target.seedwords.value.trim(), e.target.password.value)
        history.push('/cryptofights')
      }
      catch(e){

          console.log("error in logins = ",e ,"+++", e.message, e.status)

          if(e?.status == 401){
            setError({
              state: true,
              status: 401,
              field: 'password',
              message: "Your new password should be more than 10 characters"
            })
          }
          else{
              console.log("message = ", e.message)
              let err = "Your Seed words do not match our records please try again"
              setError({
                state: true,
                message: err,
                field: 'seedwords',
                status: 402
              })
          }
      }
  }
    return(
        <React.Fragment>
            {Forms(forgotPasswordSubmit, obj, fields, error)}
        </React.Fragment>
    )
}