import React, {useEffect, useState} from 'react'
import {Forms} from '../../../helpers/components/Forms'
import {AuthHeader} from '../../../login/components/AuthHeader'
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import copy from 'copy-to-clipboard';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { connect } from "react-redux";
import {WalletService} from '../../Service/WalletService'
import Close from '../../../helpers/components/Close';
function BSVWithdraw(props){
    let headerObj = {
        head: "Send BSV directly to another wallet",
        // body: "Send funds instantly to another wallet or service that supports direct BSV deposits",
        showImage: false
    }
    console.log("in BSV deposit props = ", props)
    const [error, setError] = useState(null)
    const [balance, setBalance] = useState(0)
    const [errorMessage, setErrorMessage] = useState("testing")
    const fields = [{
        title: 'Sending Amount (in BSV)',
        placeholder: '0.0',
        type: 'text',
        name: 'amount'
    },{
        title: 'Receiving destination: Valid BSV/Paymail address',
        placeholder: 'Address or Paymail',
        type: 'text',
        name: 'destination'
    }]
    useEffect(async ()=>{
        let bal = await props.run.purse.balance()
        console.log("balanave = ", bal)
        setBalance(bal/100000000)
    },[props, error])
    async function withdraw(e){
        e.preventDefault()
        let config = process.env.REACT_APP_NETWORK === 'mainnet' ? {
            network: 'mainnet',
          } : 
          {
            network: 'testnet',
          }
        const ws = new WalletService(props, config)
        console.log("Event for withdraw = ", e)
        let amount = e.target.amount.value
        let address = e.target.destination.value
        if(isNaN(amount) || amount>=balance){

         setError({
            state: true,
            message: "Your withdrawal amount set is invalid. Please enter a valid number less than your account balance",
            field: 'amount'
          })
          return
        }
        let res = await ws.withdraw(address, amount)
        if(res.error) {
            console.log("Response error ===== ", res.error.message)
            if(res.error.message.toLowerCase().includes('checksum')){
                setError({
                    state: true,
                    message: "Your deposit address is invalid. Please try again.",
                    field: 'destination'
                  })
            }
            else if(res.error.message.toLowerCase().includes('400')){
                setError({
                    state: true,
                    message: "Your withdrawal amount set is invalid. Please enter a value less than your account balance",
                    field: 'amount'
                  })
            }
            else{
                setError({
                    state: true,
                    message: res.error.message,
                    field: 'amount'
                  })
            }
        }
        console.log("AMount = ", amount, "address = ", address, "res === ", res)
        //close
        window.location.reload()
    }
    return(
    <React.Fragment>
        <div className="pop-content card card-rounded shadow-sm p-5 p-lg-5 shrink-popup">
            <Close/>
            <br/>
            <AuthHeader object={headerObj}/>
                <table style={{width: '70%', alignSelf: 'center', marginBottom: '10%', marginTop: '2%'}}>
                    <tr className="bg-light">
                        <td>Current Balance</td>
                        <td>{balance}</td>
                        <td>BSV</td>
                    </tr>
                    <tr>
                        <td>USD Balance</td>
                        <td>{(balance*localStorage.getItem('usdTR')).toFixed(2)}</td>
                        <td>USD</td>
                    </tr>
                    <tr className="bg-light">
                        <td>Exchange Rate(appx.)</td>
                        <td>{(localStorage.getItem('usdTR')*1.0).toFixed(2)}</td>
                        <td>USD</td>
                    </tr>
                </table>
                <div className='ps-20 pe-20'>
                    {/* qr*/}
                    {Forms(withdraw, null, fields, error)}
                </div>
        </div>
    </React.Fragment>
    )
}
  const mapStateToProps = state =>{ 
    return ({
     keypair: state.Blockchain.keypair,
     run: state.Blockchain.run,
     blockchain: state.Blockchain.blockchain
  });}
  export default connect(mapStateToProps)(BSVWithdraw);