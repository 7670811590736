import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {AuthHeader} from './AuthHeader'
import {CheckBox} from '../../helpers/components/CheckBox'
import {Button} from '../../helpers/components/Button'
export function SeedWords(){
    let history = useHistory()
    let headerObj = {
        head: "Seed Words",
    }
    const [checked, setChecked] = useState(false)
    useEffect(()=>{
        console.log("Changed checked = ", checked)
    },[checked])
    const redirect = () => {
        checked && history.push('/wallet')
    }
    return(
        <React.Fragment>
            <AuthHeader object={headerObj}/>
            <div className='fv-row mb-10'>
                <div className='d-flex justify-content-left flex-column mt-n5'>
                    <div className="fw-bolder text-center p-5">Save your seed words in a secure location of your choice. The Seed words will be used in the future for password reset. You must complete this form in order to use the platform.</div>
                    <div className='d-flex flex-stack mb-2'>
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Your seed words</label>
                        <br/>
                    </div>
                    <br></br>
                    <textarea className="h-100px border" style={{width: '100%'}}>{localStorage.getItem('mnemonic')}</textarea>
                </div>
          
            </div>
            {CheckBox("I have copied my seed words", setChecked)}
            <br/>
            {Button("Continue", redirect)}
        </React.Fragment>
    )
}