import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimesCircle, FaChevronLeft } from "react-icons/fa";
import { AuthService } from "@fyxgaming/lib/dist/auth-service";
import { SignedMessage } from "@fyxgaming/lib/dist/signed-message";
import { useHistory, useParams } from "react-router-dom";
import ProgressPopup from '../../ProgressPopup.js';
import { connect } from "react-redux";
import { Bip32, Constants, KeyPair, PrivKey } from 'bsv';
import Run from 'run-sdk';
import Dropdown from 'react-bootstrap-v5/lib/Dropdown'
import Select from 'react-select'
import CryptofightsItem from './CryptofightsItem'
import {Header, Breadcrumbs} from '../../helpers/components/Pageheader'
// import ProgressPopup from '../src/ProgressPopup.js';
import { trackPurchase, trackList, trackMelt, trackTransfer, trackUnlist  } from '../../AmplitudeTrack.js';
import { Forge } from 'txforge'
import { FyxOwner } from "@fyxgaming/lib/dist/fyx-owner";
import SuccessFeedback from '../../SuccessFeedback';
import Popup from '../../Popup.js';
import ImagePopup from '../../ImagePopup.js';
// import {ImagePopup} from '../src/Popup.js';
const ItemView = (props) => {
    Constants.Default = props.config.network === 'mainnet' ? Constants.Mainnet : Constants.Testnet;
    let as = ""
    let history = useHistory()
    if(props.config.network){
        as = new AuthService(process.env.REACT_APP_HOST_URL, props.config.network)
    }
    const [currencyHeading, setCurrencyHeading] = useState('BSV')
    let { itemLocation, collection } = useParams();
    const [item, setItem] = useState(null)
    const [meltPopupVisible, setMeltPopupVisible] = useState(false)
    const [sellPopupVisible, setSellPopupVisible] = useState(false)
    const [transferPopupVisible, setTransferPopupVisible] = useState(false)
    const [popupVisible, setPopupVisible] = useState(false)
    const [popupVisibleTwo, setPopupVisibleTwo] = useState(false)
    const [func, setFunc] = useState('')
    const [itemInView, setItemInView] = useState('')
    const [passingItem, setPassingItem] = useState()
    const [progressPopupFlag, setProgressPopupFlag] = useState(false)
    const [progressPopupString, setProgressPopupString] = useState([])
    const [itemRender, setItemRender] = useState()
    const [exchange, setExchange] = useState(1/100000000)
    const [currentItem, setCurrentItem] = useState([])
    const [selectedCollection, setSelectedCollection] = useState(collection)
    const [feedback, setFeedback] = useState(false)
    const [address, setAddress] = useState(null)
    //

    const [refresh, setRefresh] = useState(true)

    const [itemViewOpen, setItemViewOpen] = useState(false)

    const [usdTransferRate, setUsdTransferRate] = useState(0)
    let location = props.location
    useEffect(async ()=>{
        let transferRate = await axios.get(`https://api.whatsonchain.com/v1/bsv/main/exchangerate`)
        currencyHeading.toLowerCase() === 'usd' ? setExchange(1/100000000): setExchange(transferRate.data.rate/100000000)
        setUsdTransferRate(transferRate.data.rate)

    },[currencyHeading])
    useEffect(async ()=>{
        // jig/location
        if(!props.location) location = JSON.parse(localStorage.getItem('location'))
        const respAll = await axios.get(process.env.REACT_APP_HOST_URL+'/jigs/'+itemLocation) 
        const test = respAll.data
        console.log("ITEM SELECTED ++++++ ", test)
        await setItem(test)
        // navigateItem(test)
        
    },[props])
    useEffect(async () => {
        item && item.location && navigateItem(item)
    },[currencyHeading])
    const openPopup = (i, f) =>{
        setPopupVisible(true)
        setFunc(f)
        setPassingItem(i)
        
    }
    const closePopup = () =>{
        setPopupVisible(false)
        setPopupVisibleTwo(false)
        
        
    }
    const changeOwner = (fyxId) =>{
        const owner = new FyxOwner(process.env.REACT_APP_HOST_URL, props.bip32, fyxId, localStorage.getItem('userHandle'), props.keypair, process.env.REACT_APP_FEE_ADDRESS);
        props.run.owner = owner
    }
    const unlist = async () => {
        try{
            closePopup()
            setProgressPopupFlag(true)
            setProgressPopupString(["System ready for unlisting."])
            let one = passingItem
            if(selectedCollection == 'cryptofights') changeOwner('cryptofights')
            else changeOwner('nft')
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Initiating run."])
            props.run.activate()
            let tx =  new Run.Transaction()
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Creating a transaction."])
            const item = await props.run.load(one.location)
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Loading the item."])
            const address = await props.run.owner.nextOwner()
            tx.update(()=> item.send(address)); 
            let rawtx = await tx.export({pay: true, sign: true})
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Loading the raw transaction"])
            await props.blockchain.broadcast(rawtx).then(async resp => {
                setProgressPopupString(progressPopupString=>[...progressPopupString, "Item unlisting successful"])
                await new Promise((res, rej)=> {
                    setTimeout(()=>{
                        res()
                    }, 3000)
                })
                trackUnlist(one.owner.satoshis*usdTransferRate/100000000,one.owner.satoshis,one.origin)
                setProgressPopupFlag(false)
                setFeedback(true)
                history.push('/items')
            })
            .catch(async e => {
                setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with unlisting ${e}`])
                await new Promise((res, rej)=> {
                    setTimeout(()=>{
                        res()
                    }, 7000)
                })
                setProgressPopupFlag(false)
            })
        }
        catch(e){
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with unlisting ${e}`])
            await new Promise((res, rej)=> {
                setTimeout(()=>{
                    res()
                }, 7000)
            })
            setProgressPopupFlag(false)
        }
        
        

    }
    const buy = async () => {
        try{
        closePopup()
        setProgressPopupFlag(true)
        setProgressPopupString(["System ready for buying."])
        let one = passingItem
        if(selectedCollection == 'cryptofights') changeOwner('cryptofights')
        else changeOwner('nft')
        props.run.activate()
        setProgressPopupString(progressPopupString=>[...progressPopupString, "Initiating run."])
        let tx =  new Run.Transaction()
        setProgressPopupString(progressPopupString=>[...progressPopupString, "Creating a transaction."])
        tx.base = props.run.owner.getPurchaseBase(one.owner)
        setProgressPopupString(progressPopupString=>[...progressPopupString, "Loading owner."])
        const address = await props.run.owner.nextOwner()
        const item = await props.run.load(one.location)
        setProgressPopupString(progressPopupString=>[...progressPopupString, "Loading the item."])
        tx.update(()=> item.send(address)) 
        setProgressPopupString(progressPopupString=>[...progressPopupString, "Initiating payment to owner."])
        let rawtx = await tx.export({pay: true, sign: true})
        await props.blockchain.broadcast(rawtx).then(async resp => {
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Item purchase successful"])
            await new Promise((res, rej)=> {
                setTimeout(()=>{
                    res()
                }, 3000)
            })
            trackPurchase(one.owner.satoshis*usdTransferRate/100000000, one.owner.satoshis, one.origin)
            setProgressPopupFlag(false)
            setFeedback(true)
            history.push('/items')
        })
        .catch(async e => {
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with purchase ${e}`])
            await new Promise((res, rej)=> {
                setTimeout(()=>{
                    res()
                }, 10000)
            })
            setProgressPopupFlag(false)
        })
        }
        catch(e){
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with listing ${e}`])
            await new Promise((res, rej)=> {
                setTimeout(()=>{
                    res()
                }, 10000)
            })
            setProgressPopupFlag(false)
        }

    }
    const melt = async (one) => {
        //await 
        //loop over all

            try{
            closeMeltPopup()
            setProgressPopupFlag(true)
            setProgressPopupString(["System ready for melting."])
            changeOwner(collection)
            let jig = await props.run.load(one.location)
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Loading item`])
            jig.destroy()
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Destroying jig`])
            await jig.sync().then(async resp => {
                setProgressPopupString(progressPopupString=>[...progressPopupString, "Melting successful"])
                await new Promise((res, rej)=> {
                    setTimeout(()=>{
                        res()
                    }, 3000)
                })
                trackMelt(one.satoshis*usdTransferRate/100000000, one.satoshis, one.origin)
                setItemViewOpen(false)
                setProgressPopupFlag(false)
            })
            .catch(async e =>{
                setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with melting`])
                await new Promise((res, rej)=> {
                    setTimeout(()=>{
                        res()
                    }, 5000)
                })
                setProgressPopupFlag(false)
            })
            }
            catch(e){
                setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with melting ${e}`])
                await new Promise((res, rej)=> {
                    setTimeout(()=>{
                        res()
                    }, 5000)
                })
                setProgressPopupFlag(false)
            }
        setRefresh(!refresh)
        setFeedback(true)
        history.push('/items')
        setItemViewOpen(false)


    }
    const transfer = async (one, name) => {
        let kp = props.keypair
            try{
            closeTransferPopup()
            setProgressPopupFlag(true)
            setProgressPopupString(["System ready for transfer."])
            let fyxId = selectedCollection == 'cryptofights' ? 'cryptofights' : 'nft'
            let resp = await axios.post(process.env.REACT_APP_HOST_URL + '/accounts/' + fyxId + '/' + name + '/payment-destination', new SignedMessage({ subject: 'GetPaymentDestination' }, localStorage.getItem('userHandle'), kp))
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Loading payment destination.`])
            if(selectedCollection == 'cryptofights') changeOwner('cryptofights')
            else changeOwner('nft')
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Loading jig`])
            let jig = await props.run.load(one.location)
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Transfering Item`])
            jig.send(resp.data.address)
            await jig.sync().then(async resp =>{
                setProgressPopupString(progressPopupString=>[...progressPopupString, "Transfer successful"])
                await new Promise((res, rej)=> {
                    setTimeout(()=>{
                        res()
                    }, 3000)
                })
                trackTransfer(name, one.origin)
                setFeedback(true)
                setItemViewOpen(false)
                setProgressPopupFlag(false)
            })
            .catch(async e =>{
                setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with transfering`])
                await new Promise((res, rej)=> {
                    setTimeout(()=>{
                        res()
                    }, 5000)
                })
                setProgressPopupFlag(false)
            })
            }
            catch(e){
                setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with transfering ${e}`])
                await new Promise((res, rej)=> {
                    setTimeout(()=>{
                        res()
                    }, 5000)
                })
                setProgressPopupFlag(false)
            }
        setRefresh(!refresh)
        history.push('/items')
        setItemViewOpen(false)

    }
    const closeMeltPopup = () => {
        setMeltPopupVisible(false)

    }
    const closeSellPopup = () => {
        setSellPopupVisible(false)

    }
    const closeTransferPopup = () => {
        setTransferPopupVisible(false)

    }
    const list = async (one, price) => {
        try{
        closeSellPopup()
        setProgressPopupFlag(true)
        setProgressPopupString(["System ready for listing."])
        collection=="cryptofights" ? changeOwner(collection) : changeOwner('nft')
        setProgressPopupString(progressPopupString=>[...progressPopupString, "Initiating run."])
        let OrderLock = await props.run.load(location.OrderLock.location)
        let tx = new Run.Transaction()
        setProgressPopupString(progressPopupString=>[...progressPopupString, "Creating a transaction."])

        //what about location of this item
        const item = await props.run.load(one.location)
        setProgressPopupString(progressPopupString=>[...progressPopupString, "Loading the item."])

        tx.base = props.run.owner.getListingBase()
        tx.update(() => { item.send(new OrderLock(props.run.purse.address, price * 100000000)) })
        setProgressPopupString(progressPopupString=>[...progressPopupString, "Updating Item Status."])

        await tx.publish().then(async res => {
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Item listing successful"])
            await new Promise((res, rej)=> {
                setTimeout(()=>{
                    res()
                }, 3000)
            })
            trackList(price*usdTransferRate, price * 100000000, one.origin)
            setProgressPopupFlag(false)
            setFeedback(true)
            history.push('/marketplace')
        })
        .catch(async e => {
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with listing ${e}`])
            await new Promise((res, rej)=> {
                setTimeout(()=>{
                    res()
                }, 7000)
            })
            setProgressPopupFlag(false)
        })
        }
        catch(e){
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with listing ${e}`])
            await new Promise((res, rej)=> {
                setTimeout(()=>{
                    res()
                }, 7000)
            })
            setProgressPopupFlag(false)
        }
        // window.location.reload()
    }
    const MeltPopup = ({ visible, cancelPopup, item }) => {
        //add melting amount
        return (
            visible ? <div className="bg">
                <div className="customModal">

                    <div className="modalRow modalRow1">
                        MELT ITEMS?
                        <FaTimesCircle className="modalCancel" onClick={cancelPopup} />
                    </div>
                    <div className="modalRow modalRow2">
                        This action cannot be undone
                    </div>
                    <div className="modalRow modalRow3">
                        <button className="but modalBut" onClick={cancelPopup}>Cancel</button>
                        <button className="meltButton2 modalBut modalButC" onClick={() => melt(item)}>Melt Item(s)</button>
                    </div>
                </div>
            </div> : null

        )
    }
    const SellPopup = ({ visible, cancelPopup, item }) => {
        let price = ""
        return (
            visible ? <div className="bg">
                <div className="customModal">

                    <div className="modalRow modalRow1">
                        SELL ITEMS ON MARKETPLACE?
                        <FaTimesCircle className="modalCancel" onClick={cancelPopup} />
                    </div>
                    <div className="modalRow modalRow2" style={{ display: 'flex', flexDirection: 'column' }}>
                        This action cannot be undone. <br /> This listing will be public for 7 days on the marketplace.<br /> <br />
                        <div style={{display: 'flex', flexDirection: 'row', marginLeft: '25%'}}>
                            <Dropdown>
                                <Dropdown.Toggle style={{backgroundColor: 'white', color: '#4D00D5', border: 'none'}} className="ddNew" id="dropdown-basic">
                                    {currencyHeading}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onSelect={(a, e)=> setCurrencyHeading(e.target.innerHTML)}>USD</Dropdown.Item>
                                    <Dropdown.Item onSelect={(a, e)=> setCurrencyHeading(e.target.innerHTML)}>BSV</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <input style={currencyHeading == 'USD' ? { width: '150px', marginLeft: '5%', color: 'black' } : {display: 'none'}} type="text" className="inputHandle" onChange={(e) => { 
                                price = e.target.value 
                                document.getElementById('injectPrice').innerHTML = (price/usdTransferRate).toFixed(8)
                                // setTestPrice(e.target.value)
                                }} placeholder='Asking Price in USD'></input>
                            <input style={currencyHeading != 'USD' ? { width: '150px', marginLeft: '5%', color: 'black' } : {display: 'none'}} type="text" className="inputHandle" onChange={(e) => { price = e.target.value
                            // setTestPrice(e.target.value) 
                            document.getElementById('injectPrice').innerHTML = (price*usdTransferRate).toFixed(8)
                            }} placeholder='Asking Price in BSV'></input>
                        </div>
                        <br/>
                        <div>Price Conversion in <span id="injectPrice">0</span> {currencyHeading == 'BSV' ? "USD" : "BSV"}</div>
                    </div>
                    <div className="modalRow modalRow3">
                        <button className="but modalBut" onClick={cancelPopup}>Cancel</button>
                        {/* <button className="but modalBut" onClick={()=> console.log("Check transfer rate = ", price/usdTransferRate)}>Cancel</button> */}
                        <button className="meltButton2 modalBut modalButC" onClick={() => list(item ? item : null, currencyHeading=='BSV' ? price : (price/usdTransferRate).toFixed(8))}>Sell Item(s)</button>
                    </div>
                </div>
            </div> : null

        )
    }
    const TransferPopup = ({ visible, cancelPopup, item }) => {
        let name = ""
        return (
            visible ? <div className="bg">
                <div className="customModal">

                    <div className="modalRow modalRow1">
                        TRANSFER ITEMS?
                        <FaTimesCircle className="modalCancel" onClick={cancelPopup} />
                    </div>
                    <div className="modalRow modalRow2">
                        <input style={{ color: 'black' }} type="text" className="inputHandle" onChange={(e) => { name = e.target.value }} placeholder="Gamer Id"></input>
                    </div>
                    <div className="modalRow modalRow3">
                        <button className="but modalBut" onClick={cancelPopup}>Cancel</button>
                        <button className="meltButton2 modalBut modalButC" onClick={() => transfer(item ? item : null, name)}>Transfer Item(s)</button>
                    </div>
                </div>
            </div> : null

        )
    }
    const navigateItem = async (item) => {

        let i = item
        
        let address = props.run && await props.run.purse.address
        // console.log("Address ==== ", address)
        // console.log("Item in navigate item = ", i)
        let arr = ["Innocuous",
        "Brutal",
        "Dexterous",
        "Ambivalent",
        "Assault",
        "Precision",
        "Loading",
        "Swift",
        "Keen",
        "Light",
        "Heavy",
        "Versatile",
        "Strenuous"]
        let prop =""
        for(let val in i.item.properties){
            if(i.item.properties[val]==true) prop=prop+" • "+arr[val]
        }
        let bonuses = ["Strength",
            "Dexterity",
            "Intelligence",
            "Health",
            "Initiative",
            "Evasion",
            "CritChance",
            "BaseDamage",
            "Piercing",
            "Slashing",
            "Bludgeoning ",
            "Fire ",
            "Cold ",
            "Necrotic ",
            "Lightning ",
            "Poison ",
            "Mystic ",
            "Warfare ",
            "XP "]
        let bon = ""
        for(let val in i.item.bonuses){
            if (i.item.bonuses[val] == true){
                bon=bon+" • "+bonuses[val]
            }
        }
        let src = ""
        // let a = await props.run.purse.address
        let images = []
        images =item.metadata.attachments && item.metadata.attachments.slice().map((i)=>{
                let src = `https://dogefiles.twetch.app/${i.url.split('b://')[1]}`
                return (
                    <div onClick={()=>{
                        setPopupVisibleTwo(true)
                        setItemInView(src)
                        }}>
                        <object data={src} className="additionalFilesObj" width="100px" height="50px"/>

                    </div>)
        }).filter((i)=>i!=undefined)
        let quality = [ "Poor",
            "Common",
            "Uncommon",
            "Rare",
            "Epic",
            "Legendary",
            "Special"]
        const renderItem = (
            <div className="openItem">
                <div className="chevronBack" onClick={()=> history.goBack()}><FaChevronLeft></FaChevronLeft></div>
                <div className="openItemImage">
                    <div className="opemItemImageViewCont">
                        <img className="opemItemImageView" src={item.metadata.imageUrl ? `https://dogefiles.twetch.app/${item.metadata.imageUrl.split('b://')[1].split('/')[0]}` : ''}></img>
                        <div className="itemBoxDesc">{quality[parseInt(item.item.quality)]} item</div>

                    </div>

                    <div className="itemDescMob" style={item.metadata.author?{display: 'none'}:null}>
                        <div className="openItemImageHeader">ITEM PROPERTIES</div>
                        <div className="openItemProperties">
                            <div className="subHead">Category</div>
                            <div className="subHeadDesc" style={{color: 'grey'}}>Weapons</div>
                        </div>
                        {/* <div className="openItemProperties">
                            <div>Type</div>
                            <div style={{color: 'grey'}}>{src.split('.')[0]}</div>
                        </div> */}
                        <div className="openItemProperties">
                            <div className="subHead">Level Required</div>
                            <div className="subHeadDesc" style={{color: 'grey'}}>{i.item.levelRequired}</div>
                        </div>
                        <div className="openItemProperties">
                            <div className="subHead">Properties</div>
                            <div  className="subHeadDesc" style={{color: 'grey'}}>{prop}</div>
                        </div>
                    </div>
                    <div className="itemDescMob" style={item.metadata.author?null: {display: 'none'}}>
                        <div className="openItemImageHeader">ITEM PROPERTIES</div>
                        <div className="openItemProperties">
                            <div className="subHead">Name</div>
                            <div className="subHeadDesc">{item.metadata.name}</div>
                        </div>
                        <div className="openItemProperties">
                            <div className="subHead">Description</div>
                            <div className="subHeadDesc">{item.metadata.description}</div>
                        </div>
                        <div className="openItemProperties">
                            <div className="subHead">Attachments:</div>
                            
                        </div>
                        <div className="openItemProperties customMobAttachments">
                            {images && images.length>0 ?  images: "No attachments"}
                            
                        </div>
                    </div>
                </div>
                <div className="openImageDesc">
                    <div className="itemName">{i&&i.item.displayName}</div>
                    <br></br>
                    <div className="itemDescription">{i.item.description}</div>
                    <div className="nam">{i.displayName}</div>
                    {!i.metadata.author && <div style={{marginBottom: '5%'}}>
                        <div className="itemBonuses">
                            BONUSES: 
                            {bon!=="" ? <div style={{color: 'black', marginLeft: '1%'}}>{bon}</div> :
                            <div style={{color: 'black'}}> This item has no bonuses</div>}
                        </div>
                        <div className="itemBonuses">
                            WIELD: 
                            {i.item.equippableSlots.length ==1 ? <div style={{color: 'black', marginLeft: '1%'}}>Dual Wielding</div> :
                            <div style={{color: 'black', marginLeft: '1%'}}> Single Handed</div>}
                        </div>
                        <div className="itemBonuses">
                            QUALITY : 
                            <div style={{color: 'black', marginLeft: '1%'}}>{quality[i.item.quality]}</div> 

                        </div>
                        {/* <div className="itemBonuses">
                            RATING : 
                            <div style={{color: 'black', marginLeft: '1%'}}>{i.item.rating}</div> 
                        </div> */}
                    </div>}
                    
                    <div style={{display:'flex', flexDirection: 'row'}}>

                        <div style={{marginRight: '5%'}}>MELT VALUE:<br/> <span className="ksat">{currencyHeading == 'BSV' ? (item.satoshis*exchange).toFixed(8): (item.satoshis*exchange).toFixed(4)} {currencyHeading}</span></div>
                        <div style={item.ownerKind!=location.OrderLock.location ? {display: "none"}: null}>LISTED FOR:<br/> <span className="ksat">{currencyHeading == 'BSV' ?(item.owner.satoshis*exchange).toFixed(8) :  (item.owner.satoshis*exchange).toFixed(2)} {currencyHeading}</span></div>
                    </div>
                    <div className="ides">{i.description}</div>
                    {localStorage.getItem('privKeyString') ? 
                    item.ownerKind !== "address" ?
                    <div>
                        {(item.owner.address)!== address? <div className="butY" onClick={() => openPopup(item, 'buy')}>Buy Item</div> : <div className="butY" onClick={()=> openPopup(item, 'unlist')}>Unlist Item</div>
}
                    </div>
                    :
                    <div>
                        {typeof (item.owner)!=="string"? 
                        <div className="butY" onClick={()=> openPopup(item, 'unlist')}>Unlist Item</div>
                        :
                        <div>
                                <div className="butY" onClick={() => setMeltPopupVisible(true)}>Melt Items for BSV</div>
                                <div className="butY" onClick={() => setTransferPopupVisible(true)}>Transfer Items</div>
                                <div className="butY" onClick={() => setSellPopupVisible(true)}>Sell Item</div>
                        </div>
                        }
                    </div>
                    
                    :
                    <div>
                        <span style={{marginLeft: '25%', paddingBottom: '10px'}}>Log in to purchase</span>
                        <div className="butY" onClick={() => history.push('/')}>Login</div>
                    </div> 
                    }

                </div>
            </div>
        )
        setItemRender(renderItem)
        setCurrentItem(item.metadata.name)
        
    }
    return ( 
        <React.Fragment>
            <MeltPopup visible={meltPopupVisible} cancelPopup={closeMeltPopup} item={item} />
            <SellPopup visible={sellPopupVisible} cancelPopup={closeSellPopup} item={item} />
            <TransferPopup visible={transferPopupVisible} cancelPopup={closeTransferPopup} item={item} oneItem={true} />
            <ProgressPopup visible={progressPopupFlag} stringArr={progressPopupString} title="Creating Your Transaction"></ProgressPopup>
            <SuccessFeedback visible={feedback}></SuccessFeedback>
            <Popup visible={popupVisible} cancelPopup={closePopup} onCreate = {func == 'buy' ? buy : unlist}/>
            <ImagePopup visible={popupVisibleTwo} cancelPopup={closePopup} item={itemInView}/>
            {/* <TopNav/> */}
            {/* <div className="fullItemView">

            </div> */}
            {item && <div className="wallet">
                {Header("NFT Details")}
                {Breadcrumbs(["marketplace"])}
                <CryptofightsItem item={item} collection={collection} rate={usdTransferRate}/>
            </div>}
        </React.Fragment>
    )
}
const mapStateToProps = state => {
    return ({
        keypair: state.Blockchain.keypair,
        run: state.Blockchain.run,
        blockchain: state.Blockchain.blockchain,
        bip32: state.Blockchain.bip32
    });
}

export default connect(mapStateToProps)(ItemView);
