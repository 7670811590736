import {
    ADD_BIP,
    ADD_BLOCKCHAIN,
    ADD_KEYPAIR,
    ADD_RUN
} from "./constants";

const INIT_STATE = {
    keypair: '',
    run: '',
    blockchain: '',
    bip32: ''
};

const Blockchain = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_KEYPAIR:
            return {
                ...state,
                keypair: action.payload
            };
        case ADD_RUN:
            return {
                ...state,
                run: action.payload
            };
        case ADD_BLOCKCHAIN:
            return {
                ...state,
                blockchain: action.payload
            };
        case ADD_BIP:
            return {
                ...state,
                bip32: action.payload,
            };
        default:
            return state;
    }
};

export default Blockchain;