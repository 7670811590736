import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from "react-redux";
import {MarketplaceService} from '../service/MarketplaceService';
import ItemsCard from './ItemsCard'
import ProgressPopup from '../../ProgressPopup'
import '../../wallet/css/wallet.css'
import {Button} from '../../helpers/components/Button';
import PaginationRounded from '../../ComponentTester';
import {showModalMsg} from "../../redux/messages/actions";
import {YES_NO} from "../../messages/constants/buttonTypes";
import {ModalMessage} from "../../messages/ModalMessage";
import { useHistory } from 'react-router';
import axios from 'axios'
function ItemsGrid(props) {
    let history = useHistory()
    const dispatch = useDispatch();
    const [itemsList, setItemsList] = useState(null)
    const [popup, setPopup] = useState(false)
    const [action, setAction] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [select, setSelect] = useState([])
    const [progressPopupString, setProgressPopupString] = useState([])
    let totalSteps = {
        buy: 7
    }
    let config = process.env.REACT_APP_NETWORK === 'mainnet' ? {
        network: 'mainnet',
      } : 
      {
        network: 'testnet',
      }
    let ms = new MarketplaceService(props, config)
    useEffect(async ()=>{
        let response = []
        console.log("props in tems grid = ", props)
        if(props.collection.value.toLowerCase() == 'zenescope' && props.page == 'marketplace' && !props.searchQuery){
            let df = await ms.fetchAllMarketplaceItems(pageNumber, props.collection, 'deathforce', props.filters, props.page, 20)
            let my = await ms.fetchAllMarketplaceItems(pageNumber, props.collection, 'mystere', props.filters, props.page, 20)
            let ma = await ms.fetchAllMarketplaceItems(pageNumber, props.collection, 'masumi', props.filters, props.page, 20)
            let done = await ms.fetchAllMarketplaceItems(pageNumber, props.collection, 'dark', props.filters, props.page, 20)
            console.log("d one  = ", done)
            let ts = Date.now()
            if(ts<1640581200000) ma=[]
            console.log("masumi = ", ma)
            console.log("curr ts = ", ts)
            response = [...done, ...[...ma, ...[...my, ...df]]]
        }
        else {
            response = props.tab == 'all' ? await ms.fetchAllMarketplaceItems(pageNumber, props.collection, props.searchQuery, props.filters, props.page) : props.tab=="inventory"? await ms.fetchAllMarketplaceItems(pageNumber, props.collection, props.searchQuery, props.filters, 'myitems') : await ms.fetchMyMarketplaceItems(props.collection)
        }
        console.log("marketplace items == ", response)
        setItemsList(response)
    },[props, popup, pageNumber])
    useEffect(()=>{}, [select])
    const selector = async (e)=>{
        console.log("CHECK  selected location in checkobox = ", e.target.value, e.target.checked)
        if(e.target.checked){
            await setSelect([...select, e.target.value])
        }
        else{
            let temp = []
            for(let item of select){
                if(item != e.target.value){
                    temp.push(item)
                }
            }
            await setSelect(temp)
        }
        console.log("After step selector = ", select)
        // var clist=document.getElementsByClassName("melt-checkbox");
        // for (var i = 0; i < clist.length; ++i) { 
        //     if(clist[i].checked == "checked"){
        //         console.log("selected location in checkobox = ", clist[i].value)
        //     } 
        // }
    }
    function clearFilters(){
        var clist=document.getElementsByClassName("melt-checkbox");
        for (var i = 0; i < clist.length; ++i) { 
            clist[i].checked = false
        }
        setSelect([])
    }
    const buy = async (item) =>{
        try{
            console.log("attempting buy = ", item)
            let temp = JSON.parse(item)
            let coll = props.collection.fyxId
            try{
                await ms.buy(temp, setPopup, setProgressPopupString, coll)
            }
            catch(e){
                console.log("error in purchase")
            }
            history.push('/myitems')
        }
        catch(e){
            console.log("Error with transaction = ", e)
        }
    }
    const melt = async (item) =>{
        try{
            console.log("attempting melt = ", ms)
            let coll = props.collection.fyxId
            await ms.melt(item, setPopup, setProgressPopupString, coll)
        }
        catch(e){
            console.log("Error with transaction = ", e)
        }
    }
    const meltMultiple = async () =>{
        try{
            
            let coll = props.collection.fyxId
            setPopup(true)
            console.log("attempting melt 1= ", select)
            for(let index in select) {
                let item = select[index]
                console.log("attempting melt = ", item)
                function setPP(str){
                    setProgressPopupString(`Melting Item No ${index+1}`)
                }
                await ms.melt(item, ()=>{}, setPP, coll)
            }
            setPopup(false)
            clearFilters()
        }
        catch(e){
            console.log("Error with transaction = ", e)
        }
    }
    const listAll = async () =>{
        try{
            let coll = props.collection.fyxId
            setPopup(true)
            console.log("attempting list 1= ", itemsList, props.collection.fyxId)
            let price = await axios.get(`https://api.whatsonchain.com/v1/bsv/main/exchangerate`)
            for(let index in itemsList.slice(0,19)) {
                let item = itemsList[index].location
                console.log("attempting list check= ", index, item)
                function setPP(str){
                    setProgressPopupString(`listing Item No ${index+1}`)
                }
                let bsv = 125.0/price.data.rate
                console.log('bsv = ', price.data.rate)
                await ms.list({location: item}, ()=>{}, setPP, coll, 0.999)
            }
            setPopup(false)
            clearFilters()
        }
        catch(e){
            console.log("Error with transaction = ", e)
        }
    }
    const meltAll = async () =>{
        try{
            let coll = props.collection.fyxId
            setPopup(true)
            console.log("attempting melt 1= ", itemsList)
            for(let index in itemsList) {
                let item = itemsList[index].location
                console.log("attempting melt = ", item)
                function setPP(str){
                    setProgressPopupString(`Melting Item No ${index+1}`)
                }
                await ms.melt(item, ()=>{}, setPP, coll)
            }
            setPopup(false)
            clearFilters()
        }
        catch(e){
            console.log("Error with transaction = ", e)
        }
    }
    return(
        itemsList &&
        <div>
            <ProgressPopup visible={popup} stringArr={progressPopupString} title={action && action == 'melt' ? "Melting Item": "Buying Item"} total={totalSteps.buy}></ProgressPopup>
            {/* <MeltPopup visible={meltPopupVisible} cancelPopup={closeMeltPopup} /> */}
            {
            action && action == 'melt' ? 
                <ModalMessage
                    title=''
                    content='Confirm melting of the item?'
                    buttonType={YES_NO}
                    confirmedCallback={melt}/>
                :
                <ModalMessage
                    title=''
                    content='Confirm Purchase of the item?'
                    buttonType={YES_NO}
                    confirmedCallback={buy}/>
            }
            {select.length > 0 &&
            <div className="d-flex flex-row justify-content-end mt-5">
                {Button('Melt Selected', meltMultiple, 'rgb(82, 0, 229)')}
                &nbsp;&nbsp;
                {/* {Button('List All', listAll, 'rgb(82, 0, 229)')} */}
                {/* &nbsp;&nbsp; */}
                {Button('Melt All', meltAll, 'rgb(82, 0, 229)')}
                &nbsp;&nbsp;
                {Button('Clear Selected', clearFilters, null)}
            </div>}
            <div className="d-flex flex-row flex-wrap mt-10 justify-content-center justify-content-md-start">
                {itemsList?.map(i => {
                    return <ItemsCard
                        item={i}
                        collection={props.collection}
                        callback={(item) => {
                            console.log("in melt callbackkkkkk===== ", item)
                            setAction('buy')
                            dispatch(showModalMsg(JSON.stringify(item)))}}
                        melt={(item) => {
                            setAction('melt')
                            dispatch(showModalMsg(item.location))
                        }}
                        page={props.page}
                        activeTab={props.tab}
                        props={props}
                        selector={selector}
                    />
                })}

            </div>
            <PaginationRounded page={pageNumber} pageCount={5} onChange={setPageNumber}/>
            <br/>

        </div>
    )
}

const mapStateToProps = state => {
    return ({
        keypair: state.Blockchain.keypair,
        run: state.Blockchain.run,
        bip32: state.Blockchain.bip32,
        blockchain: state.Blockchain.blockchain
    });
}
export default connect(mapStateToProps)(ItemsGrid)