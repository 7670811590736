import { AuthService } from "@fyxgaming/lib/dist/auth-service";
import initialize from '../../RunInitialize';
import { SignedMessage } from "@fyxgaming/lib/dist/signed-message";
import axios from 'axios';
import { FyxOwner } from "@fyxgaming/lib/dist/fyx-owner";
import Run from 'run-sdk';
import { Forge } from 'txforge'
import { PaymailClient } from '@moneybutton/paymail-client'
import  { KeyPair, Constants, PrivKey, Script, Address, PubKey } from 'bsv';
import queryString from 'query-string';
import {trackMelt, trackTransfer, trackList, trackUnlist, trackPurchase} from '../../AmplitudeTrack.js'
const crypto = require("crypto");

export class MarketplaceService{
    constructor(props, config){
        this.props = props
        this.location = JSON.parse(localStorage.getItem('location'))
    }
    async changeOwner(fyxId){
        console.log("ALL props in change owner == ", this.props)
        const owner = new FyxOwner(process.env.REACT_APP_HOST_URL, this.props.bip32, fyxId, localStorage.getItem('userHandle'), this.props.keypair, process.env.REACT_APP_FEE_ADDRESS);
        this.props.run.owner = owner
    }
    async fetchJig(location){
        const data = await axios(`${process.env.REACT_APP_HOST_URL}/jigs/${location}`);
        return data.data
    }
    async fetchAllMarketplaceItems(pageNumber, collection, search, filters, page, limit){
        try{
            let cfRequest = {
                offset: (pageNumber-1)*40,
                limit: limit || 40
                }
            let nftRequest = {
                mintOrigin: collection.location,
                limit: limit || 40
                }
            if(collection.fyxId == 'cryptofights' && collection.location != 'cryptofights' ){
                cfRequest.mintOrigin = collection.location
            }
            if(collection.fyxId == 'cryptofights' && collection.location == 'cryptofights' ){
                cfRequest.fromBattle = true
            }
            if(search && search.length>0){
                cfRequest.search=search
                nftRequest.search = search
            }
            if(filters){
                cfRequest.item = filters
            }
            let res = []
            if(page == 'marketplace'){
                console.log("DEBUGGGGGG QQUERRRYYYYYY ++++++ ", cfRequest, collection)
                res = collection.fyxId == 'cryptofights' ? await axios.post(`${process.env.REACT_APP_HOST_URL}/jigs/marketplace/cryptofights`, cfRequest)
                :
                await axios.post(`${process.env.REACT_APP_HOST_URL}/jigs/marketplace/nft`, nftRequest)
            }
            else{
                console.log("DEBUGGGGGG QQUERRRYYYYYY ++++++ ", cfRequest,nftRequest, collection)
                res = collection.fyxId == 'cryptofights' ? await axios.post(`${process.env.REACT_APP_HOST_URL}/jigs/myitems/cryptofights/${localStorage.getItem('userHandle')}`, cfRequest)
                :
                await axios.post(`${process.env.REACT_APP_HOST_URL}/jigs/myitems/nft/${localStorage.getItem('userHandle')}`, nftRequest)

            }
            const items = await Promise.all(res.data.map(async ({location}) => {
                const {data: item} = await axios(`${process.env.REACT_APP_HOST_URL}/jigs/${location}`);
                return item;
            }));
            return items
        }
        catch(e){
            return({error: e})
        }
    }
    async fetchMyMarketplaceItems(collection){
        try{
            let res 
            if(collection.location == 'cryptofights' ){
                res = await axios.post(`${process.env.REACT_APP_HOST_URL}/jigs/myitems/cryptofights/${localStorage.getItem('userHandle')}`,{ listed : true, fromBattle : true })
            }
            else{
                if(collection.fyxId == 'cryptofights'){
                    res = await axios.post(`${process.env.REACT_APP_HOST_URL}/jigs/myitems/cryptofights/${localStorage.getItem('userHandle')}`,{ listed : true, mintOrigin : collection.location })
                }
                else{
                    res = await axios.post(`${process.env.REACT_APP_HOST_URL}/jigs/myitems/nft/${localStorage.getItem('userHandle')}`,{ listed : true, mintOrigin : collection.location })
                }
            }
            // let res = collection == 'cryptofights' ? await axios.post(`${process.env.REACT_APP_HOST_URL}/jigs/myitems/cryptofights/${localStorage.getItem('userHandle')}`,{ listed : true })
            //             :
            //             await axios.post(`${process.env.REACT_APP_HOST_URL}/jigs/myitems/nft/${localStorage.getItem('userHandle')}`,{ listed : true, mintOrigin : collection })
            const items = await Promise.all(res.data.map(async ({location}) => {
                const {data: item} = await axios(`${process.env.REACT_APP_HOST_URL}/jigs/${location}`);
                return item;
            }));
            return items
        }
        catch(e){
            return({error: e})
        }
    }
    async fetchMarketplaceCollections(page, tab){
        try{
            let url = ''
            if(page == 'marketplace'){
                url = `${process.env.REACT_APP_HOST_URL}/jigs/marketplace/collections`
            }
            else{
                if(tab='all'){
                    url = `${process.env.REACT_APP_HOST_URL}/jigs/myitems/collections/${localStorage.getItem('userHandle')}`
                }
                else{
                    url = `${process.env.REACT_APP_HOST_URL}/jigs/marketplace/collections/${localStorage.getItem('userHandle')}`
                }
            }
            let coll = await axios.get(url)
            console.log(" FETCHING COLLECTIONS SERVICE ++++++ ", coll, page, tab)
            coll = await Promise.all(coll.data.map(async ({location, fyx_id}) => {
                const {data: item} = await axios(`${process.env.REACT_APP_HOST_URL}/jigs/${location}`);
                return {...item, ...{fyxId: fyx_id}};
            }));
            console.log(" CHECK ++++++ ", coll)
            return coll
        }
        catch(e){
            return({error: e})
        }
    }
    async timeout(sec){
        return(
            await new Promise((res, rej)=> {
                setTimeout(()=>{
                    res()
                }, (sec*1000))
            })
        )
    }
    async signRawtx(rawtx){
        const t = await this.props.run.import(rawtx);
        const signedTx = await t.export({pay: true, sign: true})
        return signedTx
    }
    async buy(one, setProgressPopupFlag, setProgressPopupString, selectedCollection){
        try{
            console.log("testing BUY MService", one)
            setProgressPopupFlag(true)
            setProgressPopupString(["System ready for buying."])
            let balance = await this.props.run.purse.balance()
            console.log("CHECK THESE NEW ENTRIESSSSS==== ", one.owner.satoshis, balance)
            if(one.owner.satoshis*1.05>balance) throw new Error("You do not have enough funds to complete the purchase. Please deposit some funds by visiting https://portal.fyxgaming.com/wallet")
            await this.timeout(1)
            if(selectedCollection == 'cryptofights') this.changeOwner('cryptofights')
            else this.changeOwner('nft')
            this.props.run.activate()
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Initiating run."])
            await this.timeout(1)
            let tx =  new Run.Transaction()
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Creating a transaction."])
            await this.timeout(1)
            this.props.run.owner.feeRate = 0.04
            tx.base = this.props.run.owner.getPurchaseBase(one.owner)
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Loading owner."])
            await this.timeout(1)
            const address = await this.props.run.owner.nextOwner()
            const item = await this.props.run.load(one.location)
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Loading the item."])
            await this.timeout(1)
            tx.update(()=> item.send(address)) 
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Initiating payment to owner."])
            await this.timeout(3)
            let rawtx = await tx.export({pay: true, sign: true})
            await this.props.blockchain.broadcast(rawtx)
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Item purchase successful"])
            await this.timeout(3)
            const name = one.metadata.name ? one.metadata.name: one.item.displayName
            trackPurchase(one.owner.satoshis*localStorage.getItem('usdTR')/100000000, one.owner.satoshis, one.origin, one, name)
            setProgressPopupFlag(false)
            // history.push('/myitems')

        }
        catch(e){
            console.log("in catch 100--00-----", e.message)
            let msg = e.message.toLowerCase().includes('insufficient utxos') ? "You do not have enough funds to complete the purchase. Please deposit some funds by visiting https://portal.fyxgaming.com/wallet" : e.message
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with purchase ${msg}`])
            await new Promise((res, rej)=> {
                setTimeout(()=>{
                    res()
                }, 20000)
            })
            // alert("REACHED CATCH BLOCK")
            setProgressPopupFlag(false)
        }

    }
    async buyNew(one, setProgressPopupFlag, setProgressPopupString, selectedCollection){
        try{
            console.log("testing BUY MService", one)
            let balance = await this.props.run.purse.balance()
            if(one.owner.satoshis*1.05>balance) throw new Error("You do not have enough funds to complete the purchase. Please deposit some funds by visiting https://portal.fyxgaming.com/wallet")
            let rawtx = await axios.post(`${process.env.REACT_APP_HOST_URL}/market/purchase/${one.location}`, new SignedMessage(
                { subject: 'PurchaseItem' }, 
                localStorage.getItem('userHandle'), 
                this.props.keypair))
            const signedTx = await this.signRawtx(rawtx)
            await axios.post(`${process.env.REACT_APP_HOST_URL}/market/purchase/${one.location}/broadcast`,{signedTx: signedTx})
            // const name = one.metadata.name ? one.metadata.name: one.item.displayName
            // trackPurchase(one.owner.satoshis*localStorage.getItem('usdTR')/100000000, one.owner.satoshis, one.origin, one, name)
            // setProgressPopupFlag(false)

        }
        catch(e){
            console.log("in catch 100--00-----", e.message)
            let msg = e.message.toLowerCase().includes('insufficient utxos') ? "You do not have enough funds to complete the purchase. Please deposit some funds by visiting https://portal.fyxgaming.com/wallet" : e.message
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with purchase ${msg}`])
            await new Promise((res, rej)=> {
                setTimeout(()=>{
                    res()
                }, 20000)
            })
            setProgressPopupFlag(false)
        }
    }
    async unlist(one, setProgressPopupFlag, setProgressPopupString, selectedCollection){
        try{
            console.log("UNLISTING ONE ===== ", one)
            setProgressPopupFlag(true)
            setProgressPopupString(["System ready for unlisting."])
            await this.timeout(1)
            if(selectedCollection == 'cryptofights') this.changeOwner('cryptofights')
            else this.changeOwner('nft')
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Initiating run."])
            await this.timeout(1)
            this.props.run.activate()
            let tx =  new Run.Transaction()
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Creating a transaction."])
            await this.timeout(1)
            const item = await this.props.run.load(one.location)
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Loading the item."])
            await this.timeout(1)
            const address = await this.props.run.owner.nextOwner()
            tx.update(()=> item.send(address)); 
            let rawtx = await tx.export({pay: true, sign: true})
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Loading the raw transaction"])
            await this.timeout(1)
            await this.props.blockchain.broadcast(rawtx).then(async resp => {
                setProgressPopupString(progressPopupString=>[...progressPopupString, "Item unlisting successful"])
                await this.timeout(3)
                trackUnlist(one.owner.satoshis*localStorage.getItem('usdTR')/100000000,one.owner.satoshis,one.origin)
                setProgressPopupFlag(false)
                // history.push('/items')
            })
            .catch(async e => {
                setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with unlisting ${e}`])
                await new Promise((res, rej)=> {
                    setTimeout(()=>{
                        res()
                    }, 7000)
                })
                setProgressPopupFlag(false)
            })
        }
        catch(e){
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with unlisting ${e}`])
            await new Promise((res, rej)=> {
                setTimeout(()=>{
                    res()
                }, 7000)
            })
            setProgressPopupFlag(false)
        }
    }
    async unlistNew(one, setProgressPopupFlag, setProgressPopupString, selectedCollection){
        try{
            let rawtx = await axios.post(`${process.env.REACT_APP_HOST_URL}/market/unlist/${one.location}`, new SignedMessage(
                { subject: 'UnlistItem' }, 
                localStorage.getItem('userHandle'), 
                this.props.keypair))
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Item unlisting successful"])
            // await this.timeout(3)
            // trackUnlist(one.owner.satoshis*localStorage.getItem('usdTR')/100000000,one.owner.satoshis,one.origin)
            setProgressPopupFlag(false)
    
        }
        catch(e){
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with unlisting ${e}`])
            await new Promise((res, rej)=> {
                setTimeout(()=>{
                    res()
                }, 7000)
            })
            setProgressPopupFlag(false)
        }
    }
    async list(one, setProgressPopupFlag, setProgressPopupString, selectedCollection, price){
        try{
            setProgressPopupFlag(true)
            setProgressPopupString(["System ready for listing."])
            this.timeout(1)
            selectedCollection=="cryptofights" ? this.changeOwner(selectedCollection) : this.changeOwner('nft')
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Initiating run."])
            this.timeout(1)
            let OrderLock = await this.props.run.load(this.location.OrderLock.location)
            let tx = new Run.Transaction()
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Creating a transaction."])
            this.timeout(1)
            console.log('mid way listing ')
            //what about location of this item
            const item = await this.props.run.load(one.location)
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Loading the item."])
            this.timeout(1)
    
            tx.base = this.props.run.owner.getListingBase()
            let sat = parseInt(price*100000000)
            //94820000
            tx.update(() => { item.send(new OrderLock(this.props.run.purse.address, sat)) })
            console.log('price way listing ')
            setProgressPopupString(progressPopupString=>[...progressPopupString, "Updating Item Status."])
            this.timeout(1)
            console.log('publish way listing ')
            await tx.publish().then(async res => {
                setProgressPopupString(progressPopupString=>[...progressPopupString, "Item listing successful"])
                this.timeout(3)
                trackList(price*localStorage.getItem('usdTR'), sat, one.origin)
                setProgressPopupFlag(false)
            })
            .catch(async e => {
                setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with listing ${e}`])
                this.timeout(7)
                setProgressPopupFlag(false)
            })
            }
            catch(e){
                setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with listing ${e}`])
                this.timeout(7)
                setProgressPopupFlag(false)
            }
    }
    async listNew(one, setProgressPopupFlag, setProgressPopupString, selectedCollection, price){
        try{
            let sat = parseInt(price*100000000)
            let rawtx = await axios.post(`${process.env.REACT_APP_HOST_URL}/market/list/${one.location}`, new SignedMessage(
                { 
                    subject: 'ListItem',
                    payload: { satoshis: sat}
                }, 
                localStorage.getItem('userHandle'), 
                this.props.keypair))
            const signedTx = await this.signRawtx(rawtx)
            await axios.post(`${process.env.REACT_APP_HOST_URL}/market/list/${one.location}/broadcast`,{signedTx: signedTx})
            // setProgressPopupString(progressPopupString=>[...progressPopupString, "Item listing successful"])
            // this.timeout(3)
            // trackList(price*localStorage.getItem('usdTR'), sat, one.origin)
            // setProgressPopupFlag(false)

        }
        catch(e){
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with listing ${e}`])
            this.timeout(7)
            setProgressPopupFlag(false)
        }
    }
    async melt(location, setProgressPopupFlag, setProgressPopupString, selectedCollection){
        try{
            setProgressPopupFlag(true)
            setProgressPopupString(["System ready for melting."])
            this.timeout(0.5)
            this.changeOwner('cryptofights')
            let jig = await this.props.run.load(location)
            
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Loading item`])
            this.timeout(0.5)
            jig.destroy()
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Destroying jig`])
            this.timeout(0.5)
            await jig.sync().then(async resp => {
                setProgressPopupString(progressPopupString=>[...progressPopupString, "Melting successful"])
                let item = await this.fetchJig(location)
                trackMelt(item.satoshis*localStorage.getItem('usdTR')/100000000, item.satoshis, item.origin)
                this.timeout(1)
                setProgressPopupFlag(false)
            })
            .catch(async e =>{
                setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with melting`])
                this.timeout(5)
                setProgressPopupFlag(false)
            })
            }
            catch(e){
                setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with melting ${e}`])
                this.timeout(5)
                setProgressPopupFlag(false)
            }
    }
    async transfer(one, setProgressPopupFlag, setProgressPopupString, selectedCollection, name){
        try{
            let kp = this.props.keypair
            setProgressPopupFlag(true)
            setProgressPopupString(["System ready for transfer."])
            this.timeout(1)
            let fyxId = selectedCollection == 'cryptofights' ? 'cryptofights' : 'nft'
            let resp = await axios.post(process.env.REACT_APP_HOST_URL + '/accounts/' + fyxId + '/' + name + '/payment-destination', new SignedMessage({ subject: 'GetPaymentDestination' }, localStorage.getItem('userHandle'), kp))
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Loading payment destination.`])
            this.timeout(5)
            if(selectedCollection == 'cryptofights') this.changeOwner('cryptofights')
            else this.changeOwner('nft')
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Loading jig`])
            this.timeout(5)
            let jig = await this.props.run.load(one.location)
            setProgressPopupString(progressPopupString=>[...progressPopupString, `Transfering Item`])
            this.timeout(5)
            jig.send(resp.data.address)
            await jig.sync().then(async resp =>{
                setProgressPopupString(progressPopupString=>[...progressPopupString, "Transfer successful"])
                this.timeout(5)
                trackTransfer(name, one.origin)
                setProgressPopupFlag(false)
            })
            .catch(async e =>{
                setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with transfering`])
                this.timeout(5)
                setProgressPopupFlag(false)
            })
            }
            catch(e){
                setProgressPopupString(progressPopupString=>[...progressPopupString, `Error with transfering ${e}`])
                await new Promise((res, rej)=> {
                    setTimeout(()=>{
                        res()
                    }, 5000)
                })
                setProgressPopupFlag(false)
            }
    }
}