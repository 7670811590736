import React, { useEffect, useState } from 'react'
import {useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import { FaExpand, FaUnlink } from 'react-icons/fa'
import { MarketplaceService } from '../service/MarketplaceService';
import {Button} from '../../helpers/components/Button'
import ProgressPopup from '../../ProgressPopup'
import axios from 'axios'
import {SellPopup, TransferPopup} from './SellPopup'
function CryptofightsItem(props){
    let history = useHistory()
    let config = process.env.REACT_APP_NETWORK === 'mainnet' ? {
        network: 'mainnet',
      } : 
      {
        network: 'testnet',
      }
    let ms = new MarketplaceService(props, config)
    const [item, setItem] = useState(null)
    const [collection, setCollection] = useState(null)
    const [address, setAddress] = useState(null)
    const [popup, setPopup] = useState(false)
    const [sellPopupVisible, setSellPopupVisible] = useState(false)
    const [transferPopupVisible, setTransferPopupVisible] = useState(false)
    const [transactionType, setTransactionType] = useState(false)
    const [selectedAttachment, setSelectedAttachment] = useState(null)
    const [selectedAttachmentType, setSelectedAttachmentType] = useState(null)
    const [progressPopupString, setProgressPopupString] = useState([])
    let totalSteps = {
        "Buying Item": 7,
        "Unlisting Item": 6,
        "Listing Item": 6,
        "Melting Item": 4,
        "Transfer Item": 5
    }
    let quality = [ "Poor",
    "Common",
    "Uncommon",
    "Rare",
    "Epic",
    "Legendary",
    "Special"]
    let properties = ["Innocuous",
    "Brutal",
    "Dexterous",
    "Ambivalent",
    "Assault",
    "Precision",
    "Loading",
    "Swift",
    "Keen",
    "Light",
    "Heavy",
    "Versatile",
    "Strenuous"]
    let type=["Axes", "Bows", "Clubs", "Daggers", "Great Swords", "Maces", "Pikes", "Staffs", "Swords", "Mauls"]
    useEffect(async ()=>{
        setItem(props.item)

        console.log(" props in item grid= ", props)
        console.log("Items in props = ", props)
        let add = props.run && await props.run.purse.address
        setAddress(add)
        
        let coll = await axios.get(`${process.env.REACT_APP_HOST_URL}/jigs/${props.item.mint.origin}`)
        if(coll.data.metadata) setCollection(coll.data)
        console.log("checking item mint origin = ", coll.data)

    },[props])
    useEffect(()=>{}, [progressPopupString, selectedAttachmentType])
    function getProperties(arr){
        let stck = []
        for(let index in arr){
            if(arr[index]){
                stck.push(
                    <span>{properties[index]} / </span>
                )
            }
        }
        return stck
    }
    const buy = async () =>{
        try{
            setTransactionType("Buying Item")
            console.log("attempting buy = ", ms)
            let coll = props.collection == 'cryptofights' ? 'cryptofights' : 'nft'
            try{
                await ms.buy(item, setPopup, setProgressPopupString, coll)
            }
            catch(e){
                console.log("error in purchase")
            }
            history.push('/myitems')
        }
        catch(e){
            console.log("Error with transaction = ", e)
        }
    }
    const unlist = async () =>{
        try{
            setTransactionType("Unlisting Item")
            console.log("attempting unlist = ", ms)
            let coll = props.collection == 'cryptofights' ? 'cryptofights' : 'nft'
            await ms.unlist(item, setPopup, setProgressPopupString, coll)
            history.push('/myitems')
        }
        catch(e){
            console.log("Error with transaction = ", e)
        }
    }
    const list = async (price) =>{
        try{
            closeSellPopup()
            setTransactionType("Listing Item")
            console.log("attempting list = ", price, props)
            let coll = props.collection == 'cryptofights' ? 'cryptofights' : 'nft'
            await ms.list(item, setPopup, setProgressPopupString, coll, price)
            history.push('/marketplace')

        }
        catch(e){
            console.log("Error with transaction = ", e)
        }
    }
    const melt = async () =>{
        try{
            setTransactionType("Melting Item")
            let coll = props.collection == 'cryptofights' ? 'cryptofights' : 'nft'
            await ms.melt(item.location, setPopup, setProgressPopupString, coll)
            history.push('/myitems')

        }
        catch(e){
            console.log("Error with transaction = ", e)
        }
    }
    const transfer = async (username) =>{
        try{
            closeTransferPopup()
            setTransactionType("Transfer Item")
            let coll = props.collection == 'cryptofights' ? 'cryptofights' : 'nft'
            await ms.transfer(item, setPopup, setProgressPopupString, coll, username)
            history.push('/myitems')

        }
        catch(e){
            console.log("Error with transaction = ", e)
        }
    }
    const closeSellPopup = () => {
        setSellPopupVisible(false)

    }
    const openSellPopup = () => {
        setSellPopupVisible(true)

    }
    const openTransferPopup = () => {
        setTransferPopupVisible(true)

    }
    const closeTransferPopup = () => {
        setTransferPopupVisible(false)

    }
    const selectedAttachmentMethod = async(url) => {
        let res = await axios.get(url)
        console.log("response from attachment get = ", res)
        setSelectedAttachment(url)
        if(res.headers){
            if(res.headers['content-type'].includes('image')) setSelectedAttachmentType('image')
            else setSelectedAttachmentType('iframe')
        }
    }
    return(
        item && <div>
            <ProgressPopup visible={popup} stringArr={progressPopupString} title={transactionType} total={totalSteps[transactionType]}></ProgressPopup>
            <SellPopup visible={sellPopupVisible} cancelPopup={closeSellPopup} item={item} list={list}/>
            <TransferPopup visible={transferPopupVisible} cancelPopup={closeTransferPopup} item={item} transfer={transfer}/>
            <div className="d-flex flex-wrap justify-content-between mt-10 mb-10 item-flex-box">
                <div className="card card-rounded shadow-sm p-10 mb-10 d-flex flex-column justify-content-between item-det-box" style={{width: '30%', height: '500px'}}>
                    <div className="fw-bolder fs-5 mb-1">{item.metadata.name}</div>
                    {props.collection=='cryptofights' && <div className={`d-flex card card-rounded shadow-sm fs-5 mb-1 justify-content-center align-items-center ${quality[item.item.quality].toLowerCase()}`} style={{width: 80, height: '30px'}}>{quality[item.item.quality]}</div>}
                    {selectedAttachment ? 
                        selectedAttachmentType == "image"? <img className="fit-iframe" style={{height: '340px'}} src={selectedAttachment}></img>
                        :
                        <iframe className="fit-iframe" style={{height: '340px'}} src={selectedAttachment}></iframe>
                        :
                        <img style={{height: '300px'}} src={`https://dogefiles.twetch.app/${item.metadata.imageUrl.split('b://')[1].split('/')[0]}`}></img>

                    }
                    <div className="mt-5">
                        {(item.owner.address!==address) ? 
                            item.owner.address && Button('Buy', buy, '#5200E5')
                            :
                            Button('Unlist', unlist, '#5200E5')
                        }
                    </div>
                </div>
                <div className="card card-rounded shadow-sm p-10 item-det-box mb-10 desc-box" style={{width: '66%', height: '500px', overflowY: 'scroll', scrollbarWidth: '10px !important'}}>
                    <div className="fw-bolder fs-5">NFT Details</div>
                    {props.collection=='cryptofights' ? 
                        <div className="fw-bold fs-8 mt-5 mb-5">{item.item.description}</div>
                        :
                        <div className="fw-bold fs-8 mt-5 mb-5">{item.metadata.description}</div>
                        }
                    {
                        props.collection !=='cryptofights' &&
                        <div>
                            <div className="fw-bolder fs-5 mt-5">Author</div>
                            <div className="fw-bold fs-8 mb-5">{item.metadata.author}</div>
                            <div className="fw-bolder fs-5">Supply</div>
                            <div className="fw-bold fs-8 mb-5">{item.metadata.index} of {item.metadata.supply}</div>
                        </div>
                    }
                    {Object.keys(item.item).length >0 && 
                        <div style={{marginRight: '25%'}}>
                            <div className="fw-bolder fs-5 mt-0">Properties</div>
                                <div className="card w-100 d-flex flex-row flex-wrap justify-content-between">
                                <div className="property-box">
                                    <div className="fs-6 fw-bolder">{type[item.item.type] || item.item.type}</div>
                                    <div className="text-muted fs-8 fw-bolder">Category</div>
                                </div>
                                <div className="property-box">
                                    <div className="fs-6 fw-bolder">{quality[item.item.quality] || item.item.quality}</div>
                                    <div className="text-muted fs-8 fw-bolder">Quality</div>
                                </div>
                                {<div className="property-box">
                                    <div className="fs-6 fw-bolder">{(item.item.properties && getProperties(item.item.properties)) || "None"}</div>
                                    <div className="text-muted fs-8 fw-bolder">Properties</div>
                                </div>}
                            </div>
                        </div>}
                    {item.owner.satoshis && <div>
                        <div className="fw-bolder fs-5 mt-5">Listing Price</div>
                        <div className="fw-bolder fs-4">{(item.owner.satoshis*localStorage.getItem('usdTR')*1.04/100000000).toFixed(2)} USD</div>
                        <div className="text-muted fw-bolder fs-7">{item.owner.satoshis*1.04/100000000} BSV</div>
                    </div>}
                    {
                        !item.owner.satoshis &&
                            <div>
                                <div className="fw-bolder fs-4 mt-5 mb-5">Actions</div>
                                <div className="card d-flex flex-row flex-wrap justify-content-between" style={{width: '80%'}}>
                                    {Button('List', openSellPopup, '#5200E5')}
                                    {Button('Melt', melt, null)}
                                    {Button('Transfer', openTransferPopup, '#5200E5')}
                                </div>
                            </div>
                    }
                </div>

                <div className="card card-rounded shadow-sm p-10 mb-10 item-det-box" style={{width: '30%', height: '200px'}}>
                    <div className="fw-bolder fs-7">Collection</div>
                    {collection == null ?
                        <div className="d-flex flex-column mt-5">
                            <img src='../../assets/cryptoFGif.gif' style={{width: '60px', borderRadius: '30px'}}></img>
                            <div className="text-muted fs-9 mt-5">CryptoFights</div>
                        </div>
                        :
                        collection && <div className="d-flex flex-column mt-5">
                            <img className="shadow-sm" src={`https://dogefiles.twetch.app/${collection.metadata.imageUrl.split('b://')[1].split('/')[0]}`} style={{width: '60px', borderRadius: '30px'}}></img>
                            <div className="text-muted fs-9 mt-5 text-capitalize">{collection.metadata.name}</div>
                        </div>
                    }
                </div>
                <div className="card card-rounded shadow-sm p-10  mb-10 item-det-box " style={{width: '66%', height: '200px'}}>
                    <div className="fw-bolder fs-7">Attachments</div>
                    {(!item.metadata.attachments || item.metadata.attachments.length==0) ?
                        <div className="d-flex flex-column mt-10">
                            <FaUnlink size={60} color={'grey'}/> 
                            <div className="text-muted fs-9 mt-5">No attachments in this NFT</div>
                        </div>
                        :
                        <div className="d-flex flex-column ">
                            {/* <FaUnlink size={60} color={'grey'}/>  */}
                            <div className="text-muted fs-9 mt-5 w-100 d-flex flex-row flex-wrap">{
                                item.metadata.attachments.map( i=> {
                                    return(
                                        <div>
                                            <FaExpand color="blue" style={{cursor: 'pointer'}} onClick={()=>selectedAttachmentMethod(`https://dogefiles.twetch.app/${i.url.split('b://')[1].split('/')[0]}`)}/>
                                            <embed  className="w-50px h-50px rounded-circle shadow-sm me-3" src={`https://dogefiles.twetch.app/${i.url.split('b://')[1].split('/')[0]}`}/>
                                        </div>
                                    )
                                })
                            }</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state =>{ 
    return ({
     keypair: state.Blockchain.keypair,
     run: state.Blockchain.run,
     blockchain: state.Blockchain.blockchain,
     bip32: state.Blockchain.bip32
  });}
export default connect(mapStateToProps)(CryptofightsItem)