import React, {useState, useEffect} from 'react'
import Loader from "react-loader-spinner";

export function Button(body, callback, color, timeout){
    // const [loading, setLoading] = useState(false)
    // useEffect(()=>{},[])
    // let loading = false
    
    async function handleClick(e){
        if(callback) callback(e)
        
    }
    return(
        <div className='text-center' style={{cursor: 'pointer !important'}}>
            <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg mb-5 p-3 fs-7 new-button-custom'
                onClick={e=> handleClick(e)}
                disabled={false}
                style={color ? {backgroundColor: color, color: 'white', minWidth: '100px'}: {backgroundColor: "#d4d3d3", color: 'black', minWidth: '100px'}}
            >
                {!false && <div className='indicator-label'>{body}</div>}
                {false && <Loader
                    type="Puff"
                    color="white"
                    height={30}
                    width={30}
                    timeout={30000} //3 secs
                    />}
            </button>
        </div>
    )
}