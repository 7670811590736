import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap-v5/lib/Dropdown'
import { FaTimesCircle, FaChevronLeft } from "react-icons/fa";
export function SellPopup({ visible, cancelPopup, item, list }){
    let price = ""
    let usdTransferRate = localStorage.getItem('usdTR')
    const [currencyHeading, setCurrencyHeading] = useState('BSV')
    return (
        visible ? <div className="bg">
            <div className="customModal">

                <div className="modalRow modalRow1 fw-bolder">
                    SELL ITEMS ON MARKETPLACE?
                    <FaTimesCircle className="modalCancel" onClick={cancelPopup} />
                </div>
                <div className="modalRow modalRow2" style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                    This action cannot be undone. <br /> This listing will be public for 7 days on the marketplace.<br /> <br />
                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: '25%'}}>
                        <Dropdown>
                            <Dropdown.Toggle style={{backgroundColor: '#5200E5', color: 'white', border: 'none'}} className="ddNew" id="dropdown-basic">
                                {currencyHeading}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onSelect={(a, e)=> setCurrencyHeading(e.target.innerHTML)}>USD</Dropdown.Item>
                                <Dropdown.Item onSelect={(a, e)=> setCurrencyHeading(e.target.innerHTML)}>BSV</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <input style={currencyHeading == 'USD' ? { width: '150px', marginLeft: '5%', color: 'black' } : {display: 'none'}} type="text" className="inputHandle" onChange={(e) => { 
                            price = e.target.value 
                            document.getElementById('injectPrice').innerHTML = (price/usdTransferRate).toFixed(8)
                            // setTestPrice(e.target.value)
                            }} placeholder='Asking Price in USD'></input>
                        <input style={currencyHeading != 'USD' ? { width: '150px', marginLeft: '5%', color: 'black' } : {display: 'none'}} type="text" className="inputHandle" onChange={(e) => { price = e.target.value
                        // setTestPrice(e.target.value) 
                        document.getElementById('injectPrice').innerHTML = (price*usdTransferRate).toFixed(8)
                        }} placeholder='Asking Price in BSV'></input>
                    </div>
                    <br/>
                    <div>Price Conversion in <span id="injectPrice">0</span> {currencyHeading == 'BSV' ? "USD" : "BSV"}</div>
                </div>
                <div className="modalRow modalRow3 ps-20 pe-20">
                    <button className="but modalBut fw-bolder" style={{backgroundColor: '#c7c4c4', borderRadius: 4, height: '100%', width: '80px'}} onClick={cancelPopup}>Cancel</button>
                    
                    {/* <button className="but modalBut" onClick={()=> console.log("Check transfer rate = ", price/usdTransferRate)}>Cancel</button> */}
                    <button className="but modalBut modalButC fw-bolder" style={{backgroundColor: '#5200E5', borderRadius: 4, color: 'white'}} onClick={() => list(currencyHeading=='BSV' ? price : (price/usdTransferRate).toFixed(8))}>Sell Item(s)</button>
                </div>
            </div>
        </div> : null

    )
}
export function TransferPopup({ visible, cancelPopup, item, transfer }){

    let name = ""
    return (
        visible ? <div className="bg">
            <div className="customModal">

                <div className="modalRow modalRow1 fw-bolder">
                    TRANSFER ITEMS?
                    <FaTimesCircle className="modalCancel" onClick={cancelPopup} />
                </div>
                <div className="modalRow modalRow2">
                    <input style={{ color: 'black' }} type="text" className="inputHandle" onChange={(e) => { name = e.target.value }} placeholder="Gamer Id"></input>
                </div>
                <div className="modalRow modalRow3 ps-20 pe-20">
                    <button className="but modalBut fw-bolder" style={{backgroundColor: '#c7c4c4', borderRadius: 4, height: '100%', width: '180px'}}  onClick={cancelPopup}>Cancel</button>
                    <button className="but modalBut modalButC fw-bolder"  style={{backgroundColor: '#5200E5', borderRadius: 4, height: '100%', width: '180px', color: 'white'}}  onClick={() => transfer(name)}>Transfer Item(s)</button>
                </div>
            </div>
        </div> : null

    )
}
