import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import MiniDrawer from './MiniDrawer'

const theme = createTheme({
    typography: {
        fontFamily: 'Poppins, sans-serif',
        h1: { fontSize: '20px', fontWeight: 600, lineHeight: '30px', color: '#181C32' }
    },
    components: {
        Breadcrumbs: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    fontSize: '2px',
                },
            },
        },
    }
});


export default function SharedLayout({ children }) {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <MiniDrawer>
                {children}
            </MiniDrawer>
        </ThemeProvider>
    )
}