import React, {useEffect} from 'react'
import {AuthHeader} from './AuthHeader'
import Loader from "react-loader-spinner";
import axios from 'axios'
import {useHistory, useParams } from "react-router-dom";
export function EmailAuth(){
    let params = useParams();
    let history = useHistory()
    let headerObj = {
            head: "Email Verification..",
            body: "Please wait while we verify your account"
    }
    useEffect(async ()=>{
        await new Promise((res, rej)=> {
            setTimeout(()=>{
                res()
            }, 4000)
        })
        await axios.post(process.env.REACT_APP_HOST_URL+`/accounts/emails/verify/${params.userId}/${params.nonce}`).then((res)=>{
            history.push('/login', { from: 'emailVerification' })
        })
        .catch((e)=>{
            console.log("Error = ", e)
        })

    })
    return(
        <React.Fragment>
            <AuthHeader object={headerObj}/>
            <div className="d-flex flex-column mb-5" style={{justifyContent: 'center',alignItems: 'center'}}>
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={30}
                    width={30}
                    timeout={5000} //3 secs
                />
            </div>
        </React.Fragment>
    )
}