import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import '../css/Login.css'
import {LoginService} from '../service/LoginService'
import {Forms} from '../../helpers/components/Forms'
import {AuthHeader} from './AuthHeader'
import initialize from '../../RunInitialize'
import {connect} from "react-redux";
import {addKeypair, addRunInstance, addBlockchainInstance, addBip} from '../../redux/blockchain/actions';

const Login = (props) => {

    let email = true
    let history = useHistory()
    let touched = true
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({state: false})
    let obj = {
        head: history?.location?.state?.from === "emailVerification" ? "Your account has been verified. Continue to Sign in." : "Sign in to Fyx Gaming",
        body: "Don't have an account yet?",
        url: {
            body: "Sign Up",
            url: '/signup'
        }
    }
    let fields = [
        {title: 'Username', name: 'username', type: 'text'},
        {title: 'Password', name: 'password', type: 'password'}
    ]
    useEffect(() => {
        console.log("props in login page = ", props)
    }, [error])
    const loginSubmit = async (e) => {
        e.preventDefault()
        let config = process.env.REACT_APP_NETWORK === 'mainnet' ? {
                network: 'mainnet',
            } :
            {
                network: 'testnet',
            }
        const ls = new LoginService(config)
        let response = await ls.login(e.target.username.value, e.target.password.value)
        console.log("response from login = ", response)
        if (!response.error) {
            // let bip32 = response
            await props.addBip(response.bip32) //keep
            let t = await initialize(response.bip32, response.kp, config)
            await props.addBlockchainInstance(t.blockchain)
            await props.addRunInstance(t.run)
            if (history.location.state && history.location.state.from === "emailVerification") {
                history.push('/seed')
            } else {
                history.push('/wallet')
            }
            console.log("No error in login initialize here")

        } else {

            console.log("error in logins = ", response.error.message, response.error.status)
            if (response.error.status == 401) {
                setError({
                    state: true,
                    message: "Your credentials are invalid. Please try again.",
                    field: 'password'
                })
            } else if (response.error.status == 403) {
                console.log("message = ", response.error.message)
                let e = "Your credentials are invalid. Please try again."
                if (response.error.message.toLowerCase().includes('timestamp')) e = "Your clock is not in sync. Please check the Date and time on your system and try again. Note: Set your time accurately according to your timezone."
                setError({
                    state: true,
                    message: e,
                    field: 'password'
                })
            }
        }
    }
    return (
        <React.Fragment>
            {Forms(loginSubmit, obj, fields, error)}
            <div className='text-center text-muted fw-bolder mb-5'>Forgot your Password?
                <Link to='/forgotpassword' className="tx-fyx fw-bolder"> Click here</Link>
            </div>

        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return ({
        keypair: state.Blockchain.keypair,
        run: state.Blockchain.run,
        blockchain: state.Blockchain.blockchain
    });
}

export default connect(mapStateToProps,
    {addKeypair, addRunInstance, addBlockchainInstance, addBip})(Login);