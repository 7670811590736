import React from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router'
function MarketplaceTabs(props){
    let history = useHistory()
    return(
        <React.Fragment>
            <div className="mt-0 p-3 p-lg-3 d-flex flex-row">
                <div className={clsx("card card-rounded shadow-sm p-3 fw-bold",{
                    "active-tab": props.active == 'all',
                    "bg-bk": props.active !== 'all',
                })}
                style={{cursor: 'pointer'}}
                onClick={()=>props.callback('all')}
                >{props.page == 'myitems' ? "My Inventory": "All Listed Items"}</div>
                <div className={clsx("card card-rounded shadow-sm p-3 fw-bold ms-10",{
                    "active-tab": props.active == 'my',
                    "bg-bk": props.active !== 'my',
                })}
                style={{cursor: 'pointer'}}
                onClick={()=>props.page == 'myitems' ? history.push('/marketplace'): props.callback('my')}>{props.page == 'myitems' ? "Marketplace": "My Listed Items"}</div>
                {props.page!="myitems" && <div className={clsx("card card-rounded shadow-sm p-3 fw-bold ms-10",{
                    "active-tab": props.active == 'inventory',
                    "bg-bk": props.active !== 'inventory',
                })}
                style={{cursor: 'pointer'}}
                onClick={()=>history.push('/myitems')}>My NFT Items</div>}
            </div>
        </React.Fragment>
    )
}
export default(MarketplaceTabs)