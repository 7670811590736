import React, { useEffect, useState } from 'react'
import { FaChevronDown } from 'react-icons/fa'
import { Button } from '../../helpers/components/Button'
function MarketplaceFilters(props){
    let checkboxes = {"Innocuous": false,
    "Brutal": false,
    "Dexterous": false,
    "Ambivalent": false,
    "Assault": false,
    "Precision": false,
    "Loading": false,
    "Swift": false,
    "Keen": false,
    "Light": false,
    "Heavy": false,
    "Versatile": false,
    "Strenuous": false, 
    "Poor":false,
    "Common":false,
    "Uncommon":false,
    "Rare":false,
    "Epic":false,
    "Legendary":false,
    "Special":false,
    "Axes":false, 
    "Bows":false, 
    "Clubs":false, 
    "Daggers":false, 
    "Great Swords":false, 
    "Maces":false, 
    "Pikes":false, 
    "Staffs":false, 
    "Swords":false, 
    "Mauls": false}
    const [jsx, setJsx] = useState(null)
    const [slice, setSlice] = useState(null)
    const [refresh, setRefresh] = useState(true)
    const [disabled, setDisabled] = useState(checkboxes)

    useEffect(()=>{
        // console.log("calling useEffect ", slice)
        let split = {}
        let temp = Object.keys(props.categories).map(i=>{
            split[i] = props.categories[i].length
            return(
                <div className="fw-bolder text-capitalize mb-5">
                    <div className="text-muted mb-5">{i}</div>
                    {props.categories[i].slice(0,slice ? props.categories[i].length : 3).map(val => {
                        let temp = {}
                        temp[i] = val
                        if(i == 'properties'){
                            return(
                                <div className="form-check mb-2">
                                    {/* onChange={(e)=>handleMultiSelectDisable(val, i, e) */}
                                    <input className="form-check-input" type={i=="properties" ? "checkbox": "radio"} value={JSON.stringify(temp)} id={val} ></input>
                                    <label className="form-check-label" for="flexCheckDefault">
                                    {val}
                                    </label>
                                </div>
                            )
                        }
                        else{
                            return(
                                <div className="form-check mb-2">
                                    {/* onChange={(e)=>handleMultiSelectDisable(val, i, e) */}
                                    <input className="form-check-input" type={"radio"} name={i == 'quality' ? "flexRadioDefault1":"flexRadioDefault2"} value={JSON.stringify(temp)} id={val} ></input>
                                    <label className="form-check-label" for={i == 'quality' ? "flexRadioDefault1":"flexRadioDefault2"} >
                                    {val}
                                    </label>
                                </div>
                            )
                        }
                    })}
                    {!slice && <div className="text-muted mb-5 mt-3" onClick={()=>{setSlice(10)}}> See {props.categories[i].length-3} more <FaChevronDown/></div>}
                </div>
            )
        })
        setJsx(temp)
    },[slice, refresh, disabled])
    const clearFilters = () => {
        const checkedRadios = document.querySelectorAll('input[type="checkbox"]:checked')
        checkedRadios.forEach(i => i.checked = false)
        const checkedRadios1 = document.querySelectorAll('input[type="radio"]:checked')
        checkedRadios1.forEach(i => i.checked = false)
        const values = Array.from(checkedRadios, radio => radio.value)
        setSlice(null)
        setRefresh(!refresh)
        props.callback(null)
    }
    const setFilters = () => {
        const checkedRadios = document.querySelectorAll('input[type="checkbox"]:checked')
        let values = Array.from(checkedRadios, radio => radio.value)
        const checkedRadios1 = document.querySelectorAll('input[type="radio"]:checked')
        const values1 = Array.from(checkedRadios1, radio => radio.value)
        console.log("checking filter set = ", values, values1)
        values = values.concat(values1)
        let returnObj = values.map(i=> JSON.parse(i))
        props.callback(returnObj)
    }
    return(
        <React.Fragment>
            <div className="card card-rounded shadow-sm p-5 mt-10 me-10 filters" style={{minWidth: 200}}>
                <div className="fs-4 fw-bolder mb-5">Filter Items</div>
                {jsx}
                {Button("Apply Filters", setFilters, '#5200E5', 5)}
                {Button("Clear All Filters", clearFilters, null)}
            </div>
        </React.Fragment>
    )
}
export default (MarketplaceFilters)