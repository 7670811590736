import React, { useState, useEffect, useCallback } from 'react';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import copy from 'copy-to-clipboard';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import axios from 'axios';
import { useHistory } from "react-router-dom";
import { FaCopy, FaCircle } from "react-icons/fa";

// import Loading from 'react-circular-loading'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import '../marketplace/css/marketplace.css'
import clsx from 'clsx';
import PaginationRounded, { ComponentTester } from '../ComponentTester';
import {useDispatch} from "react-redux";
import {showMessage} from "../redux/messages/actions";
import {Message} from "../messages/Message";
// import { useDataExplorer, DataExplorerProvider } from './DataExplorer.service';

export const DataExplorer = (props) => {

    const dispatch = useDispatch();
    let history = useHistory()

    let quality = [ "bg-bk-2",
    "bg-bk-2",
    "Uncommon",
    "Rare",
    "Epic",
    "Legendary",
    "Special"]
    const [popupVisible, setPopupVisible] = useState(false)
    const [subject, setSubject] = useState('Deposit')
    const [stk, setStk] = useState()
    const [element, setElement] = useState([])
    const [update, setUpdate] = useState(true)
    const [index, setIndex] = useState(0);
    const [activeTab, setActivetab] = useState('all')
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    const [newEvents, setNewEvents] = useState([])
    const [usdTransferRate, setUsdTransferRate] = useState(null)
    const [check, setCheck] = useState(0)
    const [config, setConfig] = useState({})
    const [origins, setOrigins] = useState([])
    const [pageNumber, setPageNumber] = useState(1)
    const [jigs, setJigs] = useState({})
    let location = props.location
    let ws

    useEffect(async () => {

        if (!props.location) location = JSON.parse(localStorage.getItem('location'))
        //check here

        console.log("REACHED HERE")
        setUsdTransferRate(localStorage.getItem('usdTR'))
        let battles = activeTab == 'all' ? await axios.post(process.env.REACT_APP_HOST_URL + '/cryptofights/battles', {
            playerCount: 2,
            offset: (pageNumber - 1),
            limit: 20
        })
        :
        await axios.post(process.env.REACT_APP_HOST_URL + '/cryptofights/battles', {
            userId: localStorage.getItem('userHandle'),
            offset: (pageNumber - 1),
            limit: 20
        })
        console.log("JIG TO DEBUGGGGGG from new endpoint initial= ", battles)
        battles = await Promise.all(battles.data.map(async ({ id }) => {
            const { data: item } = await axios(`${process.env.REACT_APP_HOST_URL}/cryptofights/battles/${id}`);
            return item;
        }));
        console.log("JIG TO DEBUGGGGGG from new endpoint = ", battles)
        await setJigs(battles)
        battles && await CardStack(battles)
    }, [pageNumber, activeTab]);
    let setTab = (tab) => {
        setActivetab(tab)
        console.log("setting active tab = ", tab)
    }
    useEffect(async () => {
        if (update.origin) {
            const test = await axios.get(process.env.REACT_APP_HOST_URL + "/cryptofights/battles/" + update.id)
            const CardStackSocket = async (num) => {
                const stack = []
                const checkStack = []
                for (let i = 0; i < num.length; i += 2) {
                    let item = num[i]
                    const url = 'summary/' + item.id + '/' + item.hasOwnProperty('live')
                    let d = new Date(item.timestamp)
                    var year = d.getFullYear();
                    var month = (1 + d.getMonth()).toString();
                    month = month.length > 1 ? month : '0' + month;
                    var day = d.getDate().toString();
                    day = day.length > 1 ? day : '0' + day;
                    const rew = item.value.battle.hasOwnProperty('rewards') ? item.value.battle.rewards[0].item.displayName : '-'
                    checkStack.push(
                        <div style={{ color: 'white', display: 'flex', flexDirection: 'row' }}><FaCircle className="liveDot" />
                            <div style={{ width: '100%' }}>

                                <Link className="link" to={url}>
                                    <div className={item.socket && item.socket == true ? "tableHeaders animate" : "tableHeaders"}>
                                        <div className="box-text tf" style={{ width: '20%' }}><span className="box-font">{item.value.battle.battlePlayers[0].fighter.metadata.name} <span className="box-text1">vs</span> {item.value.battle.battlePlayers[1].fighter.metadata.name}</span></div>
                                        <div className="box-text tw" style={{ width: '20%' }}>{month + '.' + day + '.' + year + ' ' + d.toTimeString().slice(0, 9)}</div>
                                        <div className="box-text te" style={{ width: '10%' }}>-</div>
                                        <div className="box-text of" style={{ width: '15%' }}>{rew}</div>
                                        <div className="box-text of" style={{ width: '15%' }}>{item.value.battle.rules.playerCount == 1 ? "Bot Battle" : "PvP"}</div>
                                        <div className="box-text f" style={{ width: '8%' }}><img className="mob-img" style={{ width: '2vw', height: 'auto' }} src={'./assets/Log.png'}></img></div>
                                        <div className="box-text f" style={{ width: '8%' }}><img className="mob-img" style={{ width: '2vw', height: 'auto' }} src={'./assets/Share.png'}></img></div>
                                        <div className="box-text f" style={{ width: '8%' }}><img className="mob-img" style={{ width: '2vw', height: 'auto', opacity: '30%' }} src={'./assets/Watch.png'}></img></div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    )

                }

                if (!element.includes(checkStack)) await setElement([checkStack, ...element])


            }
            if (!origins.includes(update.origin) && test.data.rules.playerCount == 2) {
                setOrigins([...origins, update.origin])
                CardStackSocket([{ origin: update.origin, value: { battle: test.data }, ts: test.data.timestamp, socket: true }])
            }

        }
    }, [update, usdTransferRate])

    const CardStack = async (num) => {
        // let num = jigs
        const stack = []
        const checkStack = []
        // for(let item of num){
        for (let i = 0; i < num.length; i += 1) {
            //ask if victor can be null
            let item = num[i]
            console.log("Item to debug = ==== ", item)
            const url = 'summary/' + item.id + '/' + item.hasOwnProperty('live')
            let d = new Date(item.timestamp)
            var year = d.getFullYear();
            var month = (1 + d.getMonth()).toString();
            month = month.length > 1 ? month : '0' + month;
            var day = d.getDate().toString();
            day = day.length > 1 ? day : '0' + day;
            const rew = item.hasOwnProperty('rewards') ? item.rewards[0].item.displayName : '-'
            checkStack.push(<TableRow key={item.id}>
                                <TableCell className="bg-bk">{month + '.' + day + '.' + year + ' ' + d.toTimeString().slice(0, 9)}</TableCell>
                                <TableCell align="center" style={{color: '#00A3FF'}}>{item.battlePlayers[0].fighter.metadata.name} VS {item.battlePlayers[1].fighter.metadata.name}</TableCell>
                                <TableCell className="bg-bk" align="center">${(item.rules.reward * localStorage.getItem('usdTR') / 100000000).toFixed(2)}</TableCell>
                                <TableCell align="center">
                                    <div className={item.rewards ? `card card-rounded h-30px d-flex flex-column justify-content-center ${quality[item.rewards[0].item.quality].toLowerCase()}`: null}>{rew}</div>
                                </TableCell>
                                <TableCell className="bg-bk" align="right">{item.rules.rulesId}</TableCell>
                                <TableCell className="bg-bk" align="right">{item.id.slice(0,8)}...
                                    <Message content='Copied to clipboard!' msgKey={item.id}>
                                        <FaCopy color="#b5b5c3" className="fyx-oh" onClick={()=> {
                                            copy(item.id);
                                            dispatch(showMessage(item.id));
                                        }}/>
                                    </Message>
                                </TableCell>
                                <TableCell className="bg-bk" align="right"><Button className="text-muted bg-bk-2" style={{cursor: 'no-drop !important'}}>Watch</Button></TableCell>
                                <TableCell className="bg-bk" align="right"><Button className=" bg-bk-2" onClick={()=>history.push(`/summary/${item.id}/false`)}>View</Button></TableCell>
                            </TableRow>

                            )

        }
        await setStk(stack)
        renderCard(checkStack)

    }
    const renderCard = async (stack) => {
        const elem = []
        for (let items of stack) {
            elem.push(
                items
            )
        }

        await setElement(elem)
    }
    return (
        <React.Fragment>
            {
                <div style={{width: '100%'}}>
                    <Typography variant="h1" className="bg-bk-2">Data Explorer</Typography>
                    <Breadcrumbs aria-label="breadcrumb" sx={{fontSize: '13px', color:'#B5B5C3', paddingBottom: '0px'}}>
                        <Link underline="hover" color="inherit" href="/">
                            Fyx Home
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            href="/"
                        >Portal
                        </Link>
                        <Typography color="#181C32" className="bg-bk-2 fw-bold">Data Explorer</Typography>
                    </Breadcrumbs>
                    <div className="mt-10 card card-rounded shadow-sm p-3 p-lg-3 d-flex flex-row bg-bk text-light">
                        <div className={clsx("card card-rounded shadow-sm p-3 text-dark fw-bold ",{
                            "active-tab": activeTab == 'all',
                            "bg-bk": activeTab !== 'all'
                        })}
                        style={{cursor: 'pointer'}}
                        onClick={()=>setActivetab('all')}
                        >All Battles</div>
                        <div className={clsx("card card-rounded shadow-sm p-3 fw-bold text-dark ms-10",{
                            "active-tab": activeTab == 'my',
                            "bg-bk": activeTab !== 'my'
                        })}
                        style={{cursor: 'pointer'}}
                        onClick={()=>setActivetab('my')}>My Battles</div>
                    </div>
                    <br/>
                    <TableContainer component={Card} className="bg-bk">
                        <Table sx={{ minWidth: '100%' }} aria-label="simple table" className="bg-bk">
                            <TableHead className="bg-bk">
                                <TableRow className="bg-bk" style={{color: 'white'}}>
                                    <TableCell className="bg-bk">Date</TableCell>
                                    <TableCell className="bg-bk" align="center">Participants</TableCell>
                                    <TableCell className="bg-bk" align="center">Cash Prize</TableCell>
                                    <TableCell className="bg-bk" align="center">Item Reward</TableCell>
                                    <TableCell className="bg-bk" align="right">Arena</TableCell>
                                    <TableCell className="bg-bk" align="right">Battle ID</TableCell>
                                    <TableCell className="bg-bk" align="right">Replay</TableCell>
                                    <TableCell className="bg-bk" align="right">Details</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {element}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br/>
                    <PaginationRounded page={pageNumber} pageCount={5} onChange={setPageNumber}/>
                    <br/>
                </div>
            }
        </React.Fragment>
    )
}