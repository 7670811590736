import React, { Component } from 'react';
import { FaTimesCircle } from 'react-icons/fa';

const Popup = ({visible, cancelPopup, onCreate}) => {
     
    return(
        visible ? <div className="bg">
            <div className="customModal">
                
                <div className="modalRow modalRow1">
                    ARE YOU SURE?
                    <FaTimesCircle className="modalCancel" onClick={cancelPopup}/>
                </div>
                <div className="modalRow modalRow2">
                    This action cannot be undone
                </div>
                <div className="modalRow modalRow3">
                    <button className="but modalBut" onClick={cancelPopup}>Cancel</button>
                    <button className="meltButton2 modalBut modalButC" onClick={onCreate}>Confirm</button>
                </div>
            </div>
        </div> : null
        
    )
}
export default (Popup);