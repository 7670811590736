import React, {useEffect, useState} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {Header, Breadcrumbs} from '../helpers/components/Pageheader'
import ItemsGrid from './components/ItemsGrid'
import MarketplaceTabs from './components/MarketplaceTabs'
import './css/marketplace.css'
import Dropdown from '../helpers/components/Dropdown'
import {MarketplaceService} from './service/MarketplaceService'
import { FaSearch } from "react-icons/fa";
import MarketplaceFilters from './components/MarketplaceFilters'
import {useDispatch} from "react-redux";
import {showModalMsg} from "../redux/messages/actions";
import {ModalMessage} from "../messages/ModalMessage";
import {YES_NO} from "../messages/constants/buttonTypes";
import { Button } from '../helpers/components/Button'

function MarketplaceWrapper(props){
    let {collLocation} = useParams()
    let history = useHistory()
    const [activeTab, setActivetab] = useState('all')
    const [collections, setCollections] = useState(null)
    const [searchQuery, setSearchQuery] = useState(null)
    const [filter, setFilter] = useState(null)
    const [collectionLocation, setCollectionLocation] = useState({value: 'cryptofights', label: 'CryptoFights',fyxId: 'cryptofights', location: 'cryptofights', image: '../assets/cryptoFGif.gif'})
    let setTab = (tab) => {
        setActivetab(tab)
        console.log("setting active tab = ", tab)
    }
    let setActiveCollection = (coll) =>{
        setCollectionLocation(coll)
        console.log("setting active coll = ", coll)
    }
    let properties = ["Innocuous",
    "Brutal",
    "Dexterous",
    "Ambivalent",
    "Assault",
    "Precision",
    "Loading",
    "Swift",
    "Keen",
    "Light",
    "Heavy",
    "Versatile",
    "Strenuous"]
    let quality = [ "Poor",
    "Common",
    "Uncommon",
    "Rare",
    "Epic",
    "Legendary",
    "Special"]
    let type=["Axes", "Bows", "Clubs", "Daggers", "Great Swords", "Maces", "Pikes", "Staffs", "Swords", "Mauls"]
    let categories = {
        properties: properties,
        quality: quality,
        type: type
    }
    const setFilters = (e) => {
        console.log("reading filters in wrapper = ", e)
        if(e == null) {
            setFilter(null)
            
        }
        else{
            let temp = {}
            temp.properties = new Array(13)
            temp.properties.fill(false)
            for(let item of e){
                if(Object.keys(item)[0] == 'quality'){
                    temp['quality'] = quality.indexOf(item['quality'])
                }
                else if(Object.keys(item)[0] == 'type'){
                    temp['type'] = type.indexOf(item['type'])
                }
                else if(Object.keys(item)[0] == 'properties'){
                    temp['properties'] = properties.map(i => {
                        if(i==item['properties']) return temp['properties'][properties.indexOf(i)] || true
                        else return temp['properties'][properties.indexOf(i)] || false
                    })
                }
            }
            console.log("Final query item ==== ", temp)
            setFilter(temp)
        }
    }
    useEffect(()=>{

    },[collLocation])
    useEffect(async ()=>{
        console.log("props and params in marketplace/myitems = ", props.page,"coll = ", collLocation )

        let config = process.env.REACT_APP_NETWORK === 'mainnet' ? {
            network: 'mainnet',
          } : 
          {
            network: 'testnet',
          }
        let ms = new MarketplaceService(props, config)
        let coll = await ms.fetchMarketplaceCollections(props.page, activeTab)
        console.log(`Collections in ${props.page} = `, coll)
        //script to transfer collection
        // "625ee91b71fd2a1fa3abce221400532d0fd2347bb8fef1b6328d1b4d4d7ccaf9_o1"
        // let testColTransfer = await ms.transfer({location: "625ee91b71fd2a1fa3abce221400532d0fd2347bb8fef1b6328d1b4d4d7ccaf9_o1"}, null, null, 'nft', 'abeliumSecond')
        //console.log("collection transfer succesful = ", testColTransfer)
        coll=coll.reverse()
        let collList = coll.map(items=>{
                
                if(props.page == "marketplace" && items.metadata.name.toLowerCase() == 'zenescope'){
                    items.metadata.imageUrl ? setCollectionLocation({value: items.metadata.name, label: items.metadata.name, fyxId: items.fyxId, location: items.origin, image: `https://dogefiles.twetch.app/${items.metadata.imageUrl.split('b://')[1].split('/')[0]}`})
                    :
                    setCollectionLocation({value: items.metadata.name, label: items.metadata.name, fyxId: items.fyxId, location: items.origin, image: null})
                }
                return items.metadata.imageUrl ? {value: items.metadata.name, label: items.metadata.name, fyxId: items.fyxId, location: items.origin, image: `https://dogefiles.twetch.app/${items.metadata.imageUrl.split('b://')[1].split('/')[0]}`}
                                                :
                                                {value: items.metadata.name, label: items.metadata.name, fyxId: items.fyxId, location: items.origin, image: null}
        })
        collList.unshift({value: 'cryptofights', label: 'CryptoFights',fyxId: 'cryptofights', location: 'cryptofights', image: '../assets/cryptoFGif.gif'})
        console.log("All collections = ", collList)
        setCollections(collList)

    },[activeTab, props])
    useEffect(()=>{},[activeTab, collectionLocation, searchQuery, filter, collections])

    return(
        <React.Fragment>
            <div className="wallet">
                {props.page == 'marketplace' ? 
                // <div>
                //     {Header("Marketplace")}
                //     {Breadcrumbs(["marketplace"])}
                // </div>
                null
                :
                <div>
                    {Header("My Items")}
                    {Breadcrumbs(["marketplace", "myitems"])}
                </div>}
                <MarketplaceTabs callback={setTab} active={activeTab} page={props.page}/>
                <br/>
                {props.page == 'marketplace' && 
                    <a className="mb-5" href='/marketplace/d3eed15b7db6e270fbf902926e90cec21770b0d5443c13cd3b00a4e9b8b2ab9e_o1'>
                        <img className="marketplace-banner" src={process.env.REACT_APP_HOST_URL+'/media/banner/marketplace_page_top_banner'}/>
                    </a>
                 }
                <div className="d-flex flex-row mt-5">
                    
                    <div style={{display: 'flex', flexDirection: 'row'}} className="">
                        <form className="search-bar" onSubmit={(e)=>{
                            e.preventDefault()
                            setSearchQuery(e.target.search.value)}}>
                            <FaSearch/>
                            <input className="search-input" type="text" name="search" placeholder="Search..."></input>
                        </form>
                            <Dropdown list={collections} callback={setActiveCollection}/>
                    </div>
                </div>
            
                <div className="d-flex flex-row">
                    {collectionLocation.location == 'cryptofights' && activeTab=="all" && <MarketplaceFilters categories={categories} callback={setFilters}/>}
                    {collections && <ItemsGrid tab={activeTab} collection={collectionLocation} searchQuery={searchQuery} filters={filter} page={props.page}/>}
                </div>
            </div>
        </React.Fragment>
    )
}
export default(MarketplaceWrapper)