import * as React from 'react';
import { useState } from 'react'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function PaginationRounded(props) {
    const [pageNumber, setPageNumber] = useState(props.page || 1)
    const [count, setCount] = useState(props.pageCount || 10)

    const handleClick = (e, page) => {
        if((page) === count) setCount(count + 10)

        setPageNumber(page)
        if (props.onChange)  {
            props.onChange(page)
        }
    }   

    return (
        <Stack spacing={2}>
            <Pagination page={pageNumber} count={count} variant="outlined" shape="rounded" onChange={handleClick} />
        </Stack>
    );
}

export const ComponentTester = (props) => {
    return (
        <div>
            <PaginationRounded pageCount={props.pageCount} page={props.page} onChange={(e) => alert(e)} />
            {/* <PaginationRounded onChange={(e) => alert(e)} /> */}
        </div>
    )
}