import React, { useEffect, useState } from 'react'
import {useHistory} from 'react-router-dom'
import {Header, Breadcrumbs} from '../helpers/components/Pageheader'
import {WalletCard} from './components/WalletCard'
import { connect } from "react-redux";
import {AuthHeader} from '../login/components/AuthHeader'
import axios from 'axios';
import {WalletBalance, WalletDetails, TransactionHistory, GetHelp} from './components/WalletBalance'
import './css/wallet.css'
import DepositWrapper from './components/DepositWrapper';
import WithdrawWrapper from './components/WithdrawWrapper';
import ReceiveWrapper from './components/ReceiveWrapper';
import BSVDeposit from './components/Deposit/BSVDeposit';
import BSVWithdraw from './components/Withdraw/BSVWithdraw';
import SimplexDeposit from './components/Deposit/SimplexDeposit';
import CryptoDeposit from './components/Deposit/CryptoDeposit';
import CryptoWithdraw from './components/Withdraw/CryptoWithdraw';
function WalletWrapper(props){
    let history = useHistory()
    let [balanceProp, setBalanceProp] = useState({})
    let [detailsProp, setDetailsProp] = useState({})
    let [popup, setPopup] = useState(false)
    let [level, setLevel] = useState(1)
    let [txType, setTxType] = useState('deposit')
    let [renderJSX, setRenderJSX] = useState(null)

    useEffect(async ()=>{
        if(props.run){
            let address = await props.run.purse.address
            let balance = await props.run.purse.balance()
            let paymail = localStorage.getItem('userHandle')+'@fyxgaming.com'
            let {data: exRate} = await axios.get(process.env.REACT_APP_HOST_URL+`/exchange/getmarketexchange/bsv`)
            console.log("Exchange rate = ", exRate)
            setBalanceProp({balance: (balance*exRate.price/100000000).toFixed(2), convertedBalance: balance/100000000, callback1: openDeposit, callback2: openWithdraw, callback3: openReceive})
            setDetailsProp({paymail: paymail, address: address})
        }

    },[props])
    const openDeposit = (type) => {
        setPopup(true)
        setTxType('deposit')
    }
    const openWithdraw = (type) => {
        setPopup(true)
        setTxType('withdraw')
    }
    const openReceive = (type) => {
        setPopup(true)
        setTxType('receive')
    }
    const setWindowCallback = (event) => {
        console.log("FINAL CALLBACK event = ", event)
        setLevel(2)
        switch(event){
            case 'BSVDeposit': 
                setRenderJSX(<BSVDeposit props={props}/>)
                break;
            case 'BSVWithdrawal': 
                setRenderJSX(<BSVWithdraw props={props}/>)
                break;
            case 'FiatDeposit': 
                setRenderJSX(<SimplexDeposit props={props}/>)
                break;
            case 'CryptoDeposit': 
                setRenderJSX(<CryptoDeposit props={props}/>)
                break;
            case 'CryptoWithdrawal': 
                setRenderJSX(<CryptoWithdraw props={props}/>)
                break;
            default: 
                setRenderJSX(null)
                break
        }
    }
    return(
        //Page header
        //Breadcrumbs
        <React.Fragment>
            <div className="wallet">
                {Header("My Wallet")}
                {Breadcrumbs(["mywallet"])}
                <div className="d-flex flex-lg-row  flex-sm-column flex-md-column flex-column mt-15 ">
                    <WalletCard props={<WalletBalance props = {balanceProp}/>}/>
                    {/* <WalletCard props={<WalletDetails props={detailsProp}/>}/> */}
                </div>
                <div className="d-flex flex-lg-row mt-lg-15 mt-0 flex-sm-column flex-xs-column flex-column flex-md-column ">
                    <WalletCard props={<WalletDetails props={detailsProp}/>}/>
                    <WalletCard props={<TransactionHistory />}/>
                    {/* <WalletCard props={<GetHelp />}/> */}
                </div>

            </div>
            {popup && <div className="wal-popup">
                <div className="pop-wrapper" onClick={()=>{
                    setPopup(false)
                    setLevel(1)
                    }}>
                </div>
                {level == 1 && ( txType=='deposit' ? <SimplexDeposit props={props}/>
                    : 
                    txType=='withdraw' ? <WithdrawWrapper props={{callback: setWindowCallback, close: setPopup}}/>
                    :
                    <CryptoDeposit props={props}/>)}
                {level == 2 && renderJSX}
            </div>}
        </React.Fragment>
    )
}
const mapStateToProps = state =>{ 
    return ({
     keypair: state.Blockchain.keypair,
     run: state.Blockchain.run,
     blockchain: state.Blockchain.blockchain
  });}
export default connect(mapStateToProps)(WalletWrapper);