import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import { FaAngleLeft, FaAngleRight, AiOutlineFileSearch, FaHeart, FaGem } from "react-icons/fa";
import {MatchSummary} from './battleExplorer/MatchSummary';

import { SignedMessage } from "@fyxgaming/lib/dist/signed-message";
import ProgressBar from 'react-bootstrap-v5/lib/ProgressBar'
import CountUp from 'react-countup';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
const Fighter = (props) => {
    let { playerid } = useParams();
    let history = useHistory()
    const [fighterIndex, setFighterIndex] = useState(0)
    const [fighterBotIndex, setFighterBotIndex] = useState(0)
    const [match, setMatch] = useState()
    const [items, setItems] = useState()
    const [stk, setStk] = useState()
    const [element, setElement] = useState()
    const [usdTransferRate, setUsdTransferRate] = useState(localStorage.getItem('usdTR'))
    let location = props.location
    const config = props.config
    useEffect(async ()=>{
        if(!props.location) location = JSON.parse(localStorage.getItem('location'))

        let transferRate = await axios.get(`https://api.whatsonchain.com/v1/bsv/main/exchangerate`)
        localStorage.setItem('usdTR', transferRate.data.rate)
        const resp = await axios.post(process.env.REACT_APP_HOST_URL+'/cryptofights/battles',{
            fighterOrigin: playerid,
            limit: 30
        })
        resp.data = await Promise.all(resp.data.map(async ({id}) => {
            const {data: item} = await axios(`${process.env.REACT_APP_HOST_URL}/cryptofights/battles/${id}`);
            return item;
        }));
        console.log("userID = ", resp)
        if(!match)setMatch(resp.data[0])
        if(resp.data[0]&&resp.data[0].battlePlayers[0].fighter.origin == playerid){
            setFighterIndex(0)
            if(resp.data[0].battlePlayers[0].tags.includes("bot")){
                setFighterBotIndex(0)
            }
        }
        else{
            setFighterIndex(1)
            if(resp.data[0]&&resp.data[0].battlePlayers[1].tags.includes("bot")){
                setFighterBotIndex(1)
            }
        }
        CardStack(resp.data)

    },[match, usdTransferRate])
    const skills = (match) => {
        const stack = []
        
        match&&match.battlePlayers[fighterIndex].skills.map((item)=>{
            stack.push(<div className="skillList"> <span style={{fontSize: 10}}> ·</span>  &nbsp;{item.displayName} </div>)
        })
        return stack
    }
    const ability = (match) => {
        const stack = []
        let point = 0
        match&&match.battlePlayers[fighterIndex].fighter.abilityScores.map((item)=>{
            point +=1
            let text = point == 1? "Strength" : point==2? "Dexterity" : "Intelligence"
            stack.push(<div className="skillList mob-skilllist">{text} <br/> {item} </div>)
        })
        return stack
    }
    const CardStack = async (num) => {
        const stack = []
        const checkStack = []
        for(let i=0; i<num.length; i+=1){
            let item = num[i]
            //ask if victor can be null
            const url = '../summary/'+item.origin+'/'+item.hasOwnProperty('live')
            let d = new Date(item.timestamp)
            var year = d.getFullYear();
            var month = (1 + d.getMonth()).toString();
            month = month.length > 1 ? month : '0' + month;
            // console.log("USD TR = ", localStorage.getItem('usdTR'))
            var day = d.getDate().toString();
            day = day.length > 1 ? day : '0' + day;
            const rew = item.hasOwnProperty('rewards') ? item.rewards[0].item.displayName : '-'
            checkStack.push(
                <tr>
                    <td>
                        <div className='d-flex align-items-center justify-content-between '>
                            <div className='symbol symbol-45px me-1'>
                                <img className="mob-img" style={{width: '3vw', height: 'auto', borderRadius: '1.5vw'}} src={item.battlePlayers[0].fighter.metadata.imageUrl} alt='' />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark text-muted fst-italic fw-bold text-hover-primary fs-6'>
                                    {item.battlePlayers[0].fighter.metadata.name} VS {item.battlePlayers[1].fighter.metadata.name}
                                </a>
                            </div>
                            <div className='symbol symbol-45px ms-1 me-3'>
                                <img className="mob-img" style={{width: '3vw', height: 'auto', borderRadius: '1.5vw'}} src={item.battlePlayers[1].fighter.metadata.imageUrl} alt='' />
                            </div>
                        </div>
                    </td>
                    <td>
                        <a href='#' className='text-dark text-muted fw-bold  text-hover-primary d-block fs-6'>
                            {month + '.' + day + '.' + year+ ' '+ d.toTimeString().slice(0,9)}
                        </a>
                    </td>
                    <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                            <span className='text-muted me-2 fs-7 fw-bold'>${(item.rules.reward*localStorage.getItem('usdTR') /100000000).toFixed(2)}</span>
                            </div>
                            <div className='progress w-100' style={{height: '0.4rem'}}>
                                <div
                                    className='progress-bar'
                                    role='progressbar'
                                    style={{width: `${(item.rules.reward/7200).toFixed(2)}%`, backgroundColor: ((item.rules.reward/7200).toFixed(2) <33 ? "red" : (item.rules.reward/7200).toFixed(2) <66 ? "rgb(114, 167, 228)" : "rgb(86, 214, 96)")}}
                                ></div>
                            </div>
                        </div>
                    </td>
                    <td>
                    <a href='#' className='text-dark text-muted fw-bold  text-hover-primary d-block fs-6'>
                        {rew}
                    </a>
                    </td>
                    <td className="text-end">
                    <div className='d-flex justify-content-end flex-shrink-0'>
                        <div
                        onClick={()=> history.push(`/summary/${item.id}/false`)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                            <img className="mob-img" style={{width: '2vw', height: 'auto'}} src={'./assets/Log.png'}></img>
                        </div>
                        <div
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                            <img className="mob-img" style={{width: '2vw', height: 'auto'}} src={'./assets/Share.png'}></img>
                        </div>
                        <div
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                            <img className="mob-img" style={{width: '2vw', height: 'auto'}} src={'./assets/Watch.png'}></img>
                        </div>
                    </div>
                    </td>
                </tr>

            )

        }
        await setStk(stack)
        renderCard(checkStack)
        
    }
    const renderCard = async(stack) => {
        const elem = []
        for( let items of stack){
            elem.push(
                    items
            )
        }

        await setElement(elem)
    }
    const [itemSection, setItemSection] = useState('myitems')
    return(
        <React.Fragment>
            <div className="handleDetails">


                <div className="fighterCont">
                    <div className="fighterValues">
                        <div className="fighterHead">Player Profile <br/>
                            <div className="fighterDetails">
                                <div className="fighterDetCont fighterImgCont">
                                    <img className="avtr avtrCustom" src={match&& match.battlePlayers[0].fighter.metadata.imageUrl || match && match.battlePlayers[1].fighter.metadata.imageUrl? match && match.battlePlayers[0].fighter.origin==playerid ?  match &&match.battlePlayers[0].fighter.metadata.imageUrl: match &&match.battlePlayers[1].fighter.metadata.imageUrl : '../../../assets/avtr0.png' }></img>
                                    <div className="fighter-name name-custom">{match&& match.battlePlayers[0].fighter.origin == playerid ? match && match.battlePlayers[0].fighter.metadata.name : match &&match.battlePlayers[1].fighter.metadata.name}</div>
                                </div>
                            </div>
                        </div>

                        <div className="fighterStats">
                                <div className="itemDivider itemDividerMob" style={{fontWeight: '900', marginLeft: '0%'}}>
                                    Stats
                                </div>
                                <div style={itemSection==='myitems'?{display: 'block'}: {display: 'none'}}>
                                    
                                    <div className="fighterHp">
                                        <div className="skills"><span className="fighterSpan">Health:</span> {match&&<CountUp end={match.state.fighterStates[fighterIndex].maxHP} start={0} /> }/100 <FaHeart color="red" style={{marginTop: 0, marginLeft: 5}}/>
                                            <ProgressBar variant="danger" className="test-p progressBarProfile" now={match && match.state.fighterStates[fighterIndex].maxHP*100/100} style={{width: 100}}/>
                                        </div>
                                    </div>
                                    <div className="fighterHp">
                                        <div className="skills"><span className="fighterSpan">Level:</span> {match&&<CountUp end={match.battlePlayers[fighterIndex].fighter.level} start={0} /> }</div>
                                    </div>
                                    <div className="fighterHp">
                                        <div className="skills"><span className="fighterSpan">Skills:</span> {skills(match)} </div>
                                    </div>
                                    <div className="fighterHp">
                                        <div className="skills"><span className="fighterSpan">Experience:</span> {match &&<CountUp end={match.battlePlayers[fighterIndex].fighter.xp} start={0} />}/10000 <FaGem color="green" style={{marginTop: 0, marginLeft: 5}}/>
                                            <ProgressBar variant="success" className="test-p progressBarProfile" now={match &&match.battlePlayers[fighterIndex].fighter.xp*100/10000} style={{width: 100}}/>
                                        </div>
                                    </div>
                                    <div className="fighterHp custom-mob-break">
                                        <span className="fighterSpan">Abilities :</span><span className="skills custom-mob-break">  {ability(match)} </span>
                                    </div>
                                    </div>
                        </div>
                    </div>                
                    <div className="battleHistory">
                    
                    <div className="my__carousel_main" style={{paddingLeft: '0%',marginRight:'10%', marginTop: 0, width: '70vw'}}>
                        <div className='card card-xl-stretch mb-5 mb-xl-8' style={{border: 'none'}}>
                            <div className='card-body py-3'>
                                <div className='table-responsive'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead className='gy-8' style={{paddingBottom: '10%', }}>
                                    <tr >
                                        <th className='min-w-150px' >Participants</th>
                                        <th className='min-w-140px' >Date</th>
                                        <th className='min-w-120px' >Cash Prize</th>
                                        <th className='min-w-120px' >Item Reward</th>
                                        <th className='min-w-100px' >Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody className='customtbody' style={{marginTop: '10%'}}>
                                        {element}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                </div>
            </div>

        </React.Fragment>

    )

}
const mapStateToProps = state =>{ 
    return ({
     keypair: state.Blockchain.keypair
  });}

export default connect(mapStateToProps)(Fighter);