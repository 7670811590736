import React, {useEffect, useState} from 'react'
import {IconHeader} from '../../../helpers/components/IconHeader'
import clsx from 'clsx'
import axios from 'axios'
import Select from 'react-select'
import { FaCircle, FaTimes } from 'react-icons/fa'
import {Button} from '../../../helpers/components/Button'
import { connect } from "react-redux";
import { SignedMessage } from "@fyxgaming/lib/dist/signed-message";
import {Notice} from '../WalletBalance'
import Loader from "react-loader-spinner";
import { WalletService } from '../../Service/WalletService'
import Close from '../../../helpers/components/Close'
import { CheckBox } from '../../../helpers/components/CheckBox'
function SimplexDeposit(props){
    let header = {
        imageUrl2: '../../assets/visa.png',
        imageUrl: '../../assets/mastercard.png',
        head: 'Buy via Credit or Debit Card'
    }
    //placeholder
    const fields1 = [{
        title: 'Select Currency',
        type: 'dd',
        name: 'depositCurrency',
        list: [{value: 'USD', label: 'USD'}, {value: 'EUR', label: 'EUR'}]
    },{
        title: 'Deposit Amount',
        type: 'text',
        name: 'amount',
        notice: true,
        noticeJSX: Notice("#3699FF", "The minimum USD amount is $50.00", "info")
    }]
    let error = null
    const [level, setLevel] = useState(1)
    const [depositCurrency, setDepositCurrency] = useState('USD')
    const [depositAmount, setDepositAmount] = useState(null)
    const [checked, setChecked] = useState(false)
    const [fiatMaximums, setFiatMaximums] = useState({})
    const [simplexObject, setSimplexObject] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [simplexPaymentId, setSimplexPaymentId] = useState(null)
    const [fiatMinimums, setFiatMinimums] = useState({})
    const [getPayment, setGetPayment] = useState(null)
    const [fiatCurrenciesList, setFiatCurrenciesList] = useState([])
    let list = [{value: 'USD', label: 'USD'}, {value: 'EUR', label: 'EUR'}]
    useEffect(async ()=>{
        let kp = props.keypair
        let fiat  = kp && await axios.post(process.env.REACT_APP_HOST_URL+`/exchange/getfiatcurrencies`, new SignedMessage(
            {
                "from": localStorage.getItem('userHandle'),
                "subject": 'GetCurrencies',
                "payload": "",
            }, localStorage.getItem('userHandle'), kp
        ))
        console.log("FIAT CURRENCIES + ", fiat)
        // console.log("FIAT Minimums + ", fiatMinimums)
        let min = {}
        let max = {}
        if(fiat.data){
            for(let item of fiat.data){
            min[item.ticker_symbol] = item.min_amount
            max[item.ticker_symbol] = item.max_amount
        }}
        setFiatMinimums(min)
        setFiatMaximums(max)

        fiat = fiat?.data?.map(a =>({value: a.ticker_symbol, label: a.ticker_symbol + ` (${min[a.ticker_symbol]}  Minimum)`}))
        setFiatCurrenciesList(fiat)
    },[])
    useEffect(()=>{}, [depositCurrency])
    let depositStepOne = async (e) => {
        e.preventDefault(e)
        // if(e.target.depositCurrency.value) setCurrencyDeposit(e.target.depositCurrency.value)
        try{
            console.log("Event in BSV deposit = ", depositCurrency, e.target.depositAmount.value)
            e.target.depositAmount.value && setDepositAmount(e.target.depositAmount.value)
            if(isNaN(e.target.depositAmount.value)) throw {message: "The entered amount should be a valid number."}
            if(e.target.depositAmount.value < fiatMinimums[depositCurrency]*1.0) throw {message: `The entered amount should be greater than or equal to ${depositCurrency} ${fiatMinimums[depositCurrency]}`}
            
            let config = process.env.REACT_APP_NETWORK === 'mainnet' ? {
                network: 'mainnet',
              } : 
              {
                network: 'testnet',
              }
            const ws = new WalletService(props, config)
            let response = await ws.simplexGetQuote(depositCurrency, (e.target.depositAmount.value))
            console.log("RESPNSE FROM SIMPLEX ++++++ ", response)
            await setSimplexObject(response)
            setLevel(2)
        }
        catch(e){
            console.log("e.message = ",e.message)
            setErrorMessage(e.message)
        }
    }

    let depositStepTwo = async (e) => {
        e.preventDefault(e)
        let config = process.env.REACT_APP_NETWORK === 'mainnet' ? {
            network: 'mainnet',
          } : 
          {
            network: 'testnet',
          }
        const ws = new WalletService(props, config)
        let response = await ws.simplexTransaction(simplexObject)
        console.log("response from build tx = ", response)
        setSimplexPaymentId(response.simplexPaymentId)
        setGetPayment(true)
        let a = document.getElementById('payment_form')
        a.submit()
    }
    let goBack = () =>{
        // console.log("element in close = ", element)
        // element.target.value = depositAmount
        setLevel(1)
    }
    const LevelOne = () => {
        return(
            <div className="p-10">
                <div className='fv-row mb-10'>
                    <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Select a currency to exchange for BSV: </label>
                        </div>
                    </div>
                    <Select placeholder="USD" name="depositCurrency" defaultValue='USD' className="curr-dd fiat-custom-dd" options={fiatCurrenciesList} onChange={(e)=> setDepositCurrency(e.value)}/>

                </div>
                <div className='fv-row mb-10'>
                    <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Enter Amount: </label>
                        </div>
                    </div>
                    <form
                    className='form w-100'
                    onSubmit={(e)=>depositStepOne(e)}
                    noValidate>
                    <input
                        placeholder={depositAmount || (fiatMinimums[depositCurrency])}
                        className={clsx(
                            'form-control form-control-lg form-control-solid '
                        )}
                        type="text"
                        id="depositAmount"
                        value={depositAmount || null}
                        name="depositAmount"
                        autoComplete='off'></input>
                    <br/>
                    {errorMessage && Notice("red", errorMessage, "warning")}
                    <div className="fs-6 fw-bolder mb-5 mt-5 text-left d-flex justify-content-between align-items-center">Estimate BSV you’ll receive. {Button("Continue", null, "#5200E5")}</div>
                    
                    </form>
                </div>
                
            </div>

        )
    }
    const LevelTwo = () => {
        return(
            
                simplexObject && 
                <React.Fragment><div className="ps-10 pe-10">
                <table style={{width: '100%', alignSelf: 'center', marginBottom: '10%', marginTop: '2%'}}>
                        <tr className="bg-light">
                            <td>You'll Send</td>
                            <td>{simplexObject.fiat_money.total_amount}</td>
                            <td>{depositCurrency}</td>
                        </tr>
                        <tr>
                            <td>You'll receive </td>
                            <td>{simplexObject.digital_money.amount}</td>
                            <td>BSV</td>
                        </tr>
                        <tr className="bg-light">
                            <td>Exchange Rate(appx.)</td>
                            <td>{(localStorage.getItem('usdTR')*1.0).toFixed(2)}</td>
                            <td>USD</td>
                        </tr>
                    </table>
                <div className='fv-row mb-10'>

                    {errorMessage && Notice("red", errorMessage, "warning")}
                    <div className="d-flex justify-content-between">
                        {Button("Cancel", goBack, null)}
                        {Button("Continue", depositStepTwo, "#5200E5")}
                    </div>
                    
                </div>
                
            </div>
            
        </React.Fragment>

        )
    }
    return(
        <React.Fragment>
                {getPayment && <div style={{width: '100vw', height: '100%', backgroundColor: 'rgba(51, 49, 50, 0.932)', position: 'absolute', zIndex: '200', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{color: 'white'}}>Please wait! Processing ...</div>
                <br/>
                <Loader
                    type="Puff"
                    color="white"
                    height={30}
                    width={30}
                    timeout={30000} //3 secs
                />
                <form id="payment_form" action="https://checkout.simplexcc.com/payments/new" method="post">
                <input type="hidden" name="version" value="1.3.1"></input>
                <input type="hidden" name="partner" value="fyx"></input>
                <input type="hidden" name="payment_flow_type" value="wallet"></input>
                <input type="hidden" name="return_url_success" value="https://portal.fyxgaming.com/wallet"></input>
                <input type="hidden" name="return_url_fail" value="https://portal.fyxgaming.com/wallet"></input>
                <input type="hidden" name="payment_id" value={simplexPaymentId}></input>
                </form>
            </div>}
            
            <div className="pop-content card card-rounded shadow-sm p-5 p-lg-5" style={{height: 'unset'}}>
                <Close/>
                <IconHeader object={header}/>
                {level==1 && <LevelOne/>}
                {level==2 && <LevelTwo/>
                }
                {/* <div className="align-self-center">
                    <FaCircle className={clsx(
                        'tx-fyx me-5',
                        {
                            'blur' : level === 2
                        }
                    )}/>
                    
                    <FaCircle className={clsx(
                        'tx-fyx',
                        {
                            'blur' : level === 1
                        }
                    )}/>
                </div> */}
            </div>

        </React.Fragment>
    )
}
const mapStateToProps = state =>{ 
    return ({
     keypair: state.Blockchain.keypair,
     run: state.Blockchain.run,
     blockchain: state.Blockchain.blockchain
  });}
export default connect(mapStateToProps)(SimplexDeposit)