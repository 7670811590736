import React, { useState, useEffect, useRef } from 'react';

import { connect } from "react-redux";
import Popup from '../src/Popup.js';
import { useTimeout } from 'usehooks-ts'
import axios from 'axios'
import {useHistory, Link } from "react-router-dom";
import { FaChromecast } from 'react-icons/fa';
const Queue = (props) => {

    // let source = ''
    const [devQueue1, setDevQueue1] = useState([])
    const [devQueue2, setDevQueue2] = useState([])
    const [devQueue3, setDevQueue3] = useState([])
    const [prodQueue, setProdQueue] = useState([])
    const [adhocQueue, setAdhocQueue] = useState([])
    const [testQueue1, setTestQueue1] = useState([])
    const [testQueue2, setTestQueue2] = useState([])
    const [testQueue3, setTestQueue3] = useState([])
    const [refresh, setRefresh] = useState(true)
    let r


    useEffect(() => {
        const timer = setTimeout(async () => {
            console.log('This will run after 10 second!')
            let a = await axios.get(`https://dev.api.fyxgaming.com/cryptofights/queuelog`)
            let b = await axios.get(`https://test.api.fyxgaming.com/cryptofights/queuelog`)
            // let c = await axios.get(`https://adhoc.api.fyxgaming.com/cryptofights/queuelog`)
            // let d = await axios.get(`https://api.fyxgaming.com/cryptofights/queuelog`)
    
            setDevQueue1(a.data["pvp0"])
            setDevQueue2(a.data["tier7"])
            setDevQueue3(a.data["tier11"])
            setTestQueue1(b.data["pvp0"])
            setTestQueue2(b.data["tier7"])
            setTestQueue3(b.data["tier11"])
            setAdhocQueue([])
            setProdQueue([])
            console.log("refresh api call")
            setRefresh(!refresh)
        }, 10000);
        return () => clearTimeout(timer);
        }, [refresh]);
    return(
        <div>
            {/* <TopNav login={true}/> */}
            <div style={{marginLeft: '40vw'}}>
                {/* <div className="score">CREATE A NEW NFT</div> */}
                <br/><br/><br/><br/><br/><br/><br/>
                <div>Dev queue:</div>
                    <div>pvp0:   {devQueue1.map(i => `player: ${i} |||| `)}</div>
                    <div>tier7:   {devQueue2.map(i => `player: ${i} |||| `)}</div>
                    <div>tier11:   {devQueue3.map(i => `player: ${i} |||| `)}</div>
                    <br/>
                <div>Test queue:</div>
                    <div>pvp0:   {testQueue1.map(i => `player: ${i} |||| `)}</div>
                    <div>tier7:   {testQueue2.map(i => `player: ${i} |||| `)}</div>
                    <div>tier11:   {testQueue3.map(i => `player: ${i} |||| `)}</div>
                    <br/>
                <div>ADHOC queue:</div>
                    <div>{adhocQueue.map(i => `player: ${i} |||| `)}</div><br/>
                <div>PROD queue:</div>
                    <div>{prodQueue.map(i => `player: ${i} |||| `)}</div><br/>
                <div>Current timestamp :</div>
                    <div>{ Date.now()}</div>
                    <div>PING: {refresh}</div>
                    test{refresh}
                    <div class="card-body pt-5">
                        <div class="timeline-label">
                            <div class="timeline-item">
                                <div class="timeline-label fw-bolder text-gray-800 fs-6">08:42</div>
                                <div class="timeline-badge">
                                    <i class="fa fa-genderless text-warning fs-1"></i>
                                </div>
                                <div class="fw-mormal timeline-content text-muted ps-3">Outlines keep you honest. And keep structure</div>
                            </div>
                            <div class="timeline-item">
                                <div class="timeline-label fw-bolder text-gray-800 fs-6">10:00</div>
                                <div class="timeline-badge">
                                    <i class="fa fa-genderless text-success fs-1"></i>
                                </div>
                                <div class="timeline-content d-flex">
                                    <span class="fw-bolder text-gray-800 ps-3">AEOL meeting</span>
                                </div>
                            </div>
                            <div class="timeline-item">
                                <div class="timeline-label fw-bolder text-gray-800 fs-6">14:37</div>
                                <div class="timeline-badge">
                                    <i class="fa fa-genderless text-danger fs-1"></i>
                                </div>
                                <div class="timeline-content fw-bolder text-gray-800 ps-3">Make deposit
                                <a href="#" class="text-primary">USD 700</a>. to ESL</div>
                            </div>
                            <div class="timeline-item">
                                <div class="timeline-label fw-bolder text-gray-800 fs-6">16:50</div>
                                <div class="timeline-badge">
                                    <i class="fa fa-genderless text-primary fs-1"></i>
                                </div>
                                <div class="timeline-content fw-mormal text-muted ps-3">Indulging in poorly driving and keep structure keep great</div>
                            </div>
                            <div class="timeline-item">
                                <div class="timeline-label fw-bolder text-gray-800 fs-6">21:03</div>
                                <div class="timeline-badge">
                                    <i class="fa fa-genderless text-danger fs-1"></i>
                                </div>
                                <div class="timeline-content fw-bold text-gray-800 ps-3">New order placed
                                <a href="#" class="text-primary">#XF-2356</a>.</div>
                            </div>
                            <div class="timeline-item">
                                <div class="timeline-label fw-bolder text-gray-800 fs-6">16:50</div>
                                <div class="timeline-badge">
                                    <i class="fa fa-genderless text-primary fs-1"></i>
                                </div>
                                <div class="timeline-content fw-mormal text-muted ps-3">Indulging in poorly driving and keep structure keep great</div>
                            </div>
                            <div class="timeline-item">
                                <div class="timeline-label fw-bolder text-gray-800 fs-6">21:03</div>
                                <div class="timeline-badge">
                                    <i class="fa fa-genderless text-danger fs-1"></i>
                                </div>
                                <div class="timeline-content fw-bold text-gray-800 ps-3">New order placed
                                <a href="#" class="text-primary">#XF-2356</a>.</div>
                            </div>
                            <div class="timeline-item">
                                <div class="timeline-label fw-bolder text-gray-800 fs-6">10:30</div>
                                <div class="timeline-badge">
                                    <i class="fa fa-genderless text-success fs-1"></i>
                                </div>
                                <div class="timeline-content fw-mormal text-muted ps-3">Finance KPI Mobile app launch preparion meeting</div>
                            </div>
                        </div>
                    </div>
            </div>

        </div>
    )
}

const mapStateToProps = state =>{ 
    return ({
     keypair: state.Blockchain.keypair,
     run: state.Blockchain.run,
     blockchain: state.Blockchain.blockchain,
     bip32: state.Blockchain.bip32
  });}
export default connect(mapStateToProps)(Queue);