const source = "portal"
const environment = process.env.REACT_APP_ENV

//Auth in event 
const trackAuth = async () => {
    console.log("track auuth Hit")
    window.analytics.track('Portal User Authenticated', {
        user_id: localStorage.getItem('userHandle'),
        source: source,
        environment: environment,
        avatar: "http://s3.amazonaws.com/testing.fyxgaming.com/avtr0.png",
        
    })
}
//signed in event 
const trackLogIn = async () => {
    console.log("track login Hit")
    window.analytics.track('Portal Signed In', {
        user_id: localStorage.getItem('userHandle'),
        source: source,
        environment: environment,
        avatar: "http://s3.amazonaws.com/testing.fyxgaming.com/avtr0.png",
        
    })
}
//sign up event
const trackSignUp = async () => {
    
    window.analytics.track('Portal Signed Up', {
        user_id: localStorage.getItem('userHandle'),
        source: source,
        environment: environment,
        avatar: "http://s3.amazonaws.com/testing.fyxgaming.com/avtr0.png",
        
    })
    window.analytics.track('Portal User Registered', {
        user_id: localStorage.getItem('userHandle'),
        source: source,
        environment: environment,
        avatar: "http://s3.amazonaws.com/testing.fyxgaming.com/avtr0.png",
        
    })
}
//item Melt
const trackMelt = async (melt, sat, origin) => {
    console.log("MELT TRACK HIT")
    window.analytics.track('Item Melted', {
        user_id: localStorage.getItem('userHandle'),
        source: source,
        environment: environment,
        avatar: "http://s3.amazonaws.com/testing.fyxgaming.com/avtr0.png",
        'usd:melt_value': melt,
        'sats:melt_value': sat,
        item_origin: origin,
        
    })
}
//item Transfer
const trackTransfer = async (receiver, origin) => {
    
    window.analytics.track('Item Transfered', {
        user_id: localStorage.getItem('userHandle'),
        source: source,
        environment: environment,
        avatar: "http://s3.amazonaws.com/testing.fyxgaming.com/avtr0.png",
        receiver_userId: receiver,
        item_origin: origin,
        
    })
}
//item List
const trackList = async (list, sat, origin) => {
    
    window.analytics.track('Item Listed', {
        user_id: localStorage.getItem('userHandle'),
        source: source,
        environment: environment,
        avatar: "http://s3.amazonaws.com/testing.fyxgaming.com/avtr0.png",
        'usd:listed_price': list,
        'sats:listed_price': sat,
        item_origin: origin,
        
    })
}
//item unList
const trackUnlist = async (list, sat, origin) => {

    window.analytics.track('Item Unlisted', {
        user_id: localStorage.getItem('userHandle'),
        source: source,
        environment: environment,
        avatar: "http://s3.amazonaws.com/testing.fyxgaming.com/avtr0.png",
        'usd:listed_price': list,
        'sats:listed_price': sat,
        item_origin: origin,
        
    })
}
//item purchase
const trackPurchase = async (list, sat, origin, item, name) => {
    
    window.analytics.track('Item Purchased', {
        user_id: localStorage.getItem('userHandle'),
        source: source,
        environment: environment,
        avatar: "http://s3.amazonaws.com/testing.fyxgaming.com/avtr0.png",
        'usd:listed_price': list,
        'usd:rake': parseFloat(list)*0.025,
        'sats:listed_price': sat,
        item_origin: origin,
        item_data: item,
        item_name: name
        
    })
}
//downloads
const trackDownload = async (extension, platform) => {
    
    window.analytics.track('Download Started', {
        user_id: localStorage.getItem('userHandle'),
        source: source,
        environment: environment,
        avatar: "http://s3.amazonaws.com/testing.fyxgaming.com/avtr0.png",
        extension: extension,
        download_platform: platform
        
    })
}
//page viewed

const trackPage = async (page) => {
    
    window.analytics.track(`${page} Page Viewed`, {
        user_id: localStorage.getItem('userHandle'),
        source: source,
        environment: environment,
        avatar: "http://s3.amazonaws.com/testing.fyxgaming.com/avtr0.png",
        
    })
}
const fundTransfer = async (list, sat, add, type) => {
    
    window.analytics.track(`Wallet Transfer`, {
        user_id: localStorage.getItem('userHandle'),
        source: source,
        environment: environment,
        'usd:transfer_amount': list,
        'sats:transfer_amount': sat,
        sender: localStorage.getItem('userHandle'),
        receiver: add,
        type: type,
        avatar: "http://s3.amazonaws.com/testing.fyxgaming.com/avtr0.png",
        
    })
}
const fundDeposit = async ( add, toCurrency, fromCurrency, type, res) => {
    
    window.analytics.track(`Wallet Deposit`, {
        user_id: localStorage.getItem('userHandle'),
        source: source,
        environment: environment,
        to_currency: toCurrency,
        from_currency: fromCurrency,
        sender: localStorage.getItem('userHandle'),
        receiver: add,
        service: type,
        result_object: res,
        avatar: "http://s3.amazonaws.com/testing.fyxgaming.com/avtr0.png",
        
    })
}
const fundSimplexDeposit = async ( add, toCurrency,amount, type, res) => {
    
    window.analytics.track(`Wallet Deposit`, {
        user_id: localStorage.getItem('userHandle'),
        source: source,
        environment: environment,
        topup_currency: toCurrency,
        sender: localStorage.getItem('userHandle'),
        receiver: add,
        topup_amount: amount,
        service: type,
        result_object: res,
        avatar: "http://s3.amazonaws.com/testing.fyxgaming.com/avtr0.png",
        
    })
}
const fundWithdrawal = async ( add, changellyadd, toCurrency, fromCurrency, type, res) => {
    
    window.analytics.track(`Wallet Withdrawal`, {
        user_id: localStorage.getItem('userHandle'),
        source: source,
        environment: environment,
        to_currency: toCurrency,
        from_currency: fromCurrency,
        sender: localStorage.getItem('userHandle'),
        changelly_address: changellyadd,
        receiver: add,
        service: type,
        result_object: res,
        avatar: "http://s3.amazonaws.com/testing.fyxgaming.com/avtr0.png",
        
    })
}



//user registered & user Authenticated
//check with Abel about page view events
//also how did he fetch Distinct Device ID
export  {trackLogIn,fundTransfer,fundDeposit,fundSimplexDeposit,fundWithdrawal, trackSignUp,trackAuth, trackMelt, trackTransfer, trackList, trackUnlist, trackPurchase, trackDownload, trackPage} ;