import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import '../css/Login.css'
import Loader from "react-loader-spinner";
import { FaCircle } from 'react-icons/fa'
import { AuthHeader } from './AuthHeader'
import { Forms } from '../../helpers/components/Forms'
import {LoginService} from '../service/LoginService'
import { Notice } from '../../wallet/components/WalletBalance';

export const SignUp = () => {
    let history = useHistory()
    let email= true
    let touched = true
    const [loading, setLoading] = useState(false)
    const [level, setLevel] = useState(1)
    const [error, setError] = useState(null)
    let obj = {
      head: "Sign Up for Fyx Gaming",
      body: "Already have an account?",
      url: {
        body: "Sign In",
        url: '/login'
      }
    }

    const [entries, setEntries] = useState(null)
    const LevelOne = () => {
      let fields = [
        {title: 'Username',name:'username', type:'text'},
        {title: 'Password',name:'password', type:'password'},
        {title: 'Confirm Password', name:'confirmpassword', type:'password'}
      ]
        return(
            <React.Fragment>
              {Forms(handleForm1, obj, fields ,error)}
              {entries && <div className="d-flex flex-column mb-5" style={{justifyContent: 'center',alignItems: 'center', position: 'absolute', top: 0, width: '100%', height: '100%', backgroundColor: '#80808014'}}> 
                <Loader
                  type="Puff"
                  color="#00BFFF"
                  height={30}
                  width={30}
                  timeout={30000} //3 secs
                />
                Please Wait...
              </div>}
            </React.Fragment>
        )
    }
    const LevelTwo = () => {
      let fields = [
        {title: 'First Name',name:'firstname', type:'text'},
        {title: 'Last Name',name:'lastname', type:'text'},
        {title: 'Email', name:'email', type:'text'}
      ]
        return(
            <React.Fragment>
              {Forms(handleForm2, obj, fields ,error)}
              {/* {error && Notice("red", error, "warning")} */}
              <br/>
              {entries.email && !error && <div className="d-flex flex-column mb-5" style={{justifyContent: 'center',alignItems: 'center', position: 'absolute', top: 0, width: '100%', height: '100%', backgroundColor: '#80808014'}}> 
                <Loader
                  type="Puff"
                  color="#00BFFF"
                  height={30}
                  width={30}
                  timeout={30000} //3 secs
                />
                Please Wait...
              </div>}
            </React.Fragment>
        )
    }
    const handleForm1 = async (e) => {
        e.preventDefault()
        if(e.target.password.value.length<10) setError({
          state: true,
          message: "The password you entered should be more than 10 characters.",
          field: 'password'
        })
        else if(e.target.password.value != e.target.confirmpassword.value) setError({
          state: true,
          message: "The passwords you entered do not match.",
          field: 'confirmpassword'
        })
        else{
          setEntries({
          username: e.target.username.value,
          password: e.target.password.value,
          confirmpassword: e.target.confirmpassword.value
          })
          await new Promise((res, rej)=> {
            setTimeout(()=>{
                res()
            }, 2000)

          })
          setLevel(2)
        }
    }
    const handleForm2 = async (e) => {
      e.preventDefault()
      if(e.target.email.value == '' || e.target.firstname.value == '' || e.target.lastname.value == '' ){
        setError({
          state: true,
          message: "All Fields are required.",
          field: 'email'
        })
        return
      }
      if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(e.target.email.value)){
          await setEntries({
            firstname: e.target.firstname.value,
            lastname: e.target.lastname.value,
            email: e.target.email.value,
            ...entries
          })
          await new Promise((res, rej)=> {
            setTimeout(()=>{
                res()
            }, 2000)

          })
        signInMethod(
          {
            ...entries,
            firstname: e.target.firstname.value,
            lastname: e.target.lastname.value,
            email: e.target.email.value,
          }
        )
      }
      else{
        setError({
          state: true,
          message: "Email address is not valid",
          field: 'email'
        })
      }

    }
    const signInMethod = async (e) => {
      // e.preventDefault()
      setError(null)
      console.log("event username= ", e)
      let config = process.env.REACT_APP_NETWORK === 'mainnet' ? {
          network: 'mainnet',
        } : 
        {
          network: 'testnet',
        }
      const ls = new LoginService(config)
      let response = await ls.signUp(e.username, e.password, e.email, e.firstname, e.lastname)
      console.log("response from login = ", response)
      if(!response.error) history.push('/emailsent')
      else{
          console.log("error in logins = ", response.error.message, response.error.status)
          if(response.error.message.toLowerCase().includes('duplicate')){
            setError({
              state: true,
              message: "The username has been taken. Try a different one.",
              field: 'email'
            })
          }
          else{
            setError({
              state: true,
              message: "response.error.message",
              field: 'email'
            })
          }

      }

    }
    return(
          <div style={{position: 'relative'}}>
            {level == 1 ? <LevelOne/> : <LevelTwo/>}
            <div className="d-flex justify-content-center">
                <FaCircle className={clsx(
                    'tx-fyx me-5',
                    {
                        'blur' : level === 2
                    }
                )}/>
                
                <FaCircle className={clsx(
                    'tx-fyx',
                    {
                        'blur' : level === 1
                    }
                )}/>
            </div>
          </div>
    )

}