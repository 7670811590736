import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import Popup from '../src/Popup.js';
import axios from 'axios'
import {useHistory, Link } from "react-router-dom";
import { FaChromecast } from 'react-icons/fa';
const AllCollections = (props) => {
    let history = useHistory()
    const [coll, setColl] = useState([])
    // let source = ''
    useEffect(async ()=>{

        if(!localStorage.getItem('privKeyString')){
            history.push('/')
        }
        let respAll = await axios.get(process.env.REACT_APP_HOST_URL+`/jigs/mycollections/${localStorage.getItem('userHandle')}`)
        respAll = await Promise.all(respAll.data.map(async ({location}) => {
            const {data: item} = await axios(`${process.env.REACT_APP_HOST_URL}/jigs/${location}`);
            return item;
        }));
        console.log("TEST COLL = ", respAll)
        renderCollections(respAll)
    },[props.run])
    const renderCollections = (coll) => {
        let stack = []
        for(let index in coll){
            let c = coll[index]
            let url = '/create/'+c.location
            stack.push(
                <Link to={url}>
                    <div className="collectionBox" >
                        <img style={{height: '70%', width: 'auto'}} src={c.metadata.imageUrl && `https://dogefiles.twetch.app/${c.metadata.imageUrl.split('b://')[1]}`}></img>
                        <div className="collName">{c.metadata.name}</div>
                    </div>
                </Link>
            )
        }
        setColl(stack)
    }
    return(
        <div>
            <div className="my__carousel_main createCollScreen">
                {/* <div className="score">CREATE A NEW NFT</div> */}
                <div className="nftContainer">
                    <div className="collTab">
                        <div className="createColl">
                            Your Collections
                        </div>
                        <div className="collectionList">
                                {coll}
                        </div>
                        <button className="createItemButton cusBut" onClick={()=>history.push('/collection')}>Create Collection</button>
                    </div>



                </div>

            </div>

        </div>
    )
}

const mapStateToProps = state =>{ 
    return ({
     keypair: state.Blockchain.keypair,
     run: state.Blockchain.run,
     blockchain: state.Blockchain.blockchain,
     bip32: state.Blockchain.bip32
  });}
export default connect(mapStateToProps)(AllCollections);