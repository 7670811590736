import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { FaAngleLeft, FaAngleRight, AiOutlineFileSearch, FaMedal, FaSearch, FaSmile } from "react-icons/fa";
import './leaderboard.css'
import CountUp from 'react-countup';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import Card from '@mui/material/Card';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
export const Leaderboard = (props) => {

    const [stk, setStk] = useState()
    const [element, setElement] = useState()
    const [userRecord, setUserRecord] = useState(null)
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
    const [newEvents, setNewEvents] = useState([])
    const [userdata, setUserdata] = useState([])
    const [time, setTime] = useState(7)
    const [tab, setTab] = useState('tournament')
    const [search, setSearch] = useState(null)
    //set up keys in env on the basis of domains
    const location_url = process.env.REACT_APP_HOST_URL+'/cryptofights/locations'
    const jig_url = process.env.REACT_APP_HOST_URL+'/jigs'
    let location = props.location
    if(!props.location) location = JSON.parse(localStorage.getItem('location'))
    useEffect (async () => {
        console.log("HERE ")
        let reqBody = {}
        if(time){
            reqBody.end= Date.now()
            reqBody.start = Date.now() - time*86400*1000
            reqBody.prevdate = Date.now() - time*86400*1000
            // reqBody.prevdate = 1634965200000
        }
        console.log("tim erestraints = ", reqBody.start, reqBody.end)
        switch(tab){
            case 'tournament':
                    break
            case 'pvp1':
                    reqBody.battleground = 'pvp0'
                    break
            case 'pvp2':
                    reqBody.battleground = 'tier7'
                    break
            case 'pvp3':
                    reqBody.battleground = 'tier11'
                    break
            case 'gods':
                    reqBody.alldata = 1
                    break
        }
        const test = await axios.post(process.env.REACT_APP_HOST_URL+'/cryptofights/leaderboard', reqBody)
        console.log("Leaderboard TO DEBUGGGGGG from new endpoint = ", test)
        test.data = test.data.sort((a,b)=>b.ppt-a.ppt)
        for(let i in test.data){
            test.data[i]['rank'] = parseInt(i) +1
            if(test.data[i].id == localStorage.getItem('userHandle')){
                let ur = {}
                ur['rank'] = test.data[i].rank
                ur['positional'] = test.data[i].rank!=0 ? test.data[i-1].ppt-test.data[i].ppt: 'N/A'
                setUserRecord(ur)
            }
        }
        search != null ? CardStack(test.data.filter(i => i.id.includes(search))) :
        CardStack(test.data)
        //user data
        const userdata = await axios.post(process.env.REACT_APP_HOST_URL+'/cryptofights/leaderboard/'+localStorage.getItem('userHandle'), reqBody)
        console.log("userdata = ", userdata)
        let temp = []
        if(userdata.data[0][0] && userdata.data[1][0]){
            temp.push(userdata.data[0][0])
            temp.push(userdata.data[1][0])
            setUserdata(temp)
        }
      },[search, tab, time]);
    useEffect(()=>{
        setSearch(null)
        let all = document.getElementsByClassName('bg-bk')
        if(tab=='gods'){
            document.getElementsByClassName("MuiBox-root")[0].style.backgroundColor='black'
        }
        else{
            document.getElementsByClassName("MuiBox-root")[0].style.backgroundColor='unset'
        }
        for(let a of all){
            if(tab == 'gods' ) {
                a.style.backgroundColor = 'black'
                a.style.color = 'white'
            }
            else{
                a.style.backgroundColor = 'unset'
                a.style.color = 'unset'
            }
        }
    },[tab])
    let Emoji = () => {
        let emojiList = ["😂","😬","😄","😔","🤣","🤔","😀","😊","😳","😍","😒","😭","😩","😏","😁","😎","🤓","🤩","🥳","😣","😫","😟","🥺","😤","😡","🤯","🥶","😱","🤗","😬"]
        const rando = Math.floor(Math.random() * 30)
        let em = {'symbol':emojiList[rando]}
        return(<span
            className="emoji"
            role="img"
            aria-label={em.label ? em.label : ""}
            aria-hidden={em.label ? "false" : "true"}
            style={{fontSize: '24px'}}
        >
            {em.symbol}
        </span>)
    }
    let Country = () => {
        let countryList = ['usa', 'brazil', 'india', 'philippines', 'australia', 'france', 'bulgaria', "ussr", 'canada', 'mexico', 'spain', 'germany']
        const rando = Math.floor(Math.random() * 12)
        let country = countryList[rando]
        return(<img src={`https://img.icons8.com/color/50/000000/${country}-circular.png`}/>)
    }

    const CardStack = async (num) => {
        const stack = []
        const checkStack = []

        for(let item in num){
            //ask if victor can be null
            let pos = item
            let bg = 'bg-bk'
            if(pos == 0){
                pos = <FaMedal color={'gold'}/>
                bg = 'bg-1'
            }
            else if (pos == 1) {
                pos = <FaMedal color={'silver'}/>
                bg = 'bg-2'
            }
            else if (pos == 2) {
                pos = <FaMedal color={'brown'}/>
                bg = 'bg-3'
            }
            else if (pos == 3) {
                pos = <FaMedal color={'black'}/>

            }
            else pos = pos

            checkStack.push(
                <TableRow>
                    <TableCell className={bg}><div className='profile-circle bg-bk'> <Emoji/><div className='rank'>{num[item].rank}</div></div></TableCell>
                    {/* <TableCell className={bg} align="center" style={{color: '#00A3FF'}}>{item.battlePlayers[0].fighter.metadata.name} VS {item.battlePlayers[1].fighter.metadata.name}</TableCell> */}
                    <TableCell className={bg} align="center" >{<div className="lb-block" ><CountUp start={0} end={num[item].ppt} decimals={4} duration={1.0}/> </div>}</TableCell>
                    <TableCell className={bg} align="center">{num[item].id}</TableCell>
                    <TableCell className={bg} align="center"><Country/></TableCell>
                    <TableCell className={bg} align="center">{num[item].total_won}</TableCell>
                    <TableCell className={bg} align="center">{num[item].total_played}</TableCell>
                    {/* <TableCell className={bg} align="right">{<CountUp start={0} end={num[item].total_won-num[item].total_played==0? num[item].total_won : num[item].total_won/(num[item].total_played-num[item].total_won)} decimals={2} duration={1.0}/> }</TableCell> */}
                    <TableCell className={bg} align="center">{<div className="lb-block" >$<CountUp start={0} end={num[item].cash} decimals={2} duration={1.0}/></div>}</TableCell>
                    
                </TableRow>
            )


        }
        checkStack.unshift(
            <TableRow>
                <TableCell className="bg-bk fw-bold" align="center">RANK</TableCell>
                <TableCell className="bg-bk fw-bold" align="center">POINTS</TableCell>
                <TableCell className="bg-bk fw-bold" align="center">PLAYER</TableCell>
                <TableCell className="bg-bk fw-bold" align="center">COUNTRY</TableCell>
                <TableCell className="bg-bk fw-bold" align="center">WINS</TableCell>
                <TableCell className="bg-bk fw-bold" align="center">TOTAL BATTLES</TableCell>
                {/* <TableCell className="bg-bk fw-bold" align="right">WIN/LOSS RATIO</TableCell> */}
                <TableCell className="bg-bk fw-bold" align="center">REWARDS</TableCell>
            </TableRow>
        )
        await setStk(stack)
        renderCard(checkStack)
        
    }
    const renderCard = async(stack) => {
        const elem = []
        for( let items of stack){
            elem.push(
                    items
            )
        }

        await setElement(elem)
    }
    const Search = () =>{
        return (
            <form className="search-bar-lb" style={{width: '200px !important'}} onSubmit={(e)=>{
                e.preventDefault()
                setSearch(e.target.search.value)
                console.log(e.target.search.value)}}>
                <FaSearch/>
                <input className="search-input-lb" type="text" name="search" placeholder={search ? search: "Search..."}></input>
            </form>
        )
    }
    const TopPart = () => {
        
        let img
        switch(tab){
            case 'tournament':
                    img = '../../assets/tournamentTab.png'
                    break
            case 'pvp1':
                    img = '../../assets/pvp1Tab.png'
                    break
            case 'pvp2':
                    img = '../../assets/pvp2Tab.png'
                    break
            case 'pvp3':
                    img = '../../assets/pvp3Tab.png'
                    break
            case 'gods':
                    img = '../../assets/godsTab.png'
                    break
        }
        return tab !== 'gods' ? (
            <div>
                <div>
                    <img style={{width: '100%'}} src={img}></img>
                    <br/>
                    <br/>
                </div>
                {userdata && userdata.length>1 && <div className="d-flex flex-row justify-content-between mb-5">
                    <div className="card card-rounded p-5" style={{width: '35%', height: '160px'}}>
                        <div className="fs-4 fw-bolder">Your Growth</div>
                        <div className="fs-7 fw-bold">Points gained({time} days)</div>
                        <br/>
                        <div className="fs-7 fw-bold d-flex flex-row">
                            <div className='profile-circle'> <Emoji/></div>
                            <div style={{fontSize: '28px', paddingLeft: '10px', fontWeight: '700'}}>{userdata[1].ppt - userdata[0].ppt}</div> 
                        </div>

                    </div>
                    {userRecord && <div className="card card-rounded p-5" style={{width: '35%', height: '160px'}}>
                        <div className="fs-4 fw-bolder">Positional Progress</div>
                        <div className="fs-7 fw-bold">Points needed for next position</div>
                        <br/>
                        <div className="fs-7 fw-bold d-flex flex-row">
                            <div className='profile-circle'> <Emoji/></div>
                            <div style={{fontSize: '28px', paddingLeft: '10px', fontWeight: '700'}}>{userRecord.positional}</div> 
                        </div>
                    </div>}
                    {userRecord && <div className="card card-rounded p-5 " style={{width: '25%', height: '160px'}}>
                        <div className="fs-4 fw-bolder">Personal Rank</div>
                        <div className="fs-7 fw-bold">Your current rank</div>
                        <br/>
                        <div className="fs-7 fw-bold d-flex flex-row">
                            <div className='profile-circle'> <Emoji/></div>
                            <div style={{fontSize: '28px', paddingLeft: '10px', fontWeight: '700'}}>{userRecord.rank}</div> 
                        </div>
                    </div>}
                </div>}
            </div>
        )
        : 
        (
            <div className="gods-cont">
                <div className="gods-cont-h">God's Leaderboard</div>
                <div className="d-flex flex-row" style={{width: '50%'}}>
                    <div style={{position: 'relative'}}>
                        <img style={{width: '100%'}} src='../../assets/secondPlace.png'></img>
                        {/* <div className='profile-circle-2'><img style={{width: '100%'}} src='../../assets/profileTest.png'></img></div> */}
                    </div>
                    <div>
                        <img style={{width: '100%'}} src='../../assets/firstPlace.png'></img>
                        
                        {/* <div className='hexagon' style={{background: 'url(../../assets/profileTest.png)', display: 'inline-block'}}></div> */}
                    
                    </div>
                    <div>
                        <img style={{width: '100%'}} src='../../assets/thirdPlace.png'></img>
                    </div>
                </div>
            </div>
        )
    }
    return(
        <React.Fragment>
            {/* <TopNav/> */}
            <div className='bg-bk'>
                {/* <img className="bannerLeaderboard" src="../../assets/bannerT.png"/> */}
                <Typography variant="h1" className="bg-bk">Leaderboard</Typography>
                    <Breadcrumbs aria-label="breadcrumb" sx={{fontSize: '13px', color:'#B5B5C3', paddingBottom: '0px'}}>
                        <Link underline="hover" color="inherit" href="/" className="bg-bk">
                            Fyx Home
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            href="/"
                            className="bg-bk"
                        >Portal
                        </Link>
                        <Typography className="bg-bk text-dark fw-bold ">Leaderboard</Typography>
                    </Breadcrumbs>

                    <br/>
                    <div style={{width: '100%'}}><Search/></div>
                    <br/>
                    <TopPart/>
                    {tab && tab!='gods' && <div className="card card-rounded d-flex flex-row justify-content-between p-2 mb-5 fw-bolder" style={tab!='gods' ? {width: '300px'} : {width: '300px', backgroundColor: 'black', color: 'white'}}>
                        <div 
                            className="p-2" 
                            style={time && time == 7 ? {backgroundColor: 'rgba(0, 29, 79, 0.65)',color: 'white', borderRadius: '2px', cursor: 'pointer'}: {cursor: 'pointer'}}
                            onClick={()=>setTime(7)}
                            >1 week</div>
                        <div 
                            className="p-2" 
                            style={time && time == 14 ? {backgroundColor: 'rgba(0, 29, 79, 0.65)',color: 'white', borderRadius: '2px', cursor: 'pointer'}: {cursor: 'pointer'}}
                            onClick={()=>setTime(14)}
                            >2 weeks</div>
                        <div 
                            className="p-2" 
                            style={time && time == 30 ? {backgroundColor: 'rgba(0, 29, 79, 0.65)',color: 'white', borderRadius: '2px', cursor: 'pointer'}: {cursor: 'pointer'}}
                            onClick={()=>setTime(30)}
                            >1 months</div>
                        <div 
                            className="p-2" 
                            style={time && time == 90 ? {backgroundColor: 'rgba(0, 29, 79, 0.65)',color: 'white', borderRadius: '2px', cursor: 'pointer'}: {cursor: 'pointer'}}
                            onClick={()=>setTime(90)}
                            >3 months</div>
                    </div>}
                    <TableContainer component={Card}>
                        <div className='d-flex flex-row w-100 justify-content-between pt-5 ps-5 pe-5 border-bottom border-1 bg-bk'>
                            <div className="fw-bolder fs-4">Top Tournament Players</div>
                            <div style={{width: '200px'}}><Search/></div>
                            <div style={{cursor: 'pointer'}} className={tab == 'tournament' ? "border-bottom border-success pb-5 pt-2 fw-bolder": "pb-5 pt-2"} onClick={()=>setTab('tournament')}>Tournaments</div>
                            <div style={{cursor: 'pointer'}} className={tab == 'pvp1' ? "border-bottom border-success pb-5 pt-2 fw-bolder": "pb-5 pt-2"} onClick={()=>setTab('pvp1')}>PvP 1</div>
                            <div style={{cursor: 'pointer'}} className={tab == 'pvp2' ? "border-bottom border-success pb-5 pt-2 fw-bolder": "pb-5 pt-2"} onClick={()=>setTab('pvp2')}>PvP 2</div>
                            <div style={{cursor: 'pointer'}} className={tab == 'pvp3' ? "border-bottom border-success pb-5 pt-2 fw-bolder": "pb-5 pt-2"} onClick={()=>setTab('pvp3')}>PvP 3</div>
                            <div style={{cursor: 'pointer'}} className={tab == 'gods' ? "border-bottom border-success pb-5 pt-2 fw-bolder": "pb-5 pt-2"} onClick={()=>setTab('gods')}>God's Leaderboard</div>
                        </div>
                        <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {element}
                            </TableBody>
                        </Table>
                    </TableContainer>
                <div className="scrollview" >
                    {element==undefined ? <div style={{color: 'white'}}>                        
                        <Loader
                                type="Puff"
                                color="#00BFFF"
                                height={30}
                                width={30}
                                timeout={30000} //3 secs
                        /></div>: null}
                </div>

            </div>


        </React.Fragment>


    )
}