import  {  Bip32, Constants, KeyPair, PrivKey } from 'bsv';
import Run from 'run-sdk';
import { AuthService } from "@fyxgaming/lib/dist/auth-service";
import { FyxOwner } from "@fyxgaming/lib/dist/fyx-owner";
import { RestBlockchain } from "@fyxgaming/lib/dist/rest-blockchain";
import { RestStateCache } from "@fyxgaming/lib/dist/rest-state-cache";
import { FyxPurse } from "@fyxgaming/lib/dist/fyx-purse";

const initialize = async (bip32, resp, config) => {
        let retObj = {blockchain: "", run: ""}
        let apiUrl = process.env.REACT_APP_HOST_URL //done
        let fyxId = 'cryptofights' //hcoded
        
        const owner = new FyxOwner(apiUrl, bip32, fyxId || '', localStorage.getItem('userHandle'), resp);
        let purseKeyPair = KeyPair.fromPrivKey(bip32.derive(`m/0/0`).privKey)
        const cache = new Run.plugins.BrowserCache({ maxMemorySizeMB: 100 });
        
        const blockchain = new RestBlockchain(
            apiUrl,
            config.network.slice(0,4),
            cache
        )

        retObj.blockchain = blockchain
        const run = new Run({
            network: config.network.slice(0,4),
            owner,
            blockchain,
            purse: new FyxPurse(
                purseKeyPair,
                blockchain,
                0,
                0.05
            ),
            cache: new RestStateCache(apiUrl, cache),
            app: 'fyx',
            trust: '*',
            timeout: 60000,
            //inventory: null,
            // logger: console
        });
        retObj.run = run

    return retObj
}
export default (initialize);