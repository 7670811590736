import React, {useEffect, useState} from "react";
import "./css/modal.scss";
import {useDispatch, useSelector} from "react-redux";
import Loader from "react-loader-spinner";
import {HIDDEN, OK, YES_NO} from "./constants/buttonTypes";
import {hideModalMsg} from "../redux/messages/actions";

export const ModalMessage = ({title, content, buttonType, confirmedCallback}) => {

    const dispatch = useDispatch();
    const modalMsgVisible = useSelector(state => state.Messages.modalMsgVisible);

    const [active, setActive] = useState(false);

    useEffect(() => {

        getMsgKey() ? showMsg() : hideMsg();

    }, [modalMsgVisible]);

    const getMsgKey = () => {
        const identifiers = Object.keys(modalMsgVisible);
        return identifiers.filter((key) => modalMsgVisible[key])[0];
    }

    const showMsg = () => {
        setActive(true);
    };

    const hideMsg = () => {
        setActive(false);
    };

    const getButtons = (buttonType = HIDDEN) => {
        switch (buttonType) {
            case OK:
                return (
                    <>
                        <div className='btn btn-lg btn-yes' onClick={() => dispatch(hideModalMsg())}>OK</div>
                    </>
                );
            case YES_NO:
                return (
                    <>
                        <div className='btn btn-lg btn-yes' onClick={() => {
                            confirmedCallback(getMsgKey());
                            dispatch(hideModalMsg());
                        }}>Yes</div>
                        <div className='btn btn-lg btn-no' onClick={() => dispatch(hideModalMsg())}>No</div>
                    </>
                );
            default:
                return;
        }
    };

    return active && (
        <div className="modal-msg" id="bg" onClick={(e) => (e.target.id === 'bg' && dispatch(hideModalMsg()))}>
            <div className="body d-flex flex-column align-items-center">
                <div className="close" onClick={() => dispatch(hideModalMsg())}>
                    <span aria-hidden="true">×</span>
                </div>

                <img className="logo" src="../../assets/avtr0.png" alt=''/>

                <div className="content-title mb-3">{title}</div>
                <div className="content-text mb-3">{content}</div>
                <div className="content-buttons">
                    {getButtons(buttonType)}
                </div>

            </div>
        </div>
    );

};
